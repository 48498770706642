<div class = "flex flex-col justify-start items-start mb-10">
    <div class="flex flex-row flex-nowrap md:gap-3 items-center">
        <h1 class="headeradminui-text md:break-keep">Manage User Attributes</h1>
    </div>
    
</div>
<ng-template #tableHead>
    <div nz-row nzJustify="space-between">
      <div nz-col>
        <span class="font-bold">Attributes</span>
      </div>
      <div nz-col>
        <button
          nz-button
          nzSize="small"
          nzType="primary"
          (click)="handleOpenDrawer()"
        >
          Add Attributes
        </button>
      </div>
    </div>
  </ng-template>
<nz-spin [nzSpinning]="spinner" nzTip="Fetching data......" class="w-auto h-screen">
<nz-table 
#therapistTable
[nzTitle]="tableHead"
[nzScroll]="{ y: '500px' }"
class="mt-5"
[nzData]="formFields"
[nzShowPagination]="true"
nzFrontPagination="false"
[nzBordered]="true"
[nzShowSizeChanger]="true"
nzTableLayout="fixed"
>
        <thead>
            <tr>
              <th>
                Attributes Name
              </th>
              <th>
                Visible
              </th>
              <th>Input Type</th>
              <th>Mandatory</th>
              <th>
                Multiple
              </th>
              <th nzWidth="25%">values</th>
              <th>Allow Additional Choices</th>
              <th>Save Additional Choices</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
             <tr *ngFor="let data of formFields ; let i = index ">
                <td [ngClass]="{ 'bg-gray-200': data.visible === false }">
                    {{ data.name }}     
                </td>
                <td [ngClass]="{ 'bg-gray-200': data.visible === false }">
                    {{ data.visible }}     
                </td>
                <td [ngClass]="{ 'bg-gray-200': data.visible === false }">
                    {{ data.inputType }}     
                </td>
                <td [ngClass]="{ 'bg-gray-200': data.visible === false }">
                  {{ data.mandatory }}     
              </td>
                <td [ngClass]="{ 'bg-gray-200': data.visible === false }">
                    {{ data.multiple }}     
                </td>
                <td [ngClass]="{ 'bg-gray-200': data.visible === false }">
                  <ul class="flex flex-row flex-wrap">
                    <li 
                      *ngFor="let item of data.values" 
                      [ngClass]="{ 'px-1 py-0.5 bg-slate-400 border m-1 border-gray-800': item.visible }">
                      <div *ngIf="item.visible">{{ item.value }}</div>
                    </li>
                  </ul> 
                </td>
                <td [ngClass]="{ 'bg-gray-200': data.visible === false }">
                  {{ data.allowAdditionalChoices }}     
              </td>
              <td [ngClass]="{ 'bg-gray-200': data.visible === false }">
                {{ data.saveAdditionalChoices }}     
            </td>
                <td [ngClass]="{ 'bg-gray-200': data.visible === false }">
                    <button nz-button (click)="handleOpenEditDrawer(i)" class="btn-color" nzType="primary">
                        Edit
                    </button>
                </td>
             </tr>
          </tbody>
    </nz-table>
    
</nz-spin>

<nz-drawer
  [nzBodyStyle]="{ overflow: 'auto' }"
  [nzMaskClosable]="false"
  [nzWidth]="500"
  [nzVisible]="openEditDrawer"
  (nzOnClose)="handleCloseDrawer()"
>
<ng-container *nzDrawerContent>
  <div nz-row nzJustify="space-between">
    <div nz-col>
      <span class="font-bold text-2xl">Add Attributes</span>
    </div>
  </div>
  <nz-divider></nz-divider>
  <div class="flex flex-col pb-4">
    <span  class="font-bold text-lg pb-4">Visible</span>
    <nz-switch [(ngModel)]="formField.visible"></nz-switch>
  </div>
  <div class="flex flex-col pb-4">
      <span  class="font-bold text-lg pb-4">Attribute Name</span>
      <input nz-input [(ngModel)]="formField.name" placeholder="Enter attribute name" />
  </div>
  <div class="flex flex-col pb-4">
    <span class="font-bold text-lg pb-4">Attribute Type</span>
    <button nz-button nz-dropdown [nzDropdownMenu]="menu">{{ formField.inputType ? formField.inputType : "Select an input type" }}</button>
    <nz-dropdown-menu #menu="nzDropdownMenu">
      <ul nz-menu class="custom-dropdown">
        <li 
        nz-menu-item 
        *ngFor="let item of inputType" 
        (click)="onSelectInputType(item.id)" 
        (keydown.enter)="onSelectInputType(item.id)"
        tabindex="0">
        {{ item.value }}
      </li>
      </ul>
    </nz-dropdown-menu>
  </div>
  <div class="flex flex-col pb-4" *ngIf="showDropdownFields">
    <span  class="font-bold text-lg pb-4">Allow Multiple</span>
    <div class="flex flex-row space-x-2"><nz-switch [(ngModel)]="formField.multiple"></nz-switch></div>
  </div>

  <div class="flex flex-col pb-4 " *ngIf="showDropdownFields">
    <span  class="font-bold text-lg pb-4">Values</span>
    <div class="w-full border border-black mb-2"  *ngIf="formField.values?.length! > 0" >
      <ul class="flex flex-row flex-wrap">
        <li *ngFor="let item of formField.values ; let i = index" class="px-1 py-0.5 flex flex-row justify-center items-center space-x-2 bg-slate-100 border m-1 border-gray-800">
          <button 
          nzType="link" 
          class="text-blue-950 flex justify-center items-center" 
          (click)="updateValues(i)" 
          aria-label="Remove item">
          <span nz-icon nzType="edit" nzTheme="outline"></span>
        </button>
         {{ item }}
         <button 
         nzType="link" 
         class="text-red-600 flex justify-center items-center" 
         (click)="onRemoveValue(i)" 
         aria-label="Remove item">
         <span nz-icon nzType="close-circle" nzTheme="outline"></span>
       </button>
        </li>
      </ul>
    </div>
    <input nz-input [(ngModel)]="value" placeholder="Enter values"/>
    <div class="flex flex-row justify-end w-full mt-2 space-x-2">
      <button *ngIf="!updateValue" nz-button (click)="onAddValues()" class="btn-color" nzType="primary">
        Add
      </button>
      <div *ngIf="updateValue" class="flex space-x-2">
        <button nz-button (click)="cancelUpdateValues()" class="btn-color" nzDanger nzType="primary">
          Cancel
        </button>
        <button nz-button (click)="onUpdateValues()" class="btn-color" nzType="primary">
          Update
        </button>
      </div>
    </div>
    
</div>
  <div class="flex flex-col pb-4">
    <span  class="font-bold text-lg pb-4">Mandatory</span>
    <div class="flex flex-row space-x-2"><nz-switch [(ngModel)]="formField.mandatory"></nz-switch></div>
  </div>
  <div class="flex flex-col pb-4" *ngIf="showAllowAndSaveFields">
    <span  class="font-bold text-lg pb-4">Allow Additional Choices</span>
    <div class="flex flex-row space-x-2"><nz-switch [(ngModel)]="formField.allowAdditionalChoices"></nz-switch></div>
  </div>
  <div class="flex flex-col pb-4" *ngIf="showAllowAndSaveFields">
    <span  class="font-bold text-lg pb-4">Save Additional Choices</span>
    <div class="flex flex-row space-x-2"><nz-switch [(ngModel)]="formField.saveAdditionalChoices"></nz-switch></div>
  </div>
  <div class="my-5 w-full">
    <button nz-button (click)="onSubmit()" class="btn-color w-full" nzType="primary" *ngIf="!showButton">
          Add Attribute
    </button>
    <button nz-button (click)="updateAttribute()" class="btn-color w-full" nzType="primary" *ngIf="showButton">
      Update Attribute
</button>
  </div>
</ng-container>
</nz-drawer>



<ng-template #tableHead>
  <div nz-row nzJustify="space-between">
    <div nz-col>
      <span class="font-bold">Members</span>
    </div>
    <div nz-col>
      <div nz-row nzJustify="space-around">
        <div class="m-1" nz-col *ngIf="selectedTab === 0">
          <button
            (click)="bulkDeleteMember()"
            nz-button
            nzSize="small"
            nzType="primary"
          >
           <span nz-icon nzType="delete" nzTheme="outline"></span>
            Bulk Delete Member
          </button>
        </div>
        <div class="m-1" nz-col *ngIf="selectedTab === 0">
          <button
            (click)="bulkSendMail()"
            nz-button
            nzSize="small"
            nzType="primary"
          >
            <span nz-icon nzTheme="outline" nzType="mail"></span>
            Bulk send Member pass
          </button>
        </div>
        
        <div class="m-1" nz-col *ngIf="selectedTab === 0">
          <button
            (click)="clearSelection()"
            nz-button
            nzSize="small"
            nzType="primary"
          >
            Clear Selection
          </button>
        </div>
        <div nz-col class="m-1">
          <button
            (click)="exportMemberTable()"
            nz-button
            nzSize="small"
            nzType="primary"
          >
            <span nz-icon nzType="download"></span>
            Export
          </button>
        </div>
        <div nz-col class="m-1">
          <button
            nz-button
            nzSize="small"
            nzType="primary"
            (click)="resetFilter()"
          >
            Clear filter
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-template>
<nz-table
  #memberTable
  [nzTitle]="tableHead"
  [nzScroll]="{ y: '500px' }"
  class="mt-5"
  [nzData]="tableData.data"
  [nzShowPagination]="true"
  nzFrontPagination="false"
  [nzTotal]="tableData.totalRecords"
  [nzPageSize]="tableData.pageSize"
  [nzBordered]="true"
  [nzShowSizeChanger]="true"
  [nzPageSizeOptions]="memberSizeOptions"
  [nzPageIndex]="tableData.pageNum"
  nzTableLayout="fixed"
  (nzPageIndexChange)="nzPageIndexChange($event)"
  (nzPageSizeChange)="nzPageSizeChange($event)"
  (nzCurrentPageDataChange)="onCurrentPageDataChange($event)"
>
  <thead>
    <tr>
      <th
        *ngIf="selectedTab === 0"
        (nzCheckedChange)="onAllChecked($event)"
        [nzChecked]="checked"
        nzLabel="Select all"
      ></th>

      <th>
        Email
        <nz-filter-trigger [nzActive]="true" [nzDropdownMenu]="emailSearch">
          <span nz-icon nzType="search"></span>
        </nz-filter-trigger>
      </th>
      <th *ngIf="selectedTab !== 0">Corporate Member Email</th>
      <th>
        Corporate Code
        <nz-filter-trigger
          [nzActive]="true"
          [nzDropdownMenu]="corporateCodeSearch"
        >
          <span nz-icon nzType="search"></span>
        </nz-filter-trigger>
      </th>
      <th *ngIf="selectedTab === 0">Dept/Branch</th>
      <th>
        Pass Redeemed
        <nz-filter-trigger
          [nzActive]="true"
          [nzDropdownMenu]="codeRedeemDropDown"
        >
          <span nz-icon nzType="filter" nzTheme="outline"></span>
        </nz-filter-trigger>
      </th>
      <th>Redeemed On</th>
      <th *ngIf="selectedTab === 0">Family Additions</th>
      <th>Actions</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let data of memberTable.data">
      <td
        *ngIf="selectedTab === 0"
        (nzCheckedChange)="onItemChecked(data.id, $event)"
        [nzChecked]="setOfCheckedId.has(data.id)"
        [ngClass]="{
          'bg-gray-200': data.isDeleted
        }"
      ></td>
      <td
        [ngClass]="{
          'bg-gray-200': data.isDeleted,
          'bg-slate-400': data.parentMemberIsDeleted
        }"
      >
        {{ data.email }}
      </td>
      <td
        *ngIf="selectedTab !== 0"
        [ngClass]="{
          'bg-gray-200': data.isDeleted,
          'bg-slate-400': data.parentMemberIsDeleted
        }"
      >
        {{ data.parentCorporateMemberEmail }}
      </td>
      <td
        [ngClass]="{
          'bg-gray-200': data.isDeleted,
          'bg-slate-400': data.parentMemberIsDeleted
        }"
      >
        {{ data.corporateCode }}
      </td>
      <td
        *ngIf="selectedTab === 0"
        [ngClass]="{
          'bg-gray-200': data.isDeleted,
          'bg-slate-400': data.parentMemberIsDeleted
        }"
      >
        {{ data.department }}<br />{{ data.branch }}
      </td>
      <td
        [ngClass]="{
          'bg-gray-200': data.isDeleted,
          'bg-slate-400': data.parentMemberIsDeleted
        }"
      >
        {{ data.passRedeemed ? "yes" : "no" }}
      </td>
      <td
        [ngClass]="{
          'bg-gray-200': data.isDeleted,
          'bg-slate-400': data.parentMemberIsDeleted
        }"
      >
        {{ data.codeRedeemedOnString }}
      </td>
      <td
        *ngIf="selectedTab === 0"
        [ngClass]="{ 'bg-gray-200': data.isDeleted }"
      >
        {{ data.familyAdditions + "/" + data.maxFamilyInvitesAllowed }}
      </td>
      <td
        [ngClass]="{
          'bg-gray-200': data.isDeleted,
          'bg-slate-400': data.parentMemberIsDeleted
        }"
      >
        <div nz-row nzJustify="space-around">
          <button [nzDropdownMenu]="menu" nz-button nz-dropdown>
            Actions
            <span nz-icon nzType="down"></span>
          </button>
          <nz-dropdown-menu #menu="nzDropdownMenu">
            <ul nz-menu>
              <li (click)="manageMember(data.id, partnerName)" nz-menu-item>
                <a>Manage Member</a>
              </li>
              <li
                (click)="showTransferPartnerFn(data.id)"
                *ngIf="selectedTab === 0 && !data.isDeleted"
                nz-menu-item
              >
                <a>Transfer Member</a>
              </li>
              <li
                (click)="deleteMemberAlert(data.id)"
                *ngIf="selectedTab === 0 && !data.isDeleted"
                nz-menu-item
              >
                <a>Delete Member</a>
              </li>
              <li
                *ngIf="
                  selectedTab === 0 &&
                  !data.isDeleted &&
                  data.passRedeemed &&
                  viewUserProfileRole
                "
                nz-menu-item
                (click)="navigateToUserProfile(data.corporateCode)"
              >
                View User Profile
              </li>
            </ul>
          </nz-dropdown-menu>
        </div>
      </td>
    </tr>
  </tbody>
</nz-table>
<nz-dropdown-menu #emailSearch="nzDropdownMenu">
  <div class="bg-white drop-shadow-md m-1 p-2 rounded-md">
    <div class="m-1" nz-row>
      <div nz-col>
        <input
          [(ngModel)]="emailColumnSearch"
          nz-input
          placeholder="Search"
          nzSize="small"
        />
      </div>
    </div>
    <div class="m-1" nz-row nzJustify="end">
      <div nz-col>
        <button
          class="m-1"
          nz-button
          nzSize="small"
          nzType="primary"
          (click)="emailColumnSearchFn()"
        >
          Ok
        </button>
        <button
          class="m-1"
          nz-button
          nzSize="small"
          nzType="primary"
          (click)="resetFilter()"
        >
          Reset
        </button>
      </div>
    </div>
  </div>
</nz-dropdown-menu>
<nz-dropdown-menu #corporateCodeSearch="nzDropdownMenu">
  <div class="bg-white drop-shadow-md m-1 p-2 rounded-md">
    <div class="m-1" nz-row>
      <div nz-col>
        <input
          [(ngModel)]="corporateCodeColumnSearch"
          nz-input
          placeholder="Search"
          nzSize="small"
        />
      </div>
    </div>
    <div class="m-1" nz-row nzJustify="end">
      <div nz-col>
        <button
          class="m-1"
          nz-button
          nzSize="small"
          nzType="primary"
          (click)="corporateCodeSearchFn()"
        >
          Ok
        </button>
        <button
          class="m-1"
          nz-button
          nzSize="small"
          nzType="primary"
          (click)="resetFilter()"
        >
          Reset
        </button>
      </div>
    </div>
  </div>
</nz-dropdown-menu>
<nz-dropdown-menu #codeRedeemDropDown>
  <div class="bg-white drop-shadow-md m-1 p-2 rounded-md">
    <div nz-row>
      <div class="font-bold">Code Redeemed</div>
      <nz-divider></nz-divider>
      <div nz-col>
        <nz-radio-group [(ngModel)]="codeRedeemColumnValue">
          <label nz-radio nzValue="Yes">Yes</label>
          <label nz-radio nzValue="No">No</label>
        </nz-radio-group>
      </div>
    </div>
    <div nz-row nzJustify="end">
      <div nz-col>
        <button
          class="m-1"
          nz-button
          nzType="primary"
          (click)="codeRedeemColumnFilterFn()"
        >
          Ok
        </button>
      </div>

      <div nz-col>
        <button class="m-1" nz-button nzType="primary" (click)="resetFilter()">
          Reset
        </button>
      </div>
    </div>
  </div>
</nz-dropdown-menu>
<nz-modal
  (nzOnCancel)="handleCancelTransferModal()"
  (nzOnOk)="handleOkTransferModal()"
  [(nzVisible)]="showTransferMemberModal"
  nzTitle="The first Modal"
>
  <nz-spin [nzSpinning]="transferLoading"></nz-spin>
  <ng-container *nzModalContent>
    Please select the Partner You want to transfer this member to.
    <nz-select
      [(ngModel)]="selectedPartner"
      class="min-w-[400px]"
      nzAllowClear
      nzPlaceHolder="Select Partner"
      nzShowSearch
    >
      <nz-option
        *ngFor="let partner of partnerList"
        nzLabel="{{ partner.label }}"
        nzValue="{{ partner.value }}"
      ></nz-option>
    </nz-select>
  </ng-container>
</nz-modal>

import { Injectable } from '@angular/core';
import {
  NetworkUtilsService,
  URL_DICT,
} from 'projects/app-core/src/service/network-utils.service';
import {
  GetUserFormAttributesResponse,
  UpdateUserFormAttributesRequest,
  UserFormAttributes,
} from '../models/UserFormAttributes';

@Injectable({
  providedIn: 'root',
})
export class UserFormAttributesService {
  constructor(private api: NetworkUtilsService) {}
  public AddAttribute(request: UserFormAttributes) {
    return this.api.post(URL_DICT.adminAddUserFormAttributes, request);
  }
  public GetAttributes() {
    return this.api.get<GetUserFormAttributesResponse[]>(
      URL_DICT.adminGetUserFormAttributes,
    );
  }
  public UpdateAttribute(request: UpdateUserFormAttributesRequest) {
    return this.api.post(URL_DICT.adminUpdateUserFormAttributes, request);
  }
}

import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Select2Data } from 'ng-select2-component';
import { v4 as uuidv4 } from 'uuid';
import { ContentService } from '../../../services/content.service';
import { ToastService } from 'projects/app-core/src/app/services/toastr.service';
import {
  AnyoS3Buckets,
  FileUtilsService,
} from '../../../../../../app-core/src/service/fileService';
import { ICategory } from '../../../../../../app-core/src/models/ICategory';
import { MasterdataService } from '../../../services/masterdata.service';
import { AuthService } from '../../../../../../app-core/src/auth/auth.service';
import { MasterDataResponse } from '../../../models/masterDataResponse';
import { IAnyoError } from '../../../../../../app-core/src/models/errorModal';
import { lastValueFrom } from 'rxjs';
import { NewContentRequest } from '../../../models/newContentRequest';
import { IModule } from '../../../models/IContent';
import * as moment from 'moment';
import { Router } from '@angular/router';
import { Instructor } from '../../../models/Instructors';
import { CirclesService } from '../../../services/circles.service';
import { CircleTopics } from '../../../models/circles/AnyoCircles';

interface instructorsSelectOptions {
  label: string;
  value: {
    name: string;
    _id: string;
  };
}

interface categorySelectOptions {
  label: string;
  value: string;
}

interface genreSelectOptions {
  label: string;
  value: string;
}

@Component({
  selector: 'app-add',
  templateUrl: './add.component.html',
  styleUrls: ['./add.component.scss'],
})
export class AddComponent {
  modules: IModule[] = [];
  addContentForm!: FormGroup;
  contentSpinning: boolean = false;
  newContentId: string | undefined;
  categories: categorySelectOptions[] = [];
  emotionPurpose: string | undefined = '';
  energyPurpose: string | undefined = '';
  userProblems: string = '';
  feedback: string = '';
  practiceName: string = '';
  selectedGenreString: string = '';
  masterData: MasterDataResponse | undefined;
  selectedCategory: ICategory | undefined;
  selectedCategoryString: string | undefined;
  contentDescription: string | undefined;
  templates: string | undefined;
  genres: genreSelectOptions[] | undefined;
  contentThumbnailFile: File | undefined;
  moduleMediaFiles = new Map<number, File>();
  moduleThumbnailFiles = new Map<number, File>();
  moduleBannerFiles = new Map<number, File>();
  private contentBannerFile: File | undefined;
  instructors: instructorsSelectOptions[] = [];
  selectedInstructors: Instructor[] = [];
  isVisible = false
  userInterestTopics: CircleTopics[] = [];
  selectedUserInterests: string[] = [];

  constructor(
    private auth: AuthService,
    private contentService: ContentService,
    private formBuilder: FormBuilder,
    private toastService: ToastService,
    private masterDataService: MasterdataService,
    private fileService: FileUtilsService,
    private router: Router,
    private circleService: CirclesService
  ) {
    this.addContentForm = this.formBuilder.group({
      category: ['', Validators.required],
      genre: ['', Validators.required],
    });
  }

  addModule() {
    this.modules.push({
      estimatedTime: '',
      isMandatory: false,
      moduleId: `${this.modules.length + 1}`,
      moduleName: '',
      thumbnail: '',
      type: '',
      uid: '',
      url: '',
      banner: '',
      moduleDescription: '',
    });
  }

  ngOnInit(): void {
    this.contentSpinning = true;
    this.auth.currentAuthStatus.subscribe(async(user) => {
      if (user) {
        this.contentService.getNewContentId().subscribe({
          next: (response) => {
            this.newContentId = response.data;
          },
          error: (error) => {
            const errorBody = error.error as IAnyoError;
            this.toastService.showAnyoErrorToast(errorBody.description);
          },
        });
        this.userInterestTopics = await this.circleService.getUserInterestsMeta();
        this.masterDataService.masterDataList().subscribe((response) => {
          this.categories = response.categories.map((cat) => {
            return {
              label: cat.category,
              value: cat._id,
            };
          });
          this.instructors = response.instructors.map((int) => {
            return {
              label: int.name,
              value: {
                name: int.name,
                _id: int._id,
              },
            };
          });

          this.masterData = response;
        });
        this.contentSpinning = false;
      }
    });
  }

  validateNumber(event: Event): void {
    const inputElement = event.target as HTMLInputElement;
    inputElement.value = inputElement.value.replace(/[^0-9]/g, '');
  }

  async saveContent() {
    try {
      this.contentSpinning = true;
      const newContentRequest: NewContentRequest = {
        categoryId: this.selectedCategoryString!,
        description: this.contentDescription!,
        instructor: this.selectedInstructors,
        newContentId: this.newContentId!,
        practiceName: this.practiceName,
        module: [],
        contentThumbnail: '',
        contentBanner: '',
        visible: this.isVisible,
        userInterest: this.selectedUserInterests
      };
      if (this.contentThumbnailFile) {
        newContentRequest.contentThumbnail = await this.handleFileUpload(
          this.contentThumbnailFile,
          `contentthumnail`,
          'Content Thumbnail',
        );
      }
      if (this.contentBannerFile) {
        newContentRequest.contentBanner = await this.handleFileUpload(
          this.contentBannerFile,
          `contentbanner`,
          'Content Banner',
        );
      }
      const modules: IModule[] = [];
      for (let i = 0; i < this.modules.length; i++) {
        const value = this.modules[i];
        const thumbnailFile = this.moduleThumbnailFiles.get(i);
        const mediaFile = this.moduleMediaFiles.get(i);
        const bannerFile = this.moduleBannerFiles.get(i);
        if (thumbnailFile && mediaFile) {
          const thumbnailPath = await this.handleFileUpload(
            thumbnailFile,
            `module_thumbnail_${i + 1}`,
            `thumbnail ${i + 1}`,
          );
          const mediaPath = await this.handleFileUpload(
            mediaFile,
            `module_media_${i + 1}`,
            `media ${i + 1}`,
          );
          const module: IModule = {
            estimatedTime: value.estimatedTime,
            isMandatory: value.isMandatory,
            moduleId: value.moduleId.toString(),
            moduleName: value.moduleName,
            moduleDescription: value.moduleDescription,
            thumbnail: thumbnailPath,
            type: mediaFile.type,
            uid: uuidv4(),
            url: mediaPath,
            banner: '',
          };

          modules.push(module);
        }
        if (bannerFile) {
          modules[i].banner = await this.handleFileUpload(
            bannerFile,
            `module_banner_${i + 1}`,
            `module banner ${i + 1}`,
          );
        }
      }
      newContentRequest.module = modules;
      this.contentService.createContent(newContentRequest).subscribe({
        next: (value) => {
          this.toastService.showSuccess('Content added successfully');
          this.contentSpinning = false;
          this.router.navigate(['/content']);
        },
        error: (err) => {
          const errorBody = err.error as IAnyoError;
          this.toastService.showAnyoErrorToast(errorBody.description);
          this.contentSpinning = false;
        },
      });
    } catch (e) {
      console.log(e);
      this.toastService.showError(e + '');
      this.contentSpinning = false;
    }
  }

  async handleFileUpload(
    file: File,
    filePathString: string,
    toastrString: string,
  ) {
    try {
      const extension = file.name.split('.').pop();
      const timestamp = moment().format('x');
      const filePath = `content/${this.newContentId}/${filePathString}_${timestamp}.${extension}`;
      const uploadUrl = await lastValueFrom(
        this.fileService.generateUploadUrl(
          AnyoS3Buckets.CDN_BUCKET,
          filePath,
          file.type,
        ),
      );
      this.toastService.showSuccess(`Uploading ${toastrString}`);
      await lastValueFrom(this.fileService.uploadFile(file, uploadUrl.url));
      this.toastService.showSuccess(
        `Uploading module ${toastrString} completed`,
      );
      return `https://cdn.anyo.app/${filePath}`;
    } catch (e) {
      console.log(e);
      this.toastService.showError(e + '');
      throw e;
    }
  }

  deleteModule(index: number | undefined) {
    if (index != undefined) {
      this.modules.splice(index, 1);
      for (let i = index; i < this.modules.length; i++) {
        this.modules[i].moduleId = (i + 1).toString();
      }
    }
  }

  categoryChangeFn($event: string) {
    if ($event) {
      const category = this.masterData!.categories!.filter(
        (value) => value._id == $event,
      )[0];
      this.selectedCategory = category;
      this.genres = this.masterData!.genre!.filter(
        (value) => value.data == category.genre,
      ).map((value) => {
        const t: genreSelectOptions = {
          label: value.data,
          value: value.m_id,
        };
        return t;
      });
      this.selectedGenreString = this.genres[0].value;
      this.energyPurpose = category!.energyPurpose?.purpose;
      this.emotionPurpose = category!.emotionPurpose?.purpose;
      this.userProblems = category!.userProblem.join(',');
      this.feedback = category.feedback;
      this.templates = category.templates?.join('\n');
    }
  }

  uploadModuleThumbnail($event: any, i: number) {
    const file = $event.target.files[0];
    if (file) {
      this.moduleThumbnailFiles.set(i, file);
    }
  }

  uploadModuleMedia($event: any, i: number) {
    const file = $event.target.files[0];
    if (file) {
      this.moduleMediaFiles.set(i, file);
    }
  }

  uploadContentThumbNail($event: any) {
    const file = $event.target.files[0];
    if (file) {
      this.contentThumbnailFile = file;
    }
  }

  uploadContentBanner($event: any) {
    const file = $event.target.files[0];
    if (file) {
      this.contentBannerFile = file;
    }
  }

  uploadModuleBanner($event: any, i: number) {
    const file = $event.target.files[0];
    if (file) {
      this.moduleBannerFiles.set(i, file);
    }
  }
}

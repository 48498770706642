import { Component } from '@angular/core';
import { AnyoComponent } from 'projects/app-core/src/app/models/AnyoComponent';
import { ToastService } from 'projects/app-core/src/app/services/toastr.service';
import { AuthService } from 'projects/app-core/src/auth/auth.service';
import {
  AdminGetRewardValuesResponse,
  RewardService,
  AddOrUpdateRewardsValues,
} from '../../../services/rewards.service';
import { IAnyoError } from 'projects/app-core/src/models/errorModal';

@Component({
  selector: 'app-manage-rewards',
  templateUrl: './manage-rewards.component.html',
  styleUrl: './manage-rewards.component.scss',
})
export class ManageRewardsComponent extends AnyoComponent {
  constructor(
    auth: AuthService,
    private toastService: ToastService,
    private rewardService: RewardService,
  ) {
    super(auth);
  }

  spinner: boolean = false;
  rewardData: AdminGetRewardValuesResponse[] = [];
  editingRow: number | null = null;

  async ready(): Promise<void> {
    if (this.user) {
      await this.getAttributes();
    }
  }

  async getAttributes() {
    this.spinner = true;
    this.rewardService.getRewardValues().subscribe({
      next: (response) => {
        this.rewardData = response;
      },
      error: (error) => {
        const errorBody = error.error as IAnyoError;
        this.toastService.showAnyoErrorToast(errorBody.description);
      },
      complete: () => {
        this.spinner = false;
      },
    });
  }
  toggleEdit(index: number): void {
    if (this.editingRow === index) {
      this.editingRow = null;
    } else {
      this.editingRow = index;
    }
  }

  async saveAmount(index: number) {
    this.spinner = true;
    const reqBody: AddOrUpdateRewardsValues = {
      name: this.rewardData[index].name,
      value: this.rewardData[index].amount,
      description: this.rewardData[index].description,
      active: true,
    };
    this.rewardService.updateRewardValues(reqBody).subscribe({
      next: () => {
        this.getAttributes();
      },
      error: (error) => {
        const errorBody = error.error as IAnyoError;
        this.toastService.showAnyoErrorToast(errorBody.description);
      },
      complete: () => {
        this.spinner = false;
        this.editingRow = null;
      },
    });
  }
}

import { Component, ElementRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ExpertsService } from '../../../services/experts.service';
import { AuthService } from 'projects/app-core/src/auth/auth.service';
import { ToastService } from 'projects/app-core/src/app/services/toastr.service';
import {
  OnboardingPageTypes,
  ExpertsActivity,
  ExpertOnboardingStatus,
} from '../../../models/ExpertOnboardingRequests';
import { UtilsService } from 'projects/app-core/src/service/utils.service';
import { CrudExpertsComponent } from '../crud-experts/crud-experts.component';
import { FileUtilsService } from 'projects/app-core/src/service/fileService';
import { CirclesService } from '../../../services/circles.service';
import { MasterdataService } from '../../../services/masterdata.service';
import { CRUD } from 'projects/app-core/src/app/models/AnyoCrudComponent';
import * as moment from 'moment';
import { IAnyoError } from 'projects/app-core/src/models/errorModal';

@Component({
  selector: 'app-onboarding-form',
  templateUrl: './onboarding-form.component.html',
  styleUrl: './onboarding-form.component.scss',
})
export class OnboardingFormComponent extends CrudExpertsComponent {
  expertActivities = [
    ExpertsActivity.THERAPIST,
    ExpertsActivity.LISTENER,
    ExpertsActivity.CIRCLE_EXPERT,
    ExpertsActivity.MANAGE_EVENT,
  ];

  toUpperCaseFirstLetter(letter: string) {
    return letter.charAt(0).toLocaleUpperCase() + letter.substring(1);
  }
  removeSlot(i: number, indexSlots: number) {
    this.availabilityV2[i].slots.splice(indexSlots, 1);
  }
  addSlots(i: number) {
    this.availabilityV2[i].slots.push({
      slotEnd: '',
      slotStart: '',
      offline: true,
      online: true,
    });
  }

  log($event: any) {
    console.log($event);
  }

  constructor(
    auth: AuthService,
    circleService: CirclesService,
    masterdataService: MasterdataService,
    public toast: ToastService,
    public expert: ExpertsService,
    fileService: FileUtilsService,
    activatedRoute: ActivatedRoute,
    router: Router,
    utils: UtilsService,
  ) {
    super(
      auth,
      circleService,
      masterdataService,
      toast,
      expert,
      fileService,
      activatedRoute,
      router,
      utils,
    );
    this.crudType = CRUD.APPROVE;
  }
  public readonly OnboardingPageTypes = OnboardingPageTypes;
  protected readonly ExpertsActivity = ExpertsActivity;
  onChange(result: Date): void {
    console.log('Selected Time: ', result);
  }

  onOk(result: any): void {
    console.log('onOk', result);
  }

  current = 0;
  @ViewChild('targetDiv') targetDiv!: ElementRef;

  scrollToDiv(): void {
    this.targetDiv.nativeElement.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  }

  pre(): void {
    this.current -= 1;
  }

  async next() {
    this.current += 1;
  }

  showBtnChanges() {
    if (
      this.expertOnboardRequest &&
      this.expertOnboardRequest.onboardingStatus
    ) {
      return [
        ExpertOnboardingStatus.UNDER_REVIEW,
        ExpertOnboardingStatus.REQUIRE_CHANGES,
      ].includes(this.expertOnboardRequest.onboardingStatus);
    }
    return false;
  }

  showRequiredChanges() {
    if (
      this.expertOnboardRequest &&
      this.expertOnboardRequest.onboardingStatus
    ) {
      return [ExpertOnboardingStatus.UNDER_REVIEW].includes(
        this.expertOnboardRequest.onboardingStatus,
      );
    }
    return false;
  }

  showAlreadyRejected() {
    if (
      this.expertOnboardRequest &&
      this.expertOnboardRequest.onboardingStatus
    ) {
      return [ExpertOnboardingStatus.REJECTED].includes(
        this.expertOnboardRequest.onboardingStatus,
      );
    }
    return false;
  }

  addComment() {
    this.isLoadingComment = true;
    this.expert
      .addComments(this.expertOnboardRequest._id!, this.comment)
      .subscribe({
        next: () => {
          this.toast.showSuccess('Comments Added');
          this.isVisible = false;
          this.isLoadingComment = false;
          this.comment = '';
          this.fetchExpertOnboardingRequest();
          if (
            this.expertOnboardRequest.comments &&
            this.expertOnboardRequest.comments.length
          ) {
            this.scrollToDiv();
          }
        },
        error: (value) => {
          const errorBody = value.error as IAnyoError;
          this.toast.showAnyoErrorToast(errorBody.description);
          this.isVisible = false;
          this.isLoadingComment = false;
        },
      });
  }

  showAlreadyApproved() {
    if (
      this.expertOnboardRequest &&
      this.expertOnboardRequest.onboardingStatus
    ) {
      return [ExpertOnboardingStatus.APPROVED].includes(
        this.expertOnboardRequest.onboardingStatus,
      );
    }
    return false;
  }

  done(): void {
    console.log('done');
  }

  formatDate(date: Date) {
    return moment(date).format('DD/MM/YYYY hh:mm:A');
  }

  handleCancel() {
    this.isVisible = false;
  }
}

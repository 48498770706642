import { Component } from '@angular/core';
import { TherapyPlansService } from '../../../services/therapy-plans.service';
import { IAnyoError } from 'projects/app-core/src/models/errorModal';
import { ToastService } from 'projects/app-core/src/app/services/toastr.service';
import { AuthService } from 'projects/app-core/src/auth/auth.service';
import { UtilsService } from 'projects/app-core/src/service/utils.service';
import { UniqueTherapyPlanSubscriptionForUsersReq, UniqueTherapyPlanSubscriptionsFilter } from '../../../models/uniqueTherapyPlanSubscription';
import { UniqueTherapyPlanSubscriptionForUsersResp } from '../../../models/uniquePlanSubscriptionForUsers';
import { StatsCard } from '../stats-cards/stats-cards.component';

interface CheckboxOptions {
  label: string;
  value: string;
  checked: boolean;
}

@Component({
  selector: 'app-user-therapy-plans',
  templateUrl: './user-therapy-plans.component.html',
  styleUrl: './user-therapy-plans.component.scss'
})
export class UserTherapyPlansComponent {
  pageLoading = false
  therapyPlansSubscriptionsForEachUsers: UniqueTherapyPlanSubscriptionForUsersResp[] = [] 
  params = "active"
  showTestCount = false
  gridRequest!: UniqueTherapyPlanSubscriptionForUsersReq
  globalSearchInput: string = ''
  totalUsers = 0
  totalActiveUsers = 0
  totalActivePlans = 0
  totalPlans = 0
  retailValue = 0
  corporateValue = 0
  selectedPartnerId = ''
  partnerIdCheckBoxOptions: CheckboxOptions[] = []
  page = 1
  pageSize = 10
  pageSizeOptions = [10,20]
  cards: StatsCard[] = [
    {
      title: 'Total Users',
      count: 0,
      userLogo: true,
      upLogo: false,
    },
    {
      title: 'Active Users',
      count: 0,
      userLogo: true,
      upLogo: false,
    },
    {
      title: 'Total Plans',
      count: 0,
      userLogo: false,
      upLogo: true,
    },
    {
      title: 'Active Plans',
      count: 0,
      userLogo: false,
      upLogo: true,
    },
    {
      title: 'Retail Value',
      count: 0,
      userLogo: false,
      upLogo: false,
    },
    {
      title: 'Corporate Value',
      count: 0,
      userLogo: false,
      upLogo: false,
    }
  ]


  constructor(
    private plans: TherapyPlansService,
    private toastService: ToastService,
    private auth: AuthService,
    public utils: UtilsService
  ){}

  partnerIdFilterChangeFn($event: string[]){
    if(!this.gridRequest.filters){
      this.gridRequest.filters = {
        [UniqueTherapyPlanSubscriptionsFilter.PARTNER_ID]: [],
        [UniqueTherapyPlanSubscriptionsFilter.TEST_USER] : [],
      }
    }
      if($event && $event.length){
        this.gridRequest.filters.PARTNER_ID = $event
      }
  }

  formatToRupees(value: number): string {
    return `₹${value.toLocaleString()}`
  }

  goToFilterUsers(userId: string){
    window.open(
      `therapy-plans/subscriptions?userId=${userId}`,
    );
  }

  showTestUsers(){
    if(!this.gridRequest){
      this.gridRequest = {
        page: this.page,
        pageSize: this.pageSize,
        globalSearch: [],
        export: false
      }
    }
    if (!this.gridRequest.filters) {
      this.gridRequest.filters = {
        PARTNER_ID: undefined,
        TEST_USER: undefined,
      };
    }
    this.gridRequest.filters!.TEST_USER = this.showTestCount ? ['true'] : ['false']
    this.fetchList()
  }


  fetchList(){
    this.pageLoading = true
    this.plans.uniqueUserTherapySubslist(this.gridRequest).subscribe({
      next: (value) => {
        this.totalUsers = 0
        this.totalActiveUsers = 0
        this.totalActivePlans = 0
        this.totalPlans = 0
        this.retailValue = 0
        this.corporateValue = 0
        value.statsData.forEach((data) => {
          if(data.retailUser){
            this.retailValue += data.totalPlanValue
          }
          if(data.corporateUser){
            this.corporateValue += data.totalPlanValue
          }
          if(data.activePlans > 0){
          this.totalActiveUsers += 1
          }
          this.totalPlans += data.totalPlans
          this.totalActivePlans += data.activePlans
        })
        value.data.forEach((data) => {
          data.color = this.utils.getRandomColor()
        })
        this.totalUsers = value.statsData.length
        this.therapyPlansSubscriptionsForEachUsers = value.data
        this.partnerIdCheckBoxOptions = value.filterOptions.partners.map((data) => {
          if(this.gridRequest.filters?.PARTNER_ID?.includes(data.partnerId)){
            return {
              value: data.partnerId,
              label: data.partnerId+'-'+data.companyName,
              checked: true
            }
          }else{
            return {
              value: data.partnerId,
              label: data.partnerId+'-'+data.companyName,
              checked: false
            }
          }
        })
        this.cards = [
          {
            title: 'Total Users',
            count: this.totalUsers,
            userLogo: true,
            upLogo: false,
          },
          {
            title: 'Active Users',
            count: this.totalActiveUsers,
            userLogo: true,
            upLogo: false,
          },
          {
            title: 'Total Plans',
            count: this.totalPlans,
            userLogo: false,
            upLogo: true,
          },
          {
            title: 'Active Plans',
            count: this.totalActivePlans,
            userLogo: false,
            upLogo: true,
          },
          {
            title: 'Retail Value',
            count: this.formatToRupees(this.retailValue),
            userLogo: false,
            upLogo: false,
          },
          {
            title: 'Corporate Value',
            count: this.formatToRupees(this.corporateValue),
            userLogo: false,
            upLogo: false,
          }
        ]

        this.pageLoading = false;
      }, error: (error) => {
        this.pageLoading = false;
        const errorBody = error.error as IAnyoError;
        this.toastService.showAnyoErrorToast(errorBody.description);
      }
    })
  }

  searchGlobal(){
    if(!this.gridRequest){
      this.gridRequest = {
        page: this.page,
        pageSize: this.pageSize,
        globalSearch: [],
        export: false
      }
    }
    if (!this.gridRequest.filters) {
      this.gridRequest.filters = {
        PARTNER_ID: undefined,
        TEST_USER: undefined,
      };
    }
    this.gridRequest.filters!.TEST_USER = this.showTestCount ? ['true'] : ['false']

    if(this.globalSearchInput.length){
      this.gridRequest.globalSearch = [this.globalSearchInput.trim()]
      this.fetchList()
    }
  }

  nzPageNoChange(index: number){
    this.page = index;
    this.gridRequest.page = index;
    this.fetchList()
  }
  nzPageSizeChange(pageSize: number){
    this.pageSize = pageSize
    this.gridRequest.pageSize = pageSize;
    this.fetchList()
  }

  resetFilter(){
    this.globalSearchInput = ''
    this.gridRequest = {
        page: this.page,
        pageSize: this.pageSize,
        globalSearch: [],
        export: false,
    }
    if (!this.gridRequest.filters) {
      this.gridRequest.filters = {
        PARTNER_ID: undefined,
        TEST_USER: undefined,
      };
    }
    this.gridRequest.filters.TEST_USER = ['false']
    this.showTestCount = false
    this.fetchList()
  }

  ngOnInit(){
    this.auth.currentAuthStatus.subscribe((user) => {
      if(user){
        this.pageLoading = true;
        if(!this.gridRequest){
          this.gridRequest = {
            page: 1,
            pageSize: this.pageSize,
            globalSearch: [],
            export: false,
          }
        }
        if (!this.gridRequest.filters) {
          this.gridRequest.filters = {
            PARTNER_ID: undefined,
            TEST_USER: undefined,
          };
        }
        this.gridRequest.filters!.TEST_USER = [this.showTestCount ? 'true' : 'false']
        this.fetchList()
      }
    })
  }
}

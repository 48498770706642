<div class="flex flex-col justify-start items-start mb-10">
    <div class="flex flex-row flex-nowrap md:gap-3 items-center">
        <h1 class="headeradminui-text md:break-keep">Manage Reward Values</h1>
    </div>
</div>

<ng-template #tableHead>
    <div nz-row nzJustify="space-between">
        <div nz-col>
            <span class="font-bold">Reward Values</span>
        </div>
    </div>
</ng-template>

<nz-spin [nzSpinning]="spinner" nzTip="Fetching data......" class="w-auto h-screen">
    <nz-table
        #tableHead
        [nzBordered]="true"
        [nzData]="rewardData"
        [nzSize]="'middle'"
        [nzShowPagination]="false"
        [nzScroll]="{ x: '100%' }">
        <thead>
            <tr>
                <th nzWidth="25%">ID</th>
                <th nzWidth="25%">Name</th>
                <th nzWidth="20%">Amount</th>
                <th nzWidth="15%">Actions</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let data of rewardData; let i = index">
                <td>{{ data.id }}</td>
                <td>{{ data.name }}</td>
                <td>
                    <div *ngIf="editingRow !== i; else editField">
                        {{ data.amount }}
                    </div>
                    <ng-template #editField>
                        <input nz-input [(ngModel)]="data.amount" (blur)="saveAmount(i)" />
                    </ng-template>
                </td>
                <td>
                    <button nz-button nzType="link" (click)="toggleEdit(i)">
                        {{ editingRow === i ? 'Save' : 'Edit' }}
                    </button>
                </td>
            </tr>
        </tbody>
    </nz-table>
</nz-spin>

<!-- eslint-disable @angular-eslint/template/label-has-associated-control -->
<div class="flex flex-row justify-between items-center mb-3">
    <div class="flex flex-row md:gap-3 items-center">
      <div class="flex flex-row flex-nowrap md:gap-3 items-center">
        <h1 class="headeradminui-text md:break-keep">Manage Experts Rewards</h1>
      </div>
    </div>
  </div>
  <div nz-col class="flex flex-row justify-end items-center space-x-3">
    <button 
      nz-button 
      nzType="primary"
      (click)="resetAllFilter()"  
      class="reset-button">
      Reset Filter
    </button>
    <nz-range-picker
      [nzFormat]="dateFormat"
      [(ngModel)]="dateRange"
      (ngModelChange)="dateChange($event)"
      nzSize="large"
    ></nz-range-picker>
  </div>
  
  <nz-spin [nzSpinning]="spinner" nzTip="Fetching Rewards...">
    <div nz-row>
      <nz-tabset (nzSelectChange)="onTabChange($event)">
        <nz-tab nzTitle="Complete">
          <nz-table
            [nzBordered]="true"
            [nzData]="gridData"
            [nzSize]="'middle'"
            [nzShowPagination]="true"
            [nzScroll]="{ x: '100%' }"
            [nzShowPagination]="true"
    nzFrontPagination="false"
    [nzTotal]="totalRecords"
    [nzPageSize]="pageSize"
    [nzBordered]="true"
    [nzShowSizeChanger]="true"
    [nzPageSizeOptions]="pageSizeOptions"
    [nzPageIndex]="currentPage"
    nzTableLayout="fixed"
    (nzPageIndexChange)="nzPageIndexChange($event)"
    (nzPageSizeChange)="nzPageSizeChange($event)">
            <thead>
              <tr>
                <th>Expert Name
                  <nz-filter-trigger [nzActive]="true" [nzDropdownMenu]="userSelectionMenu">
                    <span nz-icon nzType="filter"></span>
                  </nz-filter-trigger>
                </th>
                <th >Duration</th>
                <th >Total Amount</th>
                <th>Paid On</th>
                <th>Transaction Id</th>
                <th>Mode of Payment</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let data of gridData">
                <td>{{ data.userName }}</td>
                <td>{{ utils.convertEpochToFormattedDate(data.duration!.start) + "-" + utils.convertEpochToFormattedDate(data.duration!.end) }}</td>
                <td>{{ data.debitValue < 0 ? "Rs " + (-data.debitValue) : "Rs " + data.debitValue }}</td>
                <td>{{ utils.convertEpochToFormattedDate(data.date) }}</td>
                <td>{{ data.transactionId }}</td>
                <td>{{ data.modeOfPayment }}</td>
                <td>
                  <button nz-button nzType="link" (click)="openDrawer(data)">
                      View Ledger
                    </button>
                  </td>
              </tr>
            </tbody>
          </nz-table>
        </nz-tab>
        <nz-tab nzTitle="Pending">
          <nz-table
            [nzBordered]="true"
            [nzData]="gridData"
            [nzSize]="'middle'"
            [nzShowPagination]="false"
            [nzScroll]="{ x: '100%' }"
            nzTableLayout="fixed">
            <thead>
              <tr>
                <th>
                  Expert Name
                  <nz-filter-trigger [nzActive]="true" [nzDropdownMenu]="userSelectionMenu">
                    <span nz-icon nzType="filter"></span>
                  </nz-filter-trigger>
                </th>
                <th>Duration</th>
                <th>Total Amount Pending</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let data of gridData">
                <td>{{ data.userName }}</td>
                <td>{{ utils.convertEpochToFormattedDate(data.duration!.start) + "-" + utils.convertEpochToFormattedDate(data.duration!.end) }}</td>
                <td>{{ data.creditValue }}</td>
                <td>
                  <button nz-button nzType="link" (click)="openDrawer(data)">
                    View Ledger
                  </button>
                  <button nz-button nzType="link" (click)="openPaymentModal(data.userId, data.creditValue)">
                    Pay Now
                  </button>
                </td>
              </tr>
            </tbody>
          </nz-table>
        </nz-tab>        
        <nz-tab nzTitle="All">
          <nz-table
            [nzBordered]="true"
            [nzData]="gridData"
            [nzSize]="'middle'"
            [nzShowPagination]="true"
            [nzScroll]="{ x: '100%' }"
            nzFrontPagination="false"
            [nzTotal]="totalRecords"
            [nzPageSize]="pageSize"
            [nzBordered]="true"
            [nzShowSizeChanger]="true"
            [nzPageSizeOptions]="pageSizeOptions"
            [nzPageIndex]="currentPage"
            nzTableLayout="fixed"
            (nzPageIndexChange)="nzPageIndexChange($event)"
    (nzPageSizeChange)="nzPageSizeChange($event)">
            <thead>
              <tr>
                <th>Name
                  <nz-filter-trigger [nzActive]="true" [nzDropdownMenu]="userSelectionMenu">
                    <span nz-icon nzType="filter"></span>
                  </nz-filter-trigger>
                </th>
                <th>Reward Type
                  <nz-filter-trigger [nzActive]="true" [nzDropdownMenu]="rewardSelectionMenu">
                    <span nz-icon nzType="filter"></span>
                  </nz-filter-trigger>
                </th>
                <th>Date</th>
                <th>Transaction Type
                  <nz-filter-trigger [nzActive]="true" [nzDropdownMenu]="transactionTypeMenu">
                    <span nz-icon nzType="filter"></span>
                  </nz-filter-trigger>
                </th>
                <th>Amount</th>
                <th>Transaction Id</th>
            </tr>
            </thead>
            <tbody>
              <tr *ngFor="let data of gridData">
                <td>{{ data.userName }}</td>
                <td>{{ data.description }}</td>
                <td>{{ utils.convertEpochToFormattedDate(data.date) }}</td>
                <td>{{ data.debitValue < 0 ? "Debit" : "Credit" }}</td>
                <td>{{ data.debitValue < 0 ? "Rs " + data.debitValue : "Rs " + data.creditValue }}</td>

                <td>{{ data.transactionId }}</td>
              </tr>
            </tbody>
          </nz-table>
        </nz-tab>
      </nz-tabset>
    </div>
  </nz-spin>
  <nz-drawer
  [nzVisible]="isDrawerVisible"
  [nzPlacement]="'bottom'"
  [nzHeight]="'500px'"
  [nzClosable]="true"
  nzTitle="Edit Expert Reward"
  (nzOnClose)="closeDrawer()">
  <ng-container *nzDrawerContent>
    <nz-spin [nzSpinning]="spinner" nzSize="large">
 <nz-table
            [nzBordered]="true"
            [nzData]="viewLedgerGridData"
            [nzSize]="'middle'"
            [nzShowPagination]="false"
            [nzScroll]="{ x: '100%' }"
            >
            <thead>
              <tr>
                <th>Date</th>
                <th>Reward Type</th>
                <th>Amount</th>
                <th>Transaction Id</th>
            </tr>
            </thead>
            <tbody>
              <tr *ngFor="let data of viewLedgerGridData">
                <td>{{ utils.convertEpochToFormattedDate(data.date) }}</td>
                <td>{{ data.description }}</td>
                <td>{{ "Rs " +  data.creditValue }}</td>
                <td>{{ data.transactionId }}</td>
              </tr>
            </tbody>
          </nz-table>
        </nz-spin>
</ng-container>
</nz-drawer>
<nz-modal
  [(nzVisible)]="isPaymentModalVisible"
  nzTitle="Make Payment"
  [nzFooter]="null"
  (nzOnCancel)="closePaymentModal()"
>
<ng-container *nzModalContent>
  <form [formGroup]="paymentForm" (ngSubmit)="submitPaymentForm()" class="flex flex-col space-y-3" >
    <nz-form-item >
      <nz-form-label [nzSpan]="6">Amount</nz-form-label>
      <nz-form-control [nzSpan]="18">
        <input
          nz-input
          formControlName="amount"
          type="number"
          placeholder="Enter amount"
          class="input-field"
        />
        <div
          class="error-text"
        >
          *As of now you can only pay full amount
        </div>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item>
      <nz-form-label [nzSpan]="6">Transaction ID</nz-form-label>
      <nz-form-control [nzSpan]="18">
        <input
          nz-input
          formControlName="transactionId"
          type="text"
          placeholder="Enter transaction ID"
          class="input-field"
        />
        <div
          *ngIf="paymentForm.controls['transactionId'].invalid && paymentForm.controls['transactionId'].touched"
          class="error-text"
        >
          Transaction ID is required.
        </div>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item >
      <nz-form-label [nzSpan]="6">Mode of Payment</nz-form-label>
      <nz-form-control [nzSpan]="18">
        <input
          nz-input
          formControlName="modeOfPayment"
          type="text"
          placeholder="Enter mode of payment (e.g. Online or Offline)"
          class="input-field"
        />
        <div
          *ngIf="paymentForm.controls['modeOfPayment'].invalid && paymentForm.controls['modeOfPayment'].touched"
          class="error-text"
        >
          Mode of payment is required.
        </div>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item class="flex flex-row justify-end " >
      <nz-form-control>
        <button
          nz-button
          nzType="primary"
          [disabled]="paymentForm.invalid"
          type="submit"
          class="submit-button ml-3"
        >
          Submit
        </button>
        <button nz-button nzType="default" (click)="closePaymentModal()" type="button">
          Cancel
        </button>
      </nz-form-control>
    </nz-form-item>
  </form>
</ng-container>

</nz-modal>

<nz-dropdown-menu #transactionTypeMenu="nzDropdownMenu">
  <div class="bg-white drop-shadow-md m-1 p-2 rounded-md">
    <div nz-row>
      <div nz-col>
        <span class="font-bold">Transaction Type</span>
        <nz-divider></nz-divider>
        <nz-radio-group [(ngModel)]="selectedTransactionType">
          <div *ngFor="let option of transactionFilterOption" nz-row>
            <div nz-col>
              <label nz-radio [nzValue]="option">{{ option }}</label>
            </div>
          </div>
        </nz-radio-group>
      </div>
    </div>
    <div nz-row nzJustify="end">
      <div nz-col>
        <button class="m-1" nz-button nzType="primary" (click)="getAllOrPendingGridData()">
          Ok
        </button>
      </div>
      <div nz-col>
        <button class="m-1" nz-button nzType="primary" (click)="resetFilterAndFetchData('TRANSACTION_TYPE')">
          Reset
        </button>
      </div>
    </div>
  </div>
</nz-dropdown-menu>

<!-- User Selection Dropdown -->
<nz-dropdown-menu #userSelectionMenu="nzDropdownMenu">
  <div class="bg-white drop-shadow-md m-1 p-2 rounded-md">
    <div nz-row>
      <div nz-col>
        <span class="font-bold">Select User</span>
        <nz-divider></nz-divider>
        <nz-radio-group [(ngModel)]="selectedUserId">
          <div *ngFor="let user of nameFilterOption.userInfo" nz-row>
            <div nz-col>
              <label nz-radio [nzValue]="user">{{ user.userName }}</label>
            </div>
          </div>
        </nz-radio-group>
      </div>
    </div>
    <div nz-row nzJustify="end">
      <div nz-col>
        <button class="m-1" nz-button nzType="primary" (click)="onSubmitFilterAndFetchData()">
          Ok
        </button>
      </div>
      <div nz-col>
        <button class="m-1" nz-button nzType="default" (click)="resetFilterAndFetchData('USER_ID')">
          Reset
        </button>
      </div>
    </div>
  </div>
</nz-dropdown-menu>

<nz-dropdown-menu #rewardSelectionMenu="nzDropdownMenu">
  <div class="bg-white drop-shadow-md m-1 p-2 rounded-md">
    <div nz-row>
      <div nz-col>
        <span class="font-bold">Select Reward Type</span>
        <nz-divider></nz-divider>
        <nz-radio-group [(ngModel)]="selectedRewardType">
          <div *ngFor="let reward of rewardTypeFilter" nz-row>
            <div nz-col>
              <label nz-radio [nzValue]="reward">{{ reward }}</label>
            </div>
          </div>
        </nz-radio-group>
      </div>
    </div>
    <div nz-row nzJustify="end">
      <div nz-col>
        <button class="m-1" nz-button nzType="primary" (click)="onSubmitFilterAndFetchData()">
          Ok
        </button>
      </div>
      <div nz-col>
        <button class="m-1" nz-button nzType="default" (click)="resetFilterAndFetchData('REWARD_TYPE')">
          Reset
        </button>
      </div>
    </div>
  </div>
</nz-dropdown-menu>



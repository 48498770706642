<!-- eslint-disable @angular-eslint/template/interactive-supports-focus -->
<!-- eslint-disable @angular-eslint/template/click-events-have-key-events -->
<!-- eslint-disable @angular-eslint/template/label-has-associated-control -->
<!-- eslint-disable @angular-eslint/template/alt-text -->
<div class="flex flex-row flex-nowrap md:gap-3 items-center justify-between">
  <div class="flex flex-wrap w-full justify-start">
    <img
      [routerLink]="['/content']"
      class="left-arrow clickable"
      src="../../../../assets/icons/left-arrow.svg"
    />
    <h1 *ngIf="!contentId" class="headeradminui-text pl-2">
      Add Recipe Content
    </h1>
    <h1 *ngIf="contentId" class="headeradminui-text pl-2">
      Update Recipe Content
    </h1>
  </div>
</div>
<nz-spin [nzSpinning]="contentSpinning" nzTip="Loading...">
  <div class="custom-card mt-2">
    <div class="card-body">
      <div nz-row nzJustify="end" *ngIf="updatePage">
        <div nz-col>
          <button nz-button nzType="primary" (click)="editMode()">
            Edit Content
          </button>
        </div>
      </div>
      <div [nzGutter]="16" nz-row nzAlign="middle" nzJustify="space-evenly">
        <div nz-col nzSpan="6" class="flex flex-col gap-2">
          <label>Category :</label>
          <nz-select
            (ngModelChange)="categoryChangeFn($event)"
            [(ngModel)]="selectedCategoryString"
            class="w-[100%] mr-1"
            nzAllowClear
            nzPlaceHolder="Select a Category"
            nzShowSearch
            [nzDisabled]="updatePage && !editModeCheck"
          >
            <nz-option
              *ngFor="let category of categories"
              [nzLabel]="category.label"
              [nzValue]="category.value"
            ></nz-option>
          </nz-select>
        </div>
        <div nz-col nzSpan="6" class="flex flex-col gap-2">
          <label>Time Taken (In Minutes) :</label>
          <input
            [(ngModel)]="timeTaken"
            class="w-[100%] mr-1"
            nz-input
            placeholder="Time taken in Minutes"
            [disabled]="updatePage && !editModeCheck"
          />
        </div>
        <div nz-col nzSpan="6" class="flex flex-col gap-2">
          <label>Author :</label>
          <input
            [(ngModel)]="author"
            class="w-[100%] mr-1"
            nz-input
            placeholder="Author's Name"
            [disabled]="updatePage && !editModeCheck"
          />
        </div>
        <div nz-col nzSpan="6" class="flex flex-col gap-2">
          <label>User Interest :</label>
          <nz-select
            [(ngModel)]="selectedUserInterests"
            nzPlaceHolder="Select User Interests"
            nzMode="multiple"
            [nzDisabled]="updatePage && !editModeCheck"
          >
            <nz-option
              *ngFor="let item of userInterestTopics"
              [nzLabel]="item.value"
              [nzValue]="item.key"
            ></nz-option>
          </nz-select>
        </div>
      </div>
      <div [nzGutter]="16" class="mt-5" nz-row nzJustify="space-evenly">
        <div nz-col nzSpan="10" class="flex flex-col gap-2">
          <label>Recipe Name :</label>
          <input
            [(ngModel)]="practiceName"
            class="w-[100%] mr-1"
            nz-input
            nzSize="large"
            placeholder="Recipe Name"
            [disabled]="updatePage && !editModeCheck"
          />
        </div>
        <div nz-col nzSpan="10" class="flex flex-col gap-2">
          <label>Recipe Description :</label>
          <textarea
            [(ngModel)]="recipeDescription"
            [disabled]="updatePage && !editModeCheck"
            nz-input
            rows="4"
          ></textarea>
        </div>
        <div nz-col nzSpan="4" class="flex flex-col gap-2">
          <label>Visible :</label>
          <nz-switch
            [nzDisabled]="updatePage && !editModeCheck"
            [(ngModel)]="isVisible"
          ></nz-switch>
        </div>
      </div>
      <div [nzGutter]="16" class="mt-5" nz-row nzJustify="start">
        <div nz-col nzSpan="8" class="flex flex-col gap-2">
          <label>Upload Images :</label>
          <input
            (change)="uploadImages($event)"
            multiple
            accept=".jpg,.jpeg"
            type="file"
            [disabled]="updatePage && !editModeCheck"
          />
        </div>
        <div nz-col nzSpan="8" class="flex flex-col gap-2">
          <video *ngIf="videoUrlString.length" controls>
            <source
              class="h-60 object-cover"
              [src]="videoUrlString"
              type="video/mp4"
            />
            Your browser does not support the video tag.
          </video>
          <label>Upload Video :</label>
          <input
            (change)="uploadMedia($event)"
            accept=".mp3,.mp4"
            type="file"
            [disabled]="updatePage && !editModeCheck"
          />
        </div>
        <div nz-col nzSpan="8" class="flex flex-col gap-2">
          <label>Ingredients :</label>
          <div
            class="flex gap-2"
            *ngFor="let ingredient of ingredients; let i = index"
          >
            <div class="passenger-input">
              <nz-ribbon
                nzSize="default"
                [nzColor]="ingredient.color"
                [nzText]="(i + 1).toString()"
              >
                <nz-card [nzTitle]="'Ingredient ' + (i + 1)">
                  <input
                    [nzSize]="'large'"
                    nz-input
                    [placeholder]="'Ingredient Name ' + (i + 1)"
                    [(ngModel)]="ingredient.name"
                    [disabled]="updatePage && !editModeCheck"
                  />
                  <input
                    class="mt-2"
                    [nzSize]="'large'"
                    nz-input
                    [placeholder]="'Ingredient Url (Optional)'"
                    [(ngModel)]="ingredient.url"
                    [disabled]="updatePage && !editModeCheck"
                  />
                </nz-card>
              </nz-ribbon>
            </div>
            <span
              nz-icon
              nzType="minus-circle-o"
              class="dynamic-delete-button"
              (click)="removeIngredientField(i)"
            ></span>
          </div>
          <div>
            <button
              nz-button
              [disabled]="updatePage && !editModeCheck"
              nzType="dashed"
              class="add-button"
              (click)="addIngredientField()"
            >
              <span nz-icon nzType="plus"></span>
              Add Ingredient
            </button>
          </div>
        </div>
      </div>

      <nz-divider *ngIf="images.length" nzText="Images"></nz-divider>

      <div
        class="flex flex-wrap justify-around items-center pr-4 pl-4 pb-5 media-container"
        *ngIf="images.length"
      >
        <div *ngFor="let media of images; index as i" class="pt-5">
          <nz-card
            [nzExtra]="deleteImageTemplate"
            nzHoverable
            style="width: 400px"
            [nzCover]="coverTemplate"
          >
            <!-- <nz-card-meta [nzTitle]="imagesFile[i].name"> </nz-card-meta> -->
          </nz-card>
          <ng-template #coverTemplate>
            <ng-container>
              <img
                class="h-60 object-cover"
                [src]="media"
                alt="Selected Image"
              />
            </ng-container>
          </ng-template>
          <ng-template #deleteImageTemplate>
            <span
              (click)="deleteImage(i)"
              nz-icon
              nzTheme="outline"
              nzType="delete"
            ></span>
          </ng-template>
        </div>
      </div>
      <div nz-row [nzGutter]="16" class="mt-3">
        <div nz-col nzSpan="12" class="flex flex-col gap-2">
          <label>Facts :</label>
          <div class="flex gap-2" *ngFor="let fact of facts; let i = index">
            <div class="passenger-input">
              <nz-ribbon
                nzSize="default"
                [nzColor]="fact.color"
                [nzText]="(i + 1).toString()"
              >
                <textarea
                  nz-input
                  [placeholder]="'fact ' + (i + 1)"
                  [(ngModel)]="fact.fact"
                  [disabled]="updatePage && !editModeCheck"
                  rows="4"
                ></textarea>
              </nz-ribbon>
            </div>
            <span
              nz-icon
              nzType="minus-circle-o"
              class="dynamic-delete-button"
              (click)="removeFactField(i)"
            ></span>
          </div>
          <div>
            <button
              nz-button
              [disabled]="updatePage && !editModeCheck"
              nzType="dashed"
              class="add-button"
              (click)="addFactsField()"
            >
              <span nz-icon nzType="plus"></span>
              Add Facts
            </button>
          </div>
        </div>
        <div nz-col nzSpan="12" class="flex flex-col gap-2">
          <label>Steps :</label>
          <div
            class="flex gap-2"
            *ngFor="let step of directions; let i = index"
          >
            <div class="passenger-input">
              <nz-ribbon
                nzSize="default"
                [nzColor]="step.color"
                [nzText]="(i + 1).toString()"
              >
                <textarea
                  nz-input
                  [placeholder]="'step ' + (i + 1)"
                  [(ngModel)]="step.stepDescription"
                  [disabled]="updatePage && !editModeCheck"
                  rows="4"
                ></textarea>
              </nz-ribbon>
            </div>
            <span
              nz-icon
              nzType="minus-circle-o"
              class="dynamic-delete-button"
              (click)="deleteSteps(i)"
            ></span>
          </div>
          <div>
            <button
              nz-button
              [disabled]="updatePage && !editModeCheck"
              nzType="dashed"
              class="add-button"
              (click)="addSteps()"
            >
              <span nz-icon nzType="plus"></span>
              Add Steps
            </button>
          </div>
        </div>
      </div>

      <nz-divider nzText="Nutritional Information"></nz-divider>
      <div nz-row nzJustify="end">
        <div nz-col>
          <button
            [disabled]="updatePage && !editModeCheck"
            (click)="addNutriInfo()"
            nz-button
            nzType="primary"
          >
            Add Nutrition Info
          </button>
        </div>
      </div>
      <div class="common-container">
        <div
          *ngFor="let singleNutriInfo of nutritionalInfo; index as i"
          class="mt-3"
          nz-row
        >
          <nz-card
            [nzExtra]="deleteNutriInfoTemplate"
            [nzTitle]="'Nutri Info ' + (i + 1)"
            style="width: 100%"
          >
            <div [nzGutter]="16" class="mt-5" nz-row nzJustify="space-evenly">
              <div nz-col nzSpan="12">
                <label>Key Name :</label>
                <input
                  [(ngModel)]="singleNutriInfo.key"
                  [disabled]="updatePage && !editModeCheck"
                  class="w-[100%] mr-1"
                  nz-input
                  nzSize="large"
                  placeholder="Key"
                />
              </div>
              <div nz-col nzSpan="12">
                <label>Value :</label>
                <input
                  [(ngModel)]="singleNutriInfo.value"
                  [disabled]="updatePage && !editModeCheck"
                  class="w-[100%] mr-1"
                  nz-input
                  nzSize="large"
                  placeholder="Value"
                />
              </div>
            </div>
          </nz-card>
          <ng-template #deleteNutriInfoTemplate>
            <span
              (click)="deleteNutriInfo(i)"
              nz-icon
              nzTheme="outline"
              nzType="delete"
            ></span>
          </ng-template>
        </div>
      </div>
      <div class="mt-5" nz-row nzJustify="end" *ngIf="!contentId">
        <div nz-col>
          <button (click)="saveContent()" nz-button nzType="primary">
            Save Recipe
          </button>
        </div>
      </div>
      <div class="mt-5" nz-row nzJustify="end" *ngIf="contentId">
        <div nz-col>
          <button
            [disabled]="updatePage && !editModeCheck"
            (click)="updateContent()"
            nz-button
            nzType="primary"
          >
            Update Recipe
          </button>
        </div>
      </div>
    </div>
  </div>
</nz-spin>

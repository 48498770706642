import { Injectable } from '@angular/core';
import { NetworkUtilsService, URL_DICT } from 'projects/app-core/src/service/network-utils.service';
import { DiscountFor, DiscountType, IAnyoPromocode } from '../models/IAnyoPromoCode';
import { PromoCodeGridRequest } from '../models/promoCodeGridRequest';
import { PromoCodeGridResponse } from '../models/promoCodeGridResponse';

export interface PromoCodeRequestCreate {
  partnerIds: string[];
  isTest: boolean;
  description: string;
  internalNote: string;
  promocode: string;
  active: boolean;
  visible: boolean;
  validFrom?: string;
  validTill?: string;
  termsAndConditions?: string;
  discountDetails: Record<DiscountFor, {
    ids: {
        key: string;
        value?: string;
      }[]
    discountDetails: {
      type: DiscountType;
      discount: {
        minimumAmount?: number;
        currency: string;
        amount: number;
        maxAmount?: number;
      }[];
    };
  }>;
  maxCount: number;
  maxUsageCount: number;
}

export interface PromoCodeRequestUpdate {
  userIds: string[];
  partnerIds: string[];
  isTest: boolean;
  description: string;
  internalNote: string;
  active: boolean;
  visible: boolean;
  validFrom?: string;
  validTill?: string;
  termsAndConditions?: string;
  maxCount: number;
  maxUsageCount: number;
}


@Injectable({
  providedIn: 'root'
})
export class PromoCodeService {

  constructor(private api: NetworkUtilsService) { }

  public createPromoCode (req:PromoCodeRequestCreate){
    return this.api.post(URL_DICT.adminPromoCodeCreateUrl,req)
  }

  public getPromoCode (id: string){
    return this.api.get<IAnyoPromocode>(URL_DICT.adminPromoCodeCreateUrl +'/'+ id)
  }

  public updatePromoCode(id: string, req:PromoCodeRequestUpdate){
    return this.api.post(URL_DICT.adminPromoCodeCreateUrl +'/' + id,req)
  }

  public promoCodeGridlist (req:PromoCodeGridRequest){
    return this.api.post<PromoCodeGridResponse>(URL_DICT.adminPromoCodeGridUrl,req)
  }
}

import { Injectable } from '@angular/core';
import {
  NetworkUtilsService,
  URL_DICT,
} from 'projects/app-core/src/service/network-utils.service';
import { AnyoSurvey } from '../models/survey/AnyoSurvey';
import { SurveysGridResponse } from '../models/survey/surveysGridResponse';
import { SurveysGridRequest } from '../models/survey/surveysGridRequest';
import { SurveyResponsesGridRequest } from '../models/survey/SurveyResponsesGridRequest';
import { SurveyResponsesGridResponse } from '../models/survey/surveyResponsesGridRespone';

@Injectable({
  providedIn: 'root',
})
export class SurveyService {
  constructor(private api: NetworkUtilsService) {}

  createSurvey(data: AnyoSurvey) {
    return this.api.post(URL_DICT.adminSurveyCreateUrl, data);
  }

  fetchSurveysGrid(req: SurveysGridRequest){
    return this.api.post<SurveysGridResponse>(URL_DICT.adminSurveysGridUrl,req);
  }

  getSurveyById(id: string){
    return this.api.get<AnyoSurvey>(URL_DICT.adminSurveysGridUrl + "/" + id);
  }

  fetchSurveyResponsesGrid(gridRequest: SurveyResponsesGridRequest){
    return this.api.post<SurveyResponsesGridResponse>(URL_DICT.adminSurveyResponsesGridUrl,gridRequest)
  }
}

import { GridRequest } from "projects/app-core/src/models/gridRequest";

export interface SurveyResponsesGridRequest
  extends GridRequest<
    SurveyRespSort,
    SurveyRespFilter,
    SurveyRespSearch,
    SurveyRespDateFilter
  > {}

export enum SurveyRespSort {
  CREATED_AT = "CREATED_AT",
}

export enum SurveyRespSearch {
  PARTNER_ID = "PARTNER_ID",
}

export enum SurveyRespDateFilter {
  CREATED_AT = "CREATED_AT",
}

export enum SurveyRespFilter {
  SURVEY = "SURVEY",
}

import { Component } from '@angular/core';
import { UtilsService } from 'projects/app-core/src/service/utils.service';
import { MasterdataService } from '../../../services/masterdata.service';
import { AuthService } from 'projects/app-core/src/auth/auth.service';
import { TherapyPlansService } from '../../../services/therapy-plans.service';
import { EventsService } from '../../../services/events.service';
import { PromoCodeRequestUpdate, PromoCodeService } from '../../../services/promo-code.service';
import { ToastService } from 'projects/app-core/src/app/services/toastr.service';
import { ActivatedRoute, Router } from '@angular/router';
import { DiscountFor, DiscountType } from '../../../models/IAnyoPromoCode';
import * as moment from 'moment';
import { Currencies } from '../../../models/Currencies';
import { IAnyoError } from 'projects/app-core/src/models/errorModal';
import { UserTags } from 'projects/app-core/src/app/posts/model/CirclePost';
import { SessionsService } from 'projects/app-core/src/app/session/service/sessions.service';
import { AutocompleteDataSourceItem } from 'ng-zorro-antd/auto-complete';
import { SearchUserResponse } from 'projects/app-core/src/app/session/components/session-table/session-table.component';

interface DropdownOptions {
  value: string;
  data: string;
}

interface DiscountDetailsArray {
  isActive: boolean,
  key: DiscountFor,
  masterData: DropdownOptions[],
  ids: string[]
  discountDetails: {
    type: DiscountType;
    discount: {
      minimumAmount?: number;
      currency: string;
      amount: number;
      maxAmount?: number;
    }[];
  };
}

@Component({
  selector: 'app-edit-promo-code',
  templateUrl: './edit-promo-code.component.html',
  styleUrl: './edit-promo-code.component.scss'
})
export class EditPromoCodeComponent {
  pageLoading = false;
    promoCode = '';
    description = '';
    internalNote = '';
    termsAndConditions = '';
    isActive = false;
    isVisible = false;
    validFromDate!: Date;
    validTillDate!: Date;
    isTesting =  false;
    maxCount = 0;
    maxUsageCount = 0;
  
    onChangeValidFrom(result: Date){
      if(moment(result).isBefore(moment())){
        this.toast.showError("valid From Date should be in future !!")
      }
    }
    onChange(result: Date): void {
      if(moment(this.validFromDate).isAfter(moment(result))){
        this.toast.showError("valid Till Date should be in future from valid From Date !!")
      }
    }
    promocodeId = '';
    partners: DropdownOptions[] = [];
    users: DropdownOptions[] = [];
    listOfSelectedValue: string[] = [];
    selectedTherapyPlans = [];
    currency: Currencies = Currencies.INR;
    discountType: DiscountType = DiscountType.PERCENTAGE;
    discountTypeOptions: { key: string, value: string }[] = []; 
    discountDetails: DiscountDetailsArray[] = [
      {
        masterData: [],
        key: DiscountFor.THERAPY_PLAN,
        isActive: false,
        ids: [],
        discountDetails: {
          type: DiscountType.PERCENTAGE,
          discount: [
            {
              minimumAmount: 0,
              currency: this.currency,
              amount: 0,
              maxAmount: 0,
            }
          ]
        }
      },
      {
        masterData: [],
        key: DiscountFor.EVENTS,
        isActive: false,
        ids: [],
        discountDetails: {
          type: DiscountType.PERCENTAGE,
          discount: [
            {
              minimumAmount: 0,
              currency: this.currency,
              amount: 0,
              maxAmount: 0,
            }
          ]
        }
      },
      {
        masterData: [],
        key: DiscountFor.CLUB_PASSES,
        isActive: false,
        ids: [],
        discountDetails: {
          type: DiscountType.PERCENTAGE,
          discount: [
            {
              minimumAmount: 0,
              currency: this.currency,
              amount: 0,
              maxAmount: 0,
            }
          ]
        }
      }
    ];

  constructor(
      public utilsService : UtilsService,
      private masterDataService: MasterdataService,
      private auth: AuthService,
      private therapyPlans: TherapyPlansService,
      private events: EventsService,
      private promo: PromoCodeService,
      private toast: ToastService,
      private router: Router,
      private activatedRoute: ActivatedRoute,
      private sessionService: SessionsService
    ){}
    showModal = false;
    newSubmitProgress = false;
    searchSpinner = false;
    userSearchResults: AutocompleteDataSourceItem[] = [];
    userSearchResultsResponse: SearchUserResponse[] = [];
    selectedUser = '';
    openModal(){
      this.showModal = true;
    }
    closeModal(){
      this.showModal = false;
    }
    submitModalFn(){
      this.showModal = false;
      this.userIds = [this.selectedUser]
      this.updatePromoCode();
    }
    userIds: string[] = [];
    onSearchUser($event: Event) {
        const searchText = ($event.target as HTMLInputElement).value;
        if (searchText.length > 3) {
          this.searchSpinner = true;
          this.sessionService.adminSearchUsers(searchText).subscribe({
            next: (response) => {
              this.searchSpinner = false;
              this.userSearchResults = [];
              this.userSearchResultsResponse.push(...response);
              response.forEach((value) => {
                if(!this.userIds.includes(value.id)){
                  this.userSearchResults.push({
                    value: value.id,
                    label: value.email,
                  });
                }
              });
            },
            error: (error) => {
              this.searchSpinner = false;
              const errorBody = error.error as IAnyoError;
              this.toast.showAnyoErrorToast(errorBody.description);
            },
          });
        }
      }

  updatePromoCode(){
    this.pageLoading = true;
    const req: PromoCodeRequestUpdate = {
      userIds: this.userIds,
      partnerIds: this.listOfSelectedValue,
      isTest: this.isTesting,
      description: this.description,
      internalNote: this.internalNote,
      active: this.isActive,
      visible: this.isVisible,
      termsAndConditions: this.termsAndConditions,
      maxCount: this.maxCount,
      maxUsageCount: this.maxUsageCount
    }
     if(this.validFromDate != null){
          req.validFrom = moment(this.validFromDate).format('x');
        }
    if(this.validTillDate != null){
      req.validTill = moment(this.validTillDate).format('x');
    }
    this.promo.updatePromoCode(this.promocodeId,req).subscribe({
      next: (value) => {
        this.pageLoading = true;
        this.toast.showSuccess("Successfully Updated!!");
        this.router.navigate(['/promo-code/manage']);
      }, error: (err) => {
       this.pageLoading = true;
        const errorBody = err.error as IAnyoError;
        this.toast.showAnyoErrorToast(errorBody.description);
      }
    })
  }

  getPromoCodeDetails(){
    this.promo.getPromoCode(this.promocodeId).subscribe({
      next: (value) => {
        this.description = value.description;
        this.isActive = value.active;
        this.isVisible = value.visible;
        this.isTesting = value.tags?.includes(UserTags.TESTING) || false;
        this.promoCode = value.promocode;
        if(value.validFrom){
          this.validFromDate = value.validFrom;
        }
        if(value.validTill){
          this.validTillDate = value.validTill ;
        }
        this.internalNote = value.internalNote;
        this.termsAndConditions = value.termsAndConditions || '';
        this.maxCount = value.maxCount || 0;
        this.maxUsageCount = value.maxUsageCount || 0;
        this.listOfSelectedValue = value.applicablePartnerIds!;
        this.userIds = value.appicableUserIds!;
        
        this.discountDetails.forEach((data) => {
          if(value.discountDetails[data.key]){
            data.isActive = true;
            data.ids = value.discountDetails[data.key].ids.map((data) => data.key);
            data.discountDetails = value.discountDetails[data.key].discountDetails;
          }
        })
        this.masterDataService.masterDataList().subscribe({
          next: (value) => {
            this.discountDetails[1].masterData = value.events.map((data) => {
              return {
                data: data.name!,
                value: data._id!
              }
            })
            this.partners = value.partners.map((data) => {
              return {
                data: data.companyName!,
                value: data._id!
              }
            })
          }, error: (err) => {
           const errorBody = err.error as IAnyoError;
           this.toast.showAnyoErrorToast(errorBody.description);
          }
        })
        this.therapyPlans.fetchAllPlans().subscribe({
          next: (value) => {
            this.discountDetails[0].masterData = value.activePlans.map((data) => {
              return {
                data: data.planName,
                value: data.id
              }
            })
          }, error: (err) => {
            const errorBody = err.error as IAnyoError;
            this.toast.showAnyoErrorToast(errorBody.description);
          }
        })

        this.events.getAllClubPasses().subscribe({
          next: (value) => {
            this.discountDetails[2].masterData = value.map((data) => {
              return {
                data: data.passName,
                value: data.passId
              }
            })
          }, error: (err) => {
            const errorBody = err.error as IAnyoError;
            this.toast.showAnyoErrorToast(errorBody.description);
          }
        })
      }, error: (err) => {
        const errorBody = err.error as IAnyoError;
        this.toast.showAnyoErrorToast(errorBody.description);
      }
    })
  }

   ngOnInit(){
      this.discountTypeOptions = Object.entries(DiscountType).map(([key, value]) => {
        return {
          key: this.utilsService.convertToTitleCase(key),
          value: value
        }
      });
      this.auth.currentAuthStatus.subscribe((user) => {
        if(user){
          const id = this.activatedRoute.snapshot.params['id']
          this.promocodeId = id;
          this.getPromoCodeDetails();
  
       }
      })
    }
}

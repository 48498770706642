<div class="max-w-1xl mx-auto bg-white p-8 rounded-lg shadow-lg">
    <div class="flex flex-wrap pb-4 justify-start">
      <img
        [routerLink]="['/promo-code/manage']"
        class="left-arrow clickable"
        src="../../../../assets/icons/left-arrow.svg"
      />
      <h1 class="headeradminui-text pl-2">Create Promo Code</h1>
    </div>
    <nz-spin [nzSpinning]="pageLoading">
        <div class="flex flex-col gap-2">
            <div class="grid grid-cols-4 gap-4" >
                <div class="flex flex-col w-full">
                    <label class="form-label required">Promo Code :</label>
                    <input nz-input placeholder="Enter Promo Code" [(ngModel)]="promoCode" type="text" />
                  </div>
                  <div class="flex flex-col w-full" >
                    <label class="form-label required">Active :</label>
                    <nz-switch [(ngModel)]="isActive"></nz-switch>
                  </div>
                  <div class="flex flex-col w-full" >
                    <label class="form-label required">Visible :</label>
                    <nz-switch [(ngModel)]="isVisible"></nz-switch>
                  </div>
                  <div class="flex flex-col w-full" >
                    <label class="form-label required">Testing :</label>
                    <nz-switch [(ngModel)]="isTesting"></nz-switch>
                  </div>
              </div>
              <div class="grid grid-cols-2 gap-2" >
                <div class="flex flex-col w-full">
                    <label class="form-label required">Description :</label>
                    <textarea rows="4" nz-input [(ngModel)]="description"></textarea>
                  </div>
                  <div class="flex flex-col w-full">
                    <label class="form-label required">Internal Note :</label>
                    <textarea rows="4" nz-input [(ngModel)]="internalNote"></textarea>
                  </div>
              </div>
              <div class="grid grid-cols-3 gap-3" >
                <div class="flex flex-col w-full">
                    <label class="form-label">Terms And Conditions :</label>
                    <textarea rows="4" nz-input [(ngModel)]="termsAndConditions"></textarea>
                  </div>
                  <div class="flex flex-col w-full">
                    <label class="form-label">Valid From :</label>
                    <nz-date-picker [(ngModel)]="validFromDate" (ngModelChange)="onChangeValidFrom($event)"></nz-date-picker>
                  </div>
                  <div class="flex flex-col w-full">
                    <label class="form-label">Valid Till :</label>
                    <nz-date-picker [(ngModel)]="validTillDate" (ngModelChange)="onChange($event)"></nz-date-picker>
                  </div>
              </div>
              <div class="grid grid-cols-2 gap-2" >
                <div class="flex flex-col w-full">
                    <label class="form-label">Max Count (Denotes how many times single user gone applied) :</label>
                    <input nz-input placeholder="enter max count" [(ngModel)]="maxCount" type="number" />
                  </div>
                  <div class="flex flex-col w-full" >
                    <label class="form-label">Max Usage Count (Denotes How many times gone appiled) :</label>
                    <input nz-input placeholder="enter max count" [(ngModel)]="maxUsageCount" type="number" />
                  </div>
              </div>
              <div class="grid grid-cols-4 gap-4" >
                <!-- <div class="flex flex-col w-full" >
                    <label class="form-label">Applicable Users:</label>
                    <nz-select
                    nzMode="multiple"
                    nzPlaceHolder="Please select users"
                    [(ngModel)]="listOfSelectedValue"
                    >
                    <nz-option
                    *ngFor="let user of users"
                    [nzValue]="user.value"
                    [nzLabel]="user.data"
                  ></nz-option></nz-select>
                  </div> -->
                  <div class="flex flex-col w-full" >
                    <label class="form-label">Applicable Partners:</label>
                    <nz-select
                    nzMode="multiple"
                    nzPlaceHolder="Please select partners"
                    [(ngModel)]="listOfSelectedValue"
                    >
                    <nz-option
                    *ngFor="let partner of partners"
                    [nzValue]="partner.value"
                    [nzLabel]="partner.data"
                  ></nz-option>
                </nz-select>
                  </div>
              </div>
              <nz-divider nzText="Discount Details"></nz-divider>
                <div style="display: flex;flex-direction: column;gap: 10px;background: #ECECEC; padding:30px;">
                    <div *ngFor="let data of discountDetails" class="flex flex-col gap-2" >
                        <label class="form-label">{{utilsService.convertToTitleCase(data.key)}}</label>
                        <nz-switch [(ngModel)]="data.isActive"></nz-switch>
                        <nz-card [nzBordered]="false" [nzTitle]="utilsService.convertToTitleCase(data.key)" >
                            <div [nzGutter]="16" nz-row >
                                <div nz-col nzSpan="8">
                                    <label class="form-label required">{{utilsService.convertToTitleCase(data.key)}} :</label>
                                    <nz-select
                                      nzMode="multiple"
                                      [(ngModel)]="data.ids"
                                      class="w-[100%] mr-1"
                                      nzAllowClear
                                      [nzPlaceHolder]="'Select a '+ utilsService.convertToTitleCase(data.key)"
                                      nzShowSearch
                                      nzSize="large"
                                    >
                                      <nz-option
                                        *ngFor="let masData of data.masterData"
                                        [nzLabel]="masData.data"
                                        [nzValue]="masData.value"
                                      ></nz-option>
                                    </nz-select>
                                  </div>
                            </div>
                            <div [nzGutter]="16" nz-row nzAlign="middle" >
                                <div nz-col nzSpan="6">
                                    <label class="form-label">Min Amount :</label>
                                    <input
                                    [(ngModel)]="data.discountDetails.discount[0].minimumAmount"
                                    class="w-[100%] mr-1"
                                    nz-input
                                    nzSize="large"
                                    type="number"
                                    placeholder="Day Number"
                                    />
                                </div>
                                <div nz-col nzSpan="6">
                                  <label class="form-label">Max Amount :</label>
                                  <input
                                  [(ngModel)]="data.discountDetails.discount[0].maxAmount"
                                  class="w-[100%] mr-1"
                                  nz-input
                                  nzSize="large"
                                  type="number"
                                  placeholder="Day Number"
                                />
                                </div>
                                <div nz-col nzSpan="6">
                                  <label class="form-label required">Amount :</label>
                                  <input
                                  [(ngModel)]="data.discountDetails.discount[0].amount"
                                  class="w-[100%] mr-1"
                                  nz-input
                                  nzSize="large"
                                  type="number"
                                  placeholder="Day Number"
                                />
                                </div>
                                <div nz-col nzSpan="6">
                                  <label class="form-label required">Discount Type :</label>
                                  <nz-select
                                    [(ngModel)]="data.discountDetails.type"
                                    class="w-[100%] mr-1"
                                    nzAllowClear
                                    nzPlaceHolder="Select a Discount Type"
                                    nzShowSearch
                                    nzSize="large"
                                  >
                                    <nz-option
                                      *ngFor="let content of discountTypeOptions"
                                      [nzLabel]="content.key"
                                      [nzValue]="content.value"
                                    ></nz-option>
                                  </nz-select>
                                </div>
                            </div>
                        </nz-card>
                        
                      </div>
                      <nz-card [nzBordered]="false" nzTitle="Anyo Packages" >
                        Anyo Packages Coming Soon....
                    </nz-card>
              </div>
        </div>
        <div nz-row class="pt-4" nzJustify="end">
            <div nz-col>
              <button (click)="savePromoCode()" nz-button nzType="primary">
                Save Promo Code
              </button>
            </div>
          </div>
    </nz-spin>
</div>
<div class="flex flex-row justify-between items-center mb-3">
  <div class="flex flex-row md:gap-3 items-center">
    <div class="flex flex-row flex-nowrap md:gap-3 items-center">
      <h1 class="headeradminui-text md:break-keep">Manage Content</h1>
    </div>
    <div>
      <img class="separator" src="../../../../assets/icons/separator.svg" />
    </div>
    <div>
      <h1 class="sub-headeradminui-text">{{ totalCount }} Content</h1>
    </div>
  </div>
  <div
    class="flex flex-row justify-between btn custom-btn-primary-adminui gap-1"
    [routerLink]="['/content/add']"
  >
    <div>
      <img class="add-icon" src="../../../../assets/icons/add-icon.svg" />
    </div>
    <div>
      <span class="">Upload New Content</span>
    </div>
  </div>
</div>

<div class="custom-card">
  <div class="flex justify-around items-center mt-5 pt-5 pb-5">
    <nz-select
      class="w-[25%] h-[100%]"
      nzShowSearch
      nzAllowClear
      nzPlaceHolder="Select a Genre"
      [(ngModel)]="genreSelectedString"
      (ngModelChange)="genreFilter($event)"
    >
      <nz-option
        *ngFor="let genre of genreDropDown"
        [nzLabel]="genre.label"
        [nzValue]="genre.value"
      ></nz-option>
    </nz-select>
    <nz-select
      class="w-[25%] h-[100%]"
      nzShowSearch
      nzAllowClear
      nzPlaceHolder="Select a Category"
      [(ngModel)]="categorySelectedString"
      (ngModelChange)="categoryFilter($event)"
    >
      <nz-option
        *ngFor="let category of categoryDropDown"
        [nzLabel]="category.label"
        [nzValue]="category.value"
      ></nz-option>
    </nz-select>
    <div class="w-[30%]">
      <nz-input-group nzSearch nzSize="large" [nzAddOnAfter]="suffixButton">
        <input
          type="text"
          nz-input
          [(ngModel)]="globalSearchInput"
          placeholder="Search for content By Practice Name"
        />
      </nz-input-group>
    </div>
    <div class="w-[10%]">
      <button
        class="w-full h-[40px]"
        nz-button
        nzSize="small"
        nzType="primary"
        (click)="resetFilter()"
      >
        Clear filter
      </button>
    </div>
    <ng-template #suffixButton>
      <button
        nz-button
        nzType="primary"
        nzSize="small"
        nzSearch
        (click)="searchGlobal()"
      >
        Search
      </button>
    </ng-template>
  </div>
  <nz-spin
    [nzSpinning]="contentTableSpinner"
    nzTip="Fetching Contents...."
    [nzSize]="'large'"
  >
    <div class="flex flex-wrap justify-around items-center pr-4 pl-4 pb-5">
      <div *ngFor="let data of contentData" class="pt-5">
        <nz-card
          nzHoverable
          style="width: 400px"
          [nzCover]="coverTemplate"
          (click)="navigateUrl(data)"
          [nzActions]="data.isRecipe ? [actionDuplicate, actionEdit] : [actionEdit]"
        >
          <nz-card-meta [nzTitle]="data.practiceName"> </nz-card-meta>
         
          <nz-rate
            class="pt-4"
            [ngModel]="data.averageRating"
            nzDisabled
          ></nz-rate>
        </nz-card>
        <ng-template #coverTemplate>
          <img class="h-60 object-cover" alt="example" [src]="data.thumbnail" />
        </ng-template>
        <ng-template #actionDuplicate>
          <span 
          (click)="navigateToDuplicate(data)"
            nz-icon nzType="copy" nzTheme="outline">
          </span>
        </ng-template>
        <ng-template #actionEdit>
          <span nz-icon nzType="edit"></span>
        </ng-template>
        <!-- <ng-template #actionEllipsis>
          <span nz-icon nzType="ellipsis"></span>
        </ng-template> -->
      </div>
    </div>
  </nz-spin>
  <div class="flex justify-end">
    <nz-pagination
      class="pt-5 pb-5 pr-7"
      [nzPageIndex]="contentTableCurrentPageNo"
      [nzTotal]="contentTableTotalCount"
      nzShowSizeChanger
      [nzPageSize]="pageSize"
      [nzPageSizeOptions]="pageSizeOptions"
      (nzPageIndexChange)="nzPageIndexChange($event)"
      (nzPageSizeChange)="nzPageSizeChange($event)"
    ></nz-pagination>
  </div>
</div>

<!-- eslint-disable @angular-eslint/template/label-has-associated-control -->
<div class="flex flex-row flex-nowrap md:gap-3 items-center justify-between">
  <div
    class="flex flex-wrap w-1/4 justify-start"
    *ngIf="!viewForm && !editMode"
  >
    <img
      class="left-arrow clickable"
      src="../../../../assets/icons/left-arrow.svg"
      [routerLink]="['/partner']"
    />
    <h1 class="headeradminui-text">Add Partner</h1>
  </div>
  <div class="flex flex-wrap w-1/4 justify-start" *ngIf="viewForm || editMode">
    <img
      class="left-arrow clickable"
      src="../../../../assets/icons/left-arrow.svg"
      [routerLink]="['/partner']"
    />
    <h1 class="headeradminui-text">{{ partner.companyName }}</h1>
  </div>
  <div class="flex gap-4 mb-4">
    <div *ngIf="showDisable && !isDisabled" class="flex justify-end">
      <button
        type="button"
        class="btn btn-error rounded-md"
        (click)="dialogShow()"
      >
        Disable
      </button>
    </div>
    <div *ngIf="showDisable && isDisabled" class="flex justify-end">
      <button type="button" class="btn btn-success" (click)="dialogShow()">
        Enable
      </button>
    </div>
    <div *ngIf="isAlert && isSpocDelete">
      <app-alert-box
        [alertHeader]="alertHeaderDelete"
        [alertBody]="alertBodyDelete"
        (closeAlert)="dialogShow()"
        (isDisable)="spocAuthDelete()"
      ></app-alert-box>
    </div>
    <div *ngIf="isAlert && !isDisabled && !isSpocDelete">
      <app-alert-box
        [alertHeader]="alertHeaderDisable"
        [alertBody]="alertBodyDisable"
        (closeAlert)="dialogShow()"
        (isDisable)="disablePartner()"
      ></app-alert-box>
    </div>
    <div *ngIf="isAlert && isDisabled && !isSpocDelete">
      <app-alert-box
        [alertHeader]="alertHeaderEnable"
        [alertBody]="alertBodyEnable"
        (closeAlert)="dialogShow()"
        (isDisable)="enablePartner()"
      ></app-alert-box>
    </div>
    <div
      *ngIf="viewForm"
      class="flex flex-row justify-between btn custom-btn-primary-adminui gap-1"
      (click)="editForm()"
    >
      <div>
        <img class="edit-icon" src="../../../../assets/icons/edit-icon.svg" />
      </div>
      <div>
        <span class="">Edit Partner</span>
      </div>
    </div>
    <div
      *ngIf="editMode"
      class="flex flex-row bg-white justify-between btn btn-discard gap-1"
      [routerLink]="['/partner']"
    >
      Discard
    </div>
  </div>
</div>

<div class="custom-card">
  <div class="card-body">
    <app-progress-bar
      [steppertitle1]="steppertitle1"
      [steppertitle2]="steppertitle2"
      [status]="gotonext"
    ></app-progress-bar>
    <nz-divider *ngIf="!gotonext" nzText="White Listed Domains"></nz-divider>
    <div *ngIf="!gotonext" class="flex gap-2">
      <div class="flex gap-2" *ngFor="let domain of whitelistedDomains;index as i">
    <nz-ribbon
    nzSize="default"
    [nzText]="(i + 1).toString()"
  >
  <div
  class="passenger-input"
  >
      <input
        class="mt-2"
        nz-input
        [(ngModel)]="domain.domain"
        placeholder="Enter new domain"
        [disabled]="viewForm"
      />
      <span
        nz-icon
        nzType="minus-circle-o"
        class="dynamic-delete-button"
        (click)="removeDomain(i)"
        [ariaDisabled]="viewForm"
      ></span>
      </div>
      </nz-ribbon>
      </div>
      <button nz-button nzType="dashed" [disabled]="viewForm" class="ml-2 mt-2 add-button" (click)="addDomain()">
        <span
        nz-icon
        nzType="plus"
      ></span>
        Add Domain
      </button>
    </div>
    <nz-divider></nz-divider>
    <form #addCompanyForm="ngForm" (ngSubmit)="submit(addCompanyForm)">
      <div [hidden]="gotonext">
        <div class="flex flex-col flex-wrap md:gap-4 lg:gap-4 sm:gap-2 gap-2">
          <div
            class="flex items-center justify-between flex-wrap md:gap-4 lg:gap-4 sm:gap-2 gap-2"
          >
            <div class="flex-auto">
              <label class="form-label required">Partner Type</label>
              <select
                class="select select-md title-select"
                name="parnerType"
                [(ngModel)]="partner.partnerType"
                ngModel
                required
                [disabled]="viewForm"
              >
                <option value="" disabled selected>Select here</option>
                <option *ngFor="let option of partnerType">
                  {{ option }}
                </option>
              </select>
            </div>
            <div class="flex-auto">
              <label class="form-label required">Name of the Partner</label>
              <input
                [ngClass]="
                  (isSubmit && partner.companyName) || !isSubmit
                    ? 'input-adminui small-text'
                    : 'input-adminui small-text input-adminui-error'
                "
                name="companyname"
                placeholder="Enter Company Name"
                [(ngModel)]="partner.companyName"
                ngModel
                required
                [disabled]="viewForm"
              />
            </div>
            <div class="flex-auto">
              <label class="form-label required">Partner Branch</label>
              <input
                [ngClass]="
                  (isSubmit && partner.companyBranch) || !isSubmit
                    ? 'input-adminui small-text'
                    : 'input-adminui small-text input-adminui-error'
                "
                name="companybranch"
                placeholder="Enter Company Branch"
                [(ngModel)]="partner.companyBranch"
                ngModel
                [disabled]="viewForm"
              />
            </div>
          </div>

          <div
            class="flex items-center justify-between flex-wrap md:gap-4 lg:gap-4 sm:gap-2 gap-2"
          >
            <div class="flex-auto">
              <label class="form-label">Partner Sector</label>
              <select
                class="select select-md title-select"
                name="companysector"
                [(ngModel)]="partner.companySector"
                ngModel
                [disabled]="viewForm"
              >
                <option value="" disabled selected>Select here</option>
                <option *ngFor="let option of partnerSector">
                  {{ option }}
                </option>
              </select>
            </div>
            <div class="flex-auto">
              <label class="form-label required">Website Link</label>
              <div class="flex flex-row">
                <input
                  [ngClass]="
                    (isSubmit && partner.websiteLink) || !isSubmit
                      ? 'input-adminui small-text'
                      : 'input-adminui small-text input-adminui-error'
                  "
                  name="weblink"
                  placeholder="Enter website link"
                  [(ngModel)]="partner.websiteLink"
                  ngModel
                  required
                  [disabled]="viewForm"
                />
                <div
                  class="btn btn-block-adminui link-button"
                  (click)="redirect(partner.websiteLink)"
                >
                  <img src="../../../../assets/icons/link.svg" />
                </div>
              </div>
            </div>
          </div>

          <div
            class="flex items-center justify-between flex-wrap md:gap-4 lg:gap-4 sm:gap-2 gap-2"
          >
            <div class="flex-auto">
              <label class="form-label required">Partner Address</label>
              <input
                [ngClass]="
                  (isSubmit && partner.address) || !isSubmit
                    ? 'input-adminui small-text'
                    : 'input-adminui small-text input-adminui-error'
                "
                name="address"
                placeholder="Enter Company Address"
                [(ngModel)]="partner.address"
                ngModel
                required
                [disabled]="viewForm"
              />
            </div>
            <div class="flex-auto">
              <label class="form-label required">City</label>
              <input
                [ngClass]="
                  (isSubmit && partner.city) || !isSubmit
                    ? 'input-adminui small-text'
                    : 'input-adminui small-text input-adminui-error'
                "
                name="city"
                placeholder="Enter City"
                [(ngModel)]="partner.city"
                ngModel
                required
                [disabled]="viewForm"
              />
            </div>
            <div class="flex-auto">
              <label class="form-label required">State</label>
              <input
                [ngClass]="
                  (isSubmit && partner.state) || !isSubmit
                    ? 'input-adminui small-text'
                    : 'input-adminui small-text input-adminui-error'
                "
                name="state"
                placeholder="Enter State"
                [(ngModel)]="partner.state"
                ngModel
                required
                [disabled]="viewForm"
              />
            </div>
          </div>

          <div
            class="flex items-center justify-between flex-wrap md:gap-4 lg:gap-4 sm:gap-2 gap-2"
          >
            <div class="flex-auto">
              <label class="form-label">Year of Partner Incorporation</label>
              <input
                class="input-adminui small-text"
                name="companyyear"
                placeholder="Enter year"
                [(ngModel)]="partner.year"
                ngModel
                [disabled]="viewForm"
              />
            </div>
            <div class="flex-auto">
              <label class="form-label">Total Strength</label>
              <input
                type="number"
                class="input-adminui small-text"
                name="employeestrength"
                placeholder="Enter employee strength"
                [(ngModel)]="partner.totalStrength"
                ngModel
                [disabled]="viewForm"
              />
            </div>
          </div>

          <div
            class="flex items-center justify-between flex-wrap md:gap-4 lg:gap-4 sm:gap-2 gap-2"
          >
            <div class="flex-auto">
              <label class="form-label mb-4"
                >Nature of Ownership of the Partner</label
              >
              <div class="flex flex-row gap-3 items-center">
                <input
                  type="radio"
                  value="public"
                  class="radio radio-sm"
                  name="public"
                  ngModel
                  [(ngModel)]="partner.ownership"
                  [disabled]="viewForm"
                /><span class="form-label">Public</span>
                <input
                  type="radio"
                  value="private"
                  class="radio radio-sm"
                  name="private"
                  ngModel
                  [(ngModel)]="partner.ownership"
                  [disabled]="viewForm"
                /><span class="form-label">Private</span>
                <input
                  type="radio"
                  value="partnership"
                  class="radio radio-sm"
                  name="partnership"
                  ngModel
                  [(ngModel)]="partner.ownership"
                  [disabled]="viewForm"
                /><span class="form-label">Partnership</span>
                <input
                  type="radio"
                  value="ngo"
                  class="radio radio-sm"
                  name="ngo"
                  ngModel
                  [(ngModel)]="partner.ownership"
                  [disabled]="viewForm"
                /><span class="form-label">NGO</span>
              </div>
            </div>
            <div class="flex-auto">
              <div class="flex flex-wrap cursor-pointer gap-4 mb-2">
                <input
                  type="checkbox"
                  name="listed"
                  class="checkbox checkbox-sm"
                  [(ngModel)]="partner.isListed"
                  ngModel
                  [disabled]="viewForm"
                />
                <span class="checkbox-span">Tick if the partner is listed</span>
              </div>
              <div class="flex flex-wrap cursor-pointer gap-4">
                <input
                  type="checkbox"
                  name="profitable"
                  class="checkbox checkbox-sm"
                  [(ngModel)]="partner.isProfitable"
                  ngModel
                  [disabled]="viewForm"
                />
                <span class="checkbox-span"
                  >Tick if the partner is profitable</span
                >
              </div>
            </div>
            <div class="divider"></div>
          </div>

          <div class="flex gap-4 justify-start h-max">
            <div class="flex flex-col items-start">
              <label class="form-label">Family Benefits</label>
              <nz-switch
                [ngModelOptions]="{ standalone: true }"
                [(ngModel)]="allowFamilyInvites"
              ></nz-switch>
            </div>

            <div *ngIf="allowFamilyInvites">
              <label class="form-label"
                >No of Family Invites Allowed for Employee</label
              >
              <input
                type="number"
                nz-input
                [(ngModel)]="maxFamilyInvitesAllowed"
                [ngModelOptions]="{ standalone: true }"
                (input)="onShowMaxFamilyInvitesValidation($event)"
              />
            </div>
          </div>
          <div *ngIf="editMode" class="pt-2">
            <label class="form-label">Archived</label>
            <nz-switch
              [ngModelOptions]="{ standalone: true }"
              [(ngModel)]="archived"
            ></nz-switch>
          </div>
          <div nz-col class="pl-2" nzSpan="6">
            <span><b>Company Logo</b></span>
            <img *ngIf="partner.companyLogoUrl" [src]="partner.companyLogoUrl" alt="companyLogo" />
            <br />
            <input
               [disabled]="viewForm"
              (change)="uploadCompanyLogo($event)"
              accept=".jpg,.jpeg,.png"
              type="file"
            />
          </div>

          <div class="divider"></div>

          <label class="form-label required"
            >SPOC / Partner Login Details</label
          >
          <div *ngFor="let item of spoc; index as index" class="flex w-full">
            <div class="flex w-full justify-start items-start">
              <div
                class="flex flex-col flex-wrap md:gap-4 lg:gap-4 sm:gap-2 w-11/12 p-8 border-2 border-solid rounded-lg border-{#E8E8E8}"
              >
                <div
                  *ngIf="index == 0"
                  class="flex items-center flex-wrap md:gap-4 lg:gap-4 sm:gap-2"
                >
                  <div class="flex-auto">
                    <label class="form-label required">SPOC Name</label>
                    <input
                      [ngClass]="
                        (isSubmit && partner.spocDetails[0].name) || !isSubmit
                          ? 'input-adminui small-text'
                          : 'input-adminui small-text input-adminui-error'
                      "
                      name="spocname{{ index }}"
                      placeholder="Enter your firstname"
                      [(ngModel)]="spoc[index].name"
                      ngModel
                      [required]="index == 0"
                      [disabled]="viewForm"
                    />
                  </div>
                  <div class="flex-auto">
                    <label class="form-label required">E-mail ID</label>
                    <input
                      [ngClass]="
                        (isSubmit && partner.spocDetails[0].email) || !isSubmit
                          ? 'input-adminui small-text'
                          : 'input-adminui small-text input-adminui-error'
                      "
                      name="email{{ index }}"
                      placeholder="Enter your e-mail"
                      [(ngModel)]="spoc[index].email"
                      ngModel
                      [required]="index == 0"
                      [disabled]="viewForm || partner.spocDetails[0].u_id"
                    />
                  </div>
                  <div class="flex-auto">
                    <label class="form-label required">Mobile Number</label>
                    <input
                      [ngClass]="
                        (isSubmit && partner.spocDetails[0].mobile) || !isSubmit
                          ? 'input-adminui small-text'
                          : 'input-adminui small-text input-adminui-error'
                      "
                      name="mobile{{ index }}"
                      placeholder="Enter your mobile number"
                      [(ngModel)]="spoc[index].mobile"
                      ngModel
                      [required]="index == 0"
                      [disabled]="viewForm || partner.spocDetails[0].u_id"
                    />
                  </div>
                </div>
                <!-- </div> -->
                <div
                  *ngIf="index > 0"
                  class="flex items-center flex-wrap md:gap-4 lg:gap-4 sm:gap-2"
                >
                  <div class="flex-auto">
                    <label class="form-label required">SPOC Name</label>
                    <input
                      class="input-adminui small-text"
                      name="spocname{{ index }}"
                      placeholder="Enter your firstname"
                      [(ngModel)]="spoc[index].name"
                      ngModel
                      [required]="index == 0"
                      [disabled]="viewForm"
                    />
                  </div>
                  <div class="flex-auto">
                    <label class="form-label required">E-mail ID</label>
                    <input
                      class="input-adminui small-text"
                      name="email{{ index }}"
                      placeholder="Enter your e-mail"
                      [(ngModel)]="spoc[index].email"
                      ngModel
                      [required]="index == 0"
                      [disabled]="viewForm || spoc[index].u_id"
                    />
                  </div>
                  <div class="flex-auto">
                    <label class="form-label required">Mobile Number</label>
                    <input
                      class="input-adminui small-text"
                      name="mobile{{ index }}"
                      placeholder="Enter your mobile number"
                      [(ngModel)]="spoc[index].mobile"
                      ngModel
                      [required]="index == 0"
                      [disabled]="viewForm || spoc[index].u_id"
                    />
                  </div>
                </div>

                <!-- </div> -->
                <div class="flex flex-wrap cursor-pointer gap-4">
                  <input
                    type="checkbox"
                    name="isoperations{{ index }}"
                    class="checkbox checkbox-sm"
                    [(ngModel)]="spoc[index].isOperations"
                    ngModel
                    [disabled]="viewForm"
                  />
                  <span class="checkbox-span">Operations SPOC</span>
                </div>
              </div>
              <img
                src="../../../../assets/icons/spoc-add.svg"
                class="cursor-pointer"
                (click)="!viewForm && addSpoc()"
              />
              <!-- <img
                *ngIf="spoc.length > 1"
                class="cursor-pointer ml-1"
                src="../../../../assets/icons/delete-spoc.svg"
                (click)="!viewForm && removeSpoc(index)"
              /> -->
            </div>
          </div>
        </div>
      </div>

      <div class="flex justify-between mt-2">
        <button
          type="button"
          *ngIf="!gotonext"
          class="btn btn-primary btn-block-adminui enable-button"
          [routerLink]="['/partner']"
        >
          Cancel
        </button>
        <div class="flex justify-end w-full">
          <button
            *ngIf="!gotonext && !viewForm && !editMode"
            class="btn btn-primary btn-block-adminui enable-button"
          >
            Create and Close
          </button>
          <button
            *ngIf="!gotonext && !viewForm && editMode"
            class="btn btn-primary btn-block-adminui enable-button"
          >
            Save partner
          </button>
          <button
            type="button"
            *ngIf="!gotonext && !viewForm && !editMode"
            class="btn btn-primary btn-bigger"
            (click)="next(addCompanyForm)"
          >
            Create Partner and Proceed to Add Subscription
          </button>
          <button
            type="button"
            *ngIf="!gotonext && !viewForm && editMode"
            class="btn btn-primary btn-bigger"
            (click)="fetchSubscription(addCompanyForm)"
          >
            Save and Go to Subscription details
          </button>
          <button
            *ngIf="!gotonext && viewForm && !editMode"
            class="btn btn-primary btn-block-adminui"
            (click)="fetchSubscription()"
          >
            Next
          </button>
        </div>
      </div>
    </form>

    <form
      #addSubscriptionForm="ngForm"
      (ngSubmit)="submitAndSubscribe(addSubscriptionForm)"
    >
      <div [hidden]="!gotonext">
        <div class="flex flex-col flex-wrap md:gap-4 lg:gap-4 sm:gap-2 gap-2">
          <div
            class="flex items-center justify-between flex-wrap md:gap-4 lg:gap-4 sm:gap-2 gap-2"
          >
            <div class="flex-auto">
              <label class="form-label required">Corporate Subscriptions</label>
              <input
                type="number"
                [ngClass]="
                  (isSubmit && subscription.corporateSubscriptions) || !isSubmit
                    ? 'input-adminui small-text'
                    : 'input-adminui small-text input-adminui-error'
                "
                [(ngModel)]="subscription.corporateSubscriptions"
                required
                [disabled]="viewForm"
                (input)="onCorporateSubscription($event)"
                [ngModelOptions]="{ standalone: true }"
              />
            </div>
            <div class="flex-auto">
              <label class="form-label required"
                >Number of Therapy Sessions</label
              >
              <input
                type="number"
                min="1"
                (input)="onInputChange($event)"
                [ngClass]="
                  (isSubmit && subscription.noOfTherapySessions) || !isSubmit
                    ? 'input-adminui small-text'
                    : 'input-adminui small-text input-adminui-error'
                "
                name="sessionsnum"
                placeholder="Enter no. of therapy sessions"
                [(ngModel)]="subscription.noOfTherapySessions"
                ngModel
                required
                [disabled]="viewForm"
              />
            </div>
            <div class="flex-auto">
              <label class="form-label required">Plan Duration(months)</label>
              <select
                [ngClass]="
                  (isSubmit && subscription.planDuration) || !isSubmit
                    ? 'select select-md title-select'
                    : 'select select-md title-select input-adminui-error'
                "
                name="planduration"
                (change)="setEndDate()"
                [(ngModel)]="subscription.planDuration"
                ngModel
                required
                [disabled]="viewForm"
              >
                <option value="" selected>Select here</option>
                <option *ngFor="let option of planDuration">
                  {{ option }}
                </option>
              </select>
            </div>
          </div>
          <div
            class="flex items-center justify-between flex-wrap md:gap-4 lg:gap-4 sm:gap-2 gap-2"
          >
            <div class="flex-auto">
              <label class="form-label required">Subscription Start Date</label>
              <div
                class="flex flex-row justify-between gap-1 px-1 border rounded-md border-solid border-{#c4c4c4}"
              >
                <!-- <ngx-datepicker name="startdate" [(ngModel)]="subscription.subscriptionStart"
                                    (click)="setEndDate()" ngModel required 
                                    [disabled]="true"></ngx-datepicker>  -->
                <input
                  class="input-datepicker noborder"
                  matInput
                  name="startdate"
                  [(ngModel)]="subscription.subscriptionStart"
                  placeholder="DD / MM / YYYY"
                  (ngModelChange)="setEndDate()"
                  ngModel
                  required
                  [matDatepicker]="dp"
                />
                <mat-datepicker-toggle
                  matIconSuffix
                  [for]="dp"
                ></mat-datepicker-toggle>
                <mat-datepicker #dp></mat-datepicker>
              </div>
            </div>
            <div class="flex-auto">
              <label class="form-label required">Subscription End Date</label>
              <div
                class="flex flex-row justify-between gap-1 px-1 border rounded-md border-solid border-{#c4c4c4}"
              >
                <input
                  class="input-adminui small-text noborder"
                  name="enddate"
                  placeholder="DD / MM / YYYY"
                  [(ngModel)]="subscription.subscriptionEnd"
                  ngModel
                  disabled
                />
                <img
                  class="calendar-icon"
                  src="../../../../assets/icons/calendar.svg"
                />
              </div>
            </div>
          </div>
          <div class="flex justify-start gap-4">
            <div class="flex-auto">
              <label class="form-label required">Family Subscription</label>
              <input
                type="number"
                [ngClass]="
                  (isSubmit && subscription.familySubscriptions) || !isSubmit
                    ? 'input-adminui small-text'
                    : 'input-adminui small-text input-adminui-error'
                "
                [(ngModel)]="subscription.familySubscriptions"
                disabled
                [ngModelOptions]="{ standalone: true }"
              />
            </div>
            <div class="flex-auto">
              <label class="form-label required">Total Passes</label>
              <input
                type="number"
                [ngClass]="
                  (isSubmit && subscription.noOfSubscriptions) || !isSubmit
                    ? 'input-adminui small-text'
                    : 'input-adminui small-text input-adminui-error'
                "
                [(ngModel)]="subscription.noOfSubscriptions"
                disabled
                [ngModelOptions]="{ standalone: true }"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="flex justify-end mt-2">
        <button
          *ngIf="gotonext && !viewForm"
          type="button"
          class="btn btn-primary btn-block-adminui enable-button"
          (click)="previous()"
        >
          Previous
        </button>
        <button *ngIf="gotonext && !viewForm" class="btn btn-primary btn-block">
          Save Details
        </button>
        <button
          *ngIf="gotonext && viewForm"
          class="btn btn-primary btn-block-adminui"
          (click)="previous()"
        >
          Previous
        </button>
      </div>
    </form>
  </div>
</div>

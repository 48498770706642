import { BaseDB } from '../../../../app-core/src/app/posts/model/BaseDB';

export interface IAnyoPromocode extends BaseDB {
  description: string;
  internalNote: string;
  promocode: string;
  active: boolean;
  visible: boolean;
  validFrom?: Date;
  validTill?: Date;
  termsAndConditions?: string;
  discountDetails: Record<DiscountFor,DiscountDetails>;
  
  maxCount?: number;
  maxUsageCount?: number;
  appicableUserIds?: string[];
  applicablePartnerIds?: string[];
  tags?:string[]
}

export interface DiscountDetails {
    ids: {
        key: string;
        value?: string;
      }[]
    discountDetails: {
      type: DiscountType;
      discount: {
        minimumAmount?: number;
        currency: string;
        amount: number;
        maxAmount?: number;
      }[];
    };
  }

export enum DiscountType {
  PERCENTAGE = "PERCENTAGE",
  FLAT = "FLAT",
  // FLAT_MAX_AMOUNT = "FLAT_MAX_AMOUNT",
  PERCENTAGE_MAX_AMOUNT = "PERCENTAGE_MAX_AMOUNT",

}
export enum DiscountFor {
  THERAPY_PLAN = "THERAPY_PLAN",
  EVENTS = "EVENTS",
  CLUB_PASSES = "CLUB_PASSES",
  // ANYO_PACKAGES = "ANYO_PACKAGES",
}

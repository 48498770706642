<div class="max-w-1xl mx-auto bg-white p-4 rounded-lg shadow-lg">
    <div class="flex flex-wrap pb-4 justify-start">
      <h1 class="headeradminui-text pl-2">Manage Promo Codes</h1>
    </div>
    <div class="flex flex-row justify-end gap-3 pb-4">
      <button nz-button nzType="primary" [routerLink]="['/promo-code/create']">
        <span nz-icon nzType="plus" nzTheme="outline"></span>
        Create Promo Code
      </button>
    </div>
    <div class="flex flex-row justify-end  mb-3">
        <div class="w-[40%]">
            <nz-input-group nzSearch nzSize="large" [nzAddOnAfter]="suffixButton">
                <input
                  type="text"
                  nz-input
                  [(ngModel)]="globalSearchInput"
                  placeholder="Search for promo code"
                />
              </nz-input-group>
        </div>
    </div>

<ng-template #suffixButton>
        <button
          nz-button
          nzType="primary"
          nzSize="small"
          nzSearch
          (click)="searchGlobal()"
        >
          Search
        </button>
</ng-template>
<ng-template #tableHead>
    <div nz-row nzJustify="space-between">
         <div nz-col>
            <span class="font-bold">Promo Codes</span>
          </div>
          <div class="flex gap-2">
            <nz-switch [(ngModel)]="showTestCount" (ngModelChange)="showTestUsers()" nzCheckedChildren="hide test promo code" nzUnCheckedChildren="show test promo code"></nz-switch>
            <div nz-col>
              <button
                class="btn-color"
                nz-button
                nzSize="small"
                nzType="primary"
                (click)="resetFilter()"
              >
                Clear filter
              </button>
            </div>
        </div>
    </div>
</ng-template>

<nz-spin [nzSpinning]="loader" nzTip="Fetching Promo Code......">
        <nz-table
          #therapistTable
          [nzTitle]="tableHead"
          [nzScroll]="{ y: '500px' }"
          class="mt-5"
          [nzData]="tableData"
          [nzShowPagination]="true"
          nzFrontPagination="false"
          [nzTotal]="totalPromoCodesCount"
          [nzPageSize]="pageSize"
          [nzBordered]="true"
          [nzShowSizeChanger]="true"
          [nzPageSizeOptions]="pageSizeOptions"
          [nzPageIndex]="page"
          nzTableLayout="fixed"
          (nzPageIndexChange)="nzPageIndexChange($event)"
          (nzPageSizeChange)="nzPageSizeChange($event)"
        >
          <thead>
            <tr>
              <th>
                Promo Code
              </th>
              <th>
                Description
              </th>
              <th>Valid From</th>
              <th>
                Max Count
              </th>
              <th>Max Usage Count</th>
              <th nzWidth="25%">Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let data of therapistTable.data">
              <td [ngClass]="{ 'bg-gray-200': !data.active }">
                {{ data.promocode }}
              </td>
              <td [ngClass]="{ 'bg-gray-200': !data.active }">
                {{ data.description }}
              </td>
              <td [ngClass]="{ 'bg-gray-200': !data.active}">
                {{ utils.convertEpochToFormattedDate(data.validFrom) }}
              </td>
              <td [ngClass]="{ 'bg-gray-200': !data.active}">
                {{ data.maxCount }}
              </td>
              <td [ngClass]="{ 'bg-gray-200': !data.active }">
                {{ data.maxUsageCount }}
              </td>
              <td [ngClass]="{ 'bg-gray-200': !data.active }" nzRight>
                <div class="flex items-center justify-evenly">
                  <span nz-tooltip nzTooltipTitle="View Promo Code">
                    <button
                      [routerLink]="['/promo-code/edit/' + data.id]"
                      nz-button
                      nzType="primary"
                      nzShape="circle"
                    >
                      <span nz-icon nzType="eye" nzTheme="outline"></span>
                    </button>
                  </span>
                  </div>
              </td>
            </tr>
          </tbody>
        </nz-table>
</nz-spin>
     
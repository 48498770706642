import { Component } from '@angular/core';
import { PromoCodeGridResponseData } from '../../../models/promoCodeGridResponse';
import { PromoCodeService } from '../../../services/promo-code.service';
import { PromoCodeGridRequest } from '../../../models/promoCodeGridRequest';
import { IAnyoError } from 'projects/app-core/src/models/errorModal';
import { ToastService } from 'projects/app-core/src/app/services/toastr.service';
import { AuthService } from 'projects/app-core/src/auth/auth.service';
import { UtilsService } from 'projects/app-core/src/service/utils.service';

@Component({
  selector: 'app-manage-promo-code',
  templateUrl: './manage-promo-code.component.html',
  styleUrl: './manage-promo-code.component.scss'
})
export class ManagePromoCodeComponent {
  globalSearchInput = '';
  loader = false;
  tableData: PromoCodeGridResponseData[] = [];
  page = 1;
  pageSize = 2;
  pageSizeOptions = [10,20];
  gridRequest!: PromoCodeGridRequest;
  totalPromoCodesCount = 0;
  showTestCount = false;

showTestUsers(){
  if(!this.gridRequest){
    this.gridRequest = {
      page: this.page,
      pageSize: this.pageSize,
      globalSearch: [],
      export: false
    }
  }
  if (!this.gridRequest.filters) {
    this.gridRequest.filters = {
      TEST_PROMO_CODE: undefined,
    };
  }
  this.gridRequest.filters!.TEST_PROMO_CODE = this.showTestCount ? ['true'] : ['false']
  this.listPromoCode()
}

  constructor(
    private promo: PromoCodeService,
    private toast: ToastService,
    private auth: AuthService,
    public utils: UtilsService
  ){}

  listPromoCode(){
    this.loader = true;
    this.promo.promoCodeGridlist(this.gridRequest).subscribe({
      next:(value) => {
        this.tableData = value.data;
        this.page = value.pageNum;
        this.totalPromoCodesCount = value.totalRecords;
        this.loader = false;
      },error: (err) => {
        this.loader = false;
        const errorBody = err.error as IAnyoError;
        this.toast.showAnyoErrorToast(errorBody.description);
      }
    })
  }

  nzPageIndexChange(page: number){
    this.gridRequest.page = page;
  this.listPromoCode()

  }

  nzPageSizeChange(pageSize: number){
    this.gridRequest.pageSize = pageSize;
  this.listPromoCode()

  }

  searchGlobal(){
    this.gridRequest.globalSearch?.push(this.globalSearchInput.trim());
    this.listPromoCode();
  }
  resetFilter(){
    this.gridRequest = {
      page: 1,
      pageSize: this.pageSize,
      globalSearch: [],
      filters: {
        TEST_PROMO_CODE: ["false"]
      },
      export: false
    }
    this.showTestCount = false;
    this.globalSearchInput = '';
    this.listPromoCode();
  }

  ngOnInit(){
    this.auth.currentAuthStatus.subscribe((user) => {
      if(user){
        this.loader = true;
        if(!this.gridRequest){
          this.gridRequest = {
            page: this.page,
            pageSize: this.pageSize,
            globalSearch: [],
            filters: {
              TEST_PROMO_CODE: ["false"]
            },
            export: false
          }
        }
        this.listPromoCode();
      }
    })
  }
}

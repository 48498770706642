import { AnyoComponent } from './AnyoComponent';
import { AuthService } from '../../auth/auth.service';
import { ToastService } from '../services/toastr.service';
import { IAnyoUser } from '../../auth/models/IAnyoUser';

function BaseClassDecorator(): ClassDecorator {
  return () => {}; // No-op decorator
}

export enum CRUD {
CREATE = "CREATE",
UPDATE = "UPDATE",
APPROVE = "APPROVE"
}

@BaseClassDecorator()
export abstract class AnyoCurdComponent extends AnyoComponent {
  viewForm = true
  paramsId = ''

  constructor(
    protected override auth: AuthService,
  ) {
    super(auth);
    
  }


  abstract getData(user?: IAnyoUser): Promise<void>;

  abstract create(): Promise<void>;

  abstract update(): Promise<void>;
  abstract get(paramsId: string): Promise<void>;

  override async ready(user?: IAnyoUser): Promise<void> {
    if (user) {
      await this.getData(user);
    } 
    return Promise.resolve();
  }

  public enableEdit(){
    this.viewForm = !this.viewForm
  }
}


import { GridRequest } from "projects/app-core/src/models/gridRequest";


export enum ExpertsFilter {
    NAME = "NAME",
    ROLES = "ROLES",
    USER_INTEREST = "USER_INTEREST"
}

export enum ExpertsDateFilter{
    CREATED_AT = "CREATED_AT"
}

export enum ExpertsSort{
    CREATED_AT = "CREATED_AT"
}

export enum ExpertsSearch{
    NAME = "NAME",
    EMAIL = "EMAIL"
}

export interface ExpertsGridRequest extends GridRequest<
ExpertsSort,
ExpertsFilter,
ExpertsSearch,
ExpertsDateFilter
> {}
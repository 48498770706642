<div class="flex flex-row justify-between items-center mb-3">
  <div class="flex flex-row md:gap-3 items-center">
    <div class="flex flex-row flex-nowrap md:gap-3 items-center">
      <h1 class="headeradminui-text md:break-keep">Manage Listeners</h1>
    </div>
    <div>
      <img class="separator" src="../../../../assets/icons/separator.svg" />
    </div>
    <div>
      <h1 class="sub-headeradminui-text">{{ totalCount }} Listeners</h1>
    </div>
    <div>
      <img class="separator" src="../../../../assets/icons/separator.svg" />
    </div>
    <div>
      <h1 class="sub-headeradminui-text">{{ activeCount }} Active Listeners</h1>
    </div>
  </div>
  <!-- <div
    class="flex flex-row justify-between btn custom-btn-primary-adminui gap-1"
    [routerLink]="['/listener/add']"
  >
    <div>
      <img class="add-icon" src="../../../../assets/icons/add-icon.svg" />
    </div>
    <div>
      <span class="">Add Listener</span>
    </div>
  </div> -->
</div>

<div class="flex flex-row justify-between visible md:visible mb-3">
  <div class="flex flex-row justify-between gap-2">
    <div class="info-section flex-1 p-2">
      <div class="flex flex-row justify-between items-center">
        <div class="flex flex-col">
          <div class="large-text">{{ listenerCount.totalChats }}</div>
          <div class="xs-text">Total Chats</div>
        </div>
        <div><img src="../../../../assets/icons/message-icon.svg" /></div>
      </div>
    </div>
    <!-- <div class="info-section flex-1 p-2">
      <div class="flex flex-row justify-between items-center">
        <div class="flex flex-col">
          <div class="large-text">{{ listenerCount.totalMins }}</div>
          <div class="xs-text">Total Minutes</div>
        </div>
        <div><img src="../../../../assets/icons/clock-icon.svg" /></div>
      </div>
    </div> -->
    <div class="info-section flex-1 p-2">
      <div class="flex flex-row justify-between items-center">
        <div class="flex flex-col">
          <div class="large-text">{{ listenerCount.totalUsers }}</div>
          <div class="xs-text">Total Users</div>
        </div>
        <div><img src="../../../../assets/icons/members-icon.svg" /></div>
      </div>
    </div>
    <div class="info-section flex-1 p-2">
      <div class="flex flex-row justify-between items-center">
        <div class="flex flex-col">
          <div class="large-text">{{ listenerCount.totalListeners }}</div>
          <div class="xs-text">Total Listeners</div>
        </div>
        <div><img src="../../../../assets/icons/listener-icon.svg" /></div>
      </div>
    </div>
  </div>
</div>
<div class="flex justify-end">
  <div class="w-[40%]">
    <nz-input-group nzSearch nzSize="large" [nzAddOnAfter]="suffixButton">
      <input
        type="text"
        nz-input
        [(ngModel)]="globalSearchInput"
        placeholder="Search for listener name,email,mobile no."
      />
    </nz-input-group>
  </div>
</div>
<ng-template #suffixButton>
  <button
    nz-button
    nzType="primary"
    nzSize="small"
    nzSearch
    (click)="searchGlobal()"
  >
    Search
  </button>
</ng-template>
<ng-template #tableHead>
  <div nz-row nzJustify="space-between">
    <div nz-col>
      <span class="font-bold">Listeners</span>
    </div>
    <div nz-col>
      <button nz-button nzSize="small" nzType="primary" (click)="resetFilter()">
        Clear filter
      </button>
    </div>
  </div>
</ng-template>
<nz-spin [nzSpinning]="listenerTableSpinner" nzTip="Fetching Listeners......">
  <nz-table
    #listenerTable
    [nzTitle]="tableHead"
    [nzScroll]="{ y: '500px' }"
    class="mt-5"
    [nzData]="tableData"
    [nzShowPagination]="true"
    nzFrontPagination="false"
    [nzTotal]="listenerTableTotalCount"
    [nzPageSize]="pageSize"
    [nzBordered]="true"
    [nzShowSizeChanger]="true"
    [nzPageSizeOptions]="pageSizeOptions"
    [nzPageIndex]="listenerTableCurrentPageNo"
    nzTableLayout="fixed"
    (nzPageIndexChange)="nzPageIndexChange($event)"
    (nzPageSizeChange)="nzPageSizeChange($event)"
  >
    <thead>
      <tr>
        <th>
          Listeners
          <nz-filter-trigger
            [nzActive]="true"
            [nzDropdownMenu]="listenerNameSearch"
          >
            <span nz-icon nzType="search"></span>
          </nz-filter-trigger>
        </th>
        <th>Phone Number</th>
        <th>
          Email ID
          <nz-filter-trigger
            [nzActive]="true"
            [nzDropdownMenu]="listenerEmailSearch"
          >
            <span nz-icon nzType="search"></span>
          </nz-filter-trigger>
        </th>
        <th>
          Status
          <nz-filter-trigger [nzActive]="true" [nzDropdownMenu]="statusMenu">
            <span nz-icon nzType="search"></span>
          </nz-filter-trigger>
        </th>
        <th>Last Active On</th>
        <!-- <th>Total Chats (Min)</th>
        <th>No of Min Chats</th> -->
        <th>Rating</th>
        <th>
          Listener-Online/Offline
          <nz-filter-trigger
            [nzActive]="true"
            [nzDropdownMenu]="AvailabilityMenu"
          >
            <span nz-icon nzType="search"></span>
          </nz-filter-trigger>
        </th>
        <!-- <th>Actions</th> -->
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let data of listenerTable.data">
        <td [ngClass]="{ 'bg-gray-200': data.status == 'inactive' }">
          {{ data.name }}
        </td>
        <td [ngClass]="{ 'bg-gray-200': data.status == 'inactive' }">
          {{ data.mobileNumber }}
        </td>
        <td [ngClass]="{ 'bg-gray-200': data.status == 'inactive' }">
          {{ data.email }}
        </td>
        <td [ngClass]="{ 'bg-gray-200': data.status == 'inactive' }">
          {{ data.status }}
        </td>
        <td [ngClass]="{ 'bg-gray-200': data.status == 'inactive' }">
          {{ timeAgo(data.lastSeen) }}
        </td>

        <!-- <td [ngClass]="{ 'bg-gray-200': data.status == 'inactive' }">
          {{ data.totalChats }}
        </td>
        <td [ngClass]="{ 'bg-gray-200': data.status == 'inactive' }">
          {{ data.totalMinutes }}
        </td> -->
        <td [ngClass]="{ 'bg-gray-200': data.status == 'inactive' }">
          {{ data.rating }}
        </td>
        <td
          [ngClass]="{
            'bg-gray-200':
              data.status == 'inactive' || data.listenerOnline == false
          }"
        >
          {{
            data.listenerOnline == true
              ? "Online"
              : data.listenerOnline == false
              ? "Offline"
              : "N/A"
          }}
        </td>
        <!-- <td
          [ngClass]="{ 'bg-gray-200': data.status == 'inactive' }"
          nzRight
          align="center"
        >
          <span nz-tooltip nzTooltipTitle="View Listener">
            <button
              [routerLink]="['/listener/add/' + data.id]"
              nz-button
              nzType="primary"
              nzShape="circle"
            >
              <span nz-icon nzType="eye" nzTheme="outline"></span>
            </button>
          </span>
        </td> -->
      </tr>

      <tr></tr>
    </tbody>
  </nz-table>
</nz-spin>
<nz-dropdown-menu #listenerNameSearch="nzDropdownMenu">
  <div class="bg-white drop-shadow-md m-1 p-2 rounded-md">
    <div class="m-1" nz-row>
      <div nz-col>
        <input
          [(ngModel)]="listenerNameColumnSearch"
          nz-input
          placeholder="Search"
          nzSize="small"
        />
      </div>
    </div>
    <div class="m-1" nz-row nzJustify="end">
      <div nz-col>
        <button
          class="m-1"
          nz-button
          nzSize="small"
          nzType="primary"
          (click)="listenerNameSearchFn()"
        >
          Ok
        </button>
        <button
          class="m-1"
          nz-button
          nzSize="small"
          nzType="primary"
          (click)="resetFilter()"
        >
          Reset
        </button>
      </div>
    </div>
  </div>
</nz-dropdown-menu>
<nz-dropdown-menu #listenerEmailSearch="nzDropdownMenu">
  <div class="bg-white drop-shadow-md m-1 p-2 rounded-md">
    <div class="m-1" nz-row>
      <div nz-col>
        <input
          [(ngModel)]="listenerNameColumnSearch"
          nz-input
          placeholder="Search"
          nzSize="small"
        />
      </div>
    </div>
    <div class="m-1" nz-row nzJustify="end">
      <div nz-col>
        <button
          class="m-1"
          nz-button
          nzSize="small"
          nzType="primary"
          (click)="listenerEmailSearchFn()"
        >
          Ok
        </button>
        <button
          class="m-1"
          nz-button
          nzSize="small"
          nzType="primary"
          (click)="resetFilter()"
        >
          Reset
        </button>
      </div>
    </div>
  </div>
</nz-dropdown-menu>
<nz-dropdown-menu #statusMenu="nzDropdownMenu">
  <div class="bg-white drop-shadow-md m-1 p-2 rounded-md">
    <div nz-row>
      <div nz-col>
        <span class="font-bold">Status</span>
        <nz-divider></nz-divider>
        <nz-checkbox-wrapper (nzOnChange)="statusFilter($event)">
          <div *ngFor="let status of statusCheckBoxOptions" nz-row>
            <div nz-col>
              <label
                nz-checkbox
                [nzValue]="status.value"
                [ngModel]="status.checked"
                >{{ status.label }}</label
              >
            </div>
          </div>
        </nz-checkbox-wrapper>
      </div>
    </div>
    <div nz-row nzJustify="end">
      <div nz-col>
        <button
          class="m-1"
          nz-button
          nzType="primary"
          (click)="listListeners()"
        >
          Ok
        </button>
      </div>

      <div nz-col>
        <button class="m-1" nz-button nzType="primary" (click)="resetFilter()">
          Reset
        </button>
      </div>
    </div>
  </div>
</nz-dropdown-menu>
<nz-dropdown-menu #AvailabilityMenu="nzDropdownMenu">
  <div class="bg-white drop-shadow-md m-1 p-2 rounded-md">
    <div nz-row>
      <div nz-col>
        <span class="font-bold">Status</span>
        <nz-divider></nz-divider>
        <nz-checkbox-wrapper (nzOnChange)="availabilityFilter($event)">
          <div *ngFor="let availability of availabilityCheckBoxOptions" nz-row>
            <div nz-col>
              <label
                nz-checkbox
                [nzValue]="availability.value"
                [ngModel]="availability.checked"
                >{{ availability.label }}</label
              >
            </div>
          </div>
        </nz-checkbox-wrapper>
      </div>
    </div>
    <div nz-row nzJustify="end">
      <div nz-col>
        <button
          class="m-1"
          nz-button
          nzType="primary"
          (click)="listListeners()"
        >
          Ok
        </button>
      </div>

      <div nz-col>
        <button class="m-1" nz-button nzType="primary" (click)="resetFilter()">
          Reset
        </button>
      </div>
    </div>
  </div>
</nz-dropdown-menu>

import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  EventsListFilterData,
  EventsListGridResponse,
} from '../../../models/events/eventsAdminGridResponse';
import * as moment from 'moment';
import { UtilsService } from 'projects/app-core/src/service/utils.service';
import { EventTags } from '../../../models/events/AnyoEvents';
interface CheckboxOptions {
  label: string;
  value: string;
  checked: boolean;
}

export interface DateFilterEmitData {
  dates: Date[] | undefined;
  options: string[];
}

@Component({
  selector: 'app-events-table',
  templateUrl: './events-table.component.html',
  styleUrl: './events-table.component.scss',
})
export class EventsTableComponent {
  @Input() eventsTableData: EventsListGridResponse | undefined;
  @Output() resetFilterEvent = new EventEmitter<void>();
  @Output() pageSizeChangeEvent = new EventEmitter<number>();
  @Output() pageChangeEvent = new EventEmitter<number>();
  @Output() categoryFilterEvent = new EventEmitter<string[]>();
  @Output() paymentTypeFilterEvent = new EventEmitter<string[]>();
  @Output() eventsDateFilterEvent = new EventEmitter<DateFilterEmitData>();
  @Output() hideTestFilterEvent = new EventEmitter<boolean>();
  public eventTags = EventTags;
  dateFormat = 'dd/MM/yyyy';
  categoryCheckBoxOptions: CheckboxOptions[] = [];
  paymentTypeCheckBoxOptions: CheckboxOptions[] = [];
  dateCheckBoxOptions: CheckboxOptions[] = [
    {
      label: 'Event Date',
      value: 'eventDate',
      checked: false,
    },
    {
      label: 'Registration Cut Off Date',
      value: 'registrationCutOffDate',
      checked: false,
    },
    {
      label: 'Visible From',
      value: 'visibleFrom',
      checked: false,
    },
  ];
  pageSizeOptions = [10, 20];
  selectedCategoryString: string[] = [];
  selectedPaymentTypeString: string[] = [];
  hideTestEvent: boolean = false;

  eventsColumnFilterDate: Date[] | undefined;

  disabledDate = (current: Date) => {
    return moment(current).isBefore(moment('01/12/2023', 'dd/MM/YYYY'));
  };

  resetValues() {
    this.categoryCheckBoxOptions = [];
  }

  resetFilter() {
    this.resetFilterEvent.emit();
  }

  nzPageIndexChange(page: number) {
    this.pageChangeEvent.emit(page);
  }

  nzPageSizeChange(pageSize: number) {
    this.pageSizeChangeEvent.emit(pageSize);
  }

  categoryFilterFn() {
    this.categoryFilterEvent.emit(this.selectedCategoryString);
  }

  categoryFilterChangeFn($event: string[]) {
    this.selectedCategoryString = $event;
  }

  setCategoryFilterData(filterOptions?: EventsListFilterData) {
    this.categoryCheckBoxOptions = [];
    filterOptions?.category.map((data) => {
      this.categoryCheckBoxOptions.push({
        checked: false,
        label: data.categoryName,
        value: data.categoryId,
      });
    });
  }

  paymentTypeFilterFn() {
    this.paymentTypeFilterEvent.emit(this.selectedPaymentTypeString);
  }

  paymentTypeFilterChangeFn($event: string[]) {
    this.selectedPaymentTypeString = $event;
  }

  setPaymentTypeFilterData(filterOptions?: EventsListFilterData) {
    this.paymentTypeCheckBoxOptions = [];
    filterOptions?.paymentTypes.map((data) => {
      this.paymentTypeCheckBoxOptions.push({
        checked: false,
        label: this.utils.convertToTitleCase(data),
        value: data,
      });
    });
  }

  dateFilterFn() {
    const data: DateFilterEmitData = {
      dates: this.eventsColumnFilterDate,
      options: this.dateFilter,
    };
    this.eventsDateFilterEvent.emit(data);
  }

  dateFilter!: string[];

  dateFilterChangeFn($event: string[]) {
    this.dateFilter = $event;
  }

  onEventsFilterDateChange($event: Date[]) {
    this.eventsColumnFilterDate = $event;
  }

  testEventChangeFn($event: boolean) {
    this.hideTestEvent = $event;
    this.hideTestFilterEvent.emit($event);
  }

  constructor(public utils: UtilsService) {}
}

<div class="flex item-center w-2/4 pb-4">
  <nz-input-group nzSearch nzSize="large" [nzAddOnAfter]="suffixButton">
    <input
      type="text"
      [(ngModel)]="email"
      nz-input
      placeholder="search user by email or id or phone number"
    />
  </nz-input-group>
  <ng-template #suffixButton>
    <button
      nz-button
      nzType="primary"
      nzSize="large"
      nzSearch
      (click)="searchUser()"
    >
      Search
    </button>
  </ng-template>
</div>
<nz-spin [nzSpinning]="spinner" nzTip="Fetching User Profile......">
  <div *ngIf="spinner" class="h-screen w-full"></div>
  <div *ngIf="!spinner && searched" style="background: #ececec; padding: 30px">
    <div nz-row [nzGutter]="8">
      <div nz-col [nzSpan]="6">
        <nz-card nzTitle="User id">
          <p>{{ userProfileResp.userCardDetails.id }}</p>
        </nz-card>
      </div>
      <div nz-col [nzSpan]="6">
        <nz-card nzTitle="User Name">
          <p>{{ userProfileResp.userCardDetails.name }}</p>
        </nz-card>
      </div>
      <div nz-col [nzSpan]="6">
        <nz-card nzTitle="User Phone">
          <p>{{ userProfileResp.userCardDetails.phoneNumber }}</p>
        </nz-card>
      </div>
    </div>

    <div nz-row [nzGutter]="8">
      <div nz-col [nzSpan]="6">
        <nz-card nzTitle="User Partner Name">
          <p>{{ userProfileResp.userCardDetails.userPartner }}</p>
        </nz-card>
      </div>
      <div nz-col [nzSpan]="6">
        <nz-card nzTitle="User Joined Time">
          <p>
            {{
              utils.convertEpochToFormattedDate(
                userProfileResp.userCardDetails.joinedTime?.toString()!
              )
            }}
          </p>
        </nz-card>
      </div>
      <div nz-col [nzSpan]="6">
        <nz-card nzTitle="User Time Zone">
          <p>{{ userProfileResp.userCardDetails.userTimeZone }}</p>
        </nz-card>
      </div>
    </div>
    <div nz-row [nzGutter]="8" class="pt-2">
      <div nz-col [nzSpan]="6">
        <nz-card nzTitle="Daily Streak">
          <p>{{ userProfileResp.userCardDetails.dailyStreak }}</p>
        </nz-card>
      </div>
      <div nz-col [nzSpan]="6">
        <nz-card nzTitle="Gender">
          <p>{{ userProfileResp.userCardDetails.gender }}</p>
        </nz-card>
      </div>
      <div nz-col [nzSpan]="6">
        <nz-card nzTitle="Age">
          <p>{{ userProfileResp.userCardDetails.age }}</p>
        </nz-card>
      </div>
      <div nz-col [nzSpan]="6">
        <nz-card nzTitle="LastSeen">
          <p>
            {{
              utils.convertEpochToFormattedDate(
                userProfileResp.userCardDetails.lastSeen
              )
            }}
          </p>
        </nz-card>
      </div>
    </div>
    <div nz-row [nzGutter]="8" class="pt-2">
      <div nz-col [nzSpan]="6">
        <nz-card nzTitle="Circles Joined">
          <div
            class="mt-2"
            *ngFor="
              let data of userProfileResp.userCardDetails.circlesJoinedWithColor
            "
          >
            <nz-tag [nzColor]="data.color">{{ data.data }}</nz-tag>
          </div>
        </nz-card>
      </div>
      <div nz-col [nzSpan]="6">
        <nz-card nzTitle="User Interests">
          <div
            class="mt-2"
            *ngFor="
              let data of userProfileResp.userCardDetails.userInterestsWithColor
            "
          >
            <nz-tag [nzColor]="data.color">{{ data.data }}</nz-tag>
          </div>
        </nz-card>
      </div>
      <div nz-col [nzSpan]="6">
        <nz-card nzTitle="User Life Style Choices">
          <div
            class="mt-2"
            *ngFor="
              let data of userProfileResp.userCardDetails
                .userLifestyleChoicesWithColor
            "
          >
            <nz-tag [nzColor]="data.color">{{ data.data }}</nz-tag>
          </div>
        </nz-card>
      </div>
      <div nz-col [nzSpan]="6">
        <nz-card nzTitle="Average Time used">
          <p>{{ userProfileResp.userCardDetails.avgUseTime }}</p>
        </nz-card>
      </div>
    </div>
  </div>
</nz-spin>
<div *ngIf="!spinner && searched" style="background: #ececec; padding: 30px">
  <div nz-row [nzGutter]="8">
    <div nz-col [nzSpan]="6">
      <nz-card nzTitle="Total Sessions">
        <p>{{ therapyCards.totalSessions }}</p>
      </nz-card>
    </div>
    <div nz-col [nzSpan]="6">
      <nz-card nzTitle="Completed Sessions">
        <p>{{ therapyCards.completedSessions }}</p>
      </nz-card>
    </div>
    <div nz-col [nzSpan]="6">
      <nz-card nzTitle="Confirmed Sessions">
        <p>
          {{ therapyCards.confirmedSessions }}
        </p>
      </nz-card>
    </div>
    <div nz-col [nzSpan]="6">
      <nz-card nzTitle="Cancelled Sessions">
        <p>{{ therapyCards.cancelledSessions }}</p>
      </nz-card>
    </div>
  </div>
  <div nz-row [nzGutter]="8" class="pt-2">
    <div nz-col [nzSpan]="6">
      <nz-card nzTitle="No Show Therapist">
        <p>{{ therapyCards.noShowTherapistSessions }}</p>
      </nz-card>
    </div>
    <div nz-col [nzSpan]="6">
      <nz-card nzTitle="No Show User">
        <p>{{ therapyCards.noShowUserSessions }}</p>
      </nz-card>
    </div>
    <div nz-col [nzSpan]="6">
      <nz-card nzTitle="Feedback Pending">
        <p>
          {{ therapyCards.feedbackPendingSessions }}
        </p>
      </nz-card>
    </div>
    <div nz-col [nzSpan]="6">
      <nz-card nzTitle="Tech Issue">
        <p>{{ therapyCards.techIssueSessions }}</p>
      </nz-card>
    </div>
  </div>
  <div nz-row [nzGutter]="8" class="pt-2">
    <div nz-col [nzSpan]="6">
      <nz-card nzTitle="Total Chat Sessions">
        <p>{{ userProfileResp.userTableDetails.chatSessions.length }}</p>
      </nz-card>
    </div>
    <div nz-col [nzSpan]="6">
      <nz-card nzTitle="Total New ChatRequest">
        <p>{{ userProfileResp.userTableDetails.newChatRequest.length }}</p>
      </nz-card>
    </div>
    <div nz-col [nzSpan]="6">
      <nz-card nzTitle="Total Live Joined">
        <p>
          {{ userProfileResp.userTableDetails.liveJoins.length }}
        </p>
      </nz-card>
    </div>
    <div nz-col [nzSpan]="6">
      <nz-card nzTitle="Parables Watched">
        <p>
          {{ userProfileResp.userTableDetails.parablesWatchHistory.length }}
        </p>
      </nz-card>
    </div>
  </div>
  <div nz-row [nzGutter]="8" class="pt-2">
    <div nz-col [nzSpan]="6">
      <nz-card nzTitle="Live Watched">
        <p>{{ userProfileResp.userTableDetails.liveHistoryWatch.length }}</p>
      </nz-card>
    </div>
    <div nz-col [nzSpan]="6">
      <nz-card nzTitle="Circle Post Impressions">
        <p>
          {{ userProfileResp.userTableDetails.circlePostImpression.length }}
        </p>
      </nz-card>
    </div>
    <div nz-col [nzSpan]="6">
      <nz-card nzTitle="Circle Post Reactions">
        <p>
          {{ userProfileResp.userTableDetails.circlePostReactions.length }}
        </p>
      </nz-card>
    </div>
    <div nz-col [nzSpan]="6">
      <nz-card nzTitle="Routine Logs">
        <p>{{ userProfileResp.userTableDetails.routinesLog.length }}</p>
      </nz-card>
    </div>
  </div>
  <div nz-row [nzGutter]="8" class="pt-2">
    <div nz-col [nzSpan]="6">
      <nz-card nzTitle="Poll Logs">
        <p>{{ userProfileResp.userTableDetails.pollsLog.length }}</p>
      </nz-card>
    </div>
    <div nz-col [nzSpan]="6">
      <nz-card nzTitle="Questionnare Logs">
        <p>{{ userProfileResp.userTableDetails.QuestionnareLog.length }}</p>
      </nz-card>
    </div>
    <div nz-col [nzSpan]="6">
      <nz-card nzTitle="Total Posts">
        <p>
          {{ userProfileResp.userTableDetails.circlePosts.length }}
        </p>
      </nz-card>
    </div>
    <div nz-col [nzSpan]="6">
      <nz-card nzTitle="Total Comments">
        <p>{{ userProfileResp.userTableDetails.circlePostComments.length }}</p>
      </nz-card>
    </div>
  </div>
</div>
<div *ngIf="!spinner && searched">
  <nz-collapse nzAccordion>
    <nz-collapse-panel
      *ngFor="let panel of paginatedData; trackBy: trackByName"
      [nzHeader]="panel.name"
      [nzActive]="panel.active"
      (nzActiveChange)="activeChange($event)"
    >
      <div class="overflow-x-auto">
        <nz-table
          [nzData]="panel.data"
          [nzPageSize]="10"
          [nzShowPagination]="false"
        >
          <thead>
            <tr>
              <th *ngFor="let key of getKeys(panel.data[0])">
                {{ key }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let row of panel.data">
              <td *ngFor="let key of getKeys(row)">
                <ng-container
                  *ngIf="!isArrayOfObjects(row[key]); else nestedTable"
                >
                  {{ row[key] }}
                </ng-container>
                <ng-template #nestedTable>
                  <button
                    nz-button
                    class="bg-bunting text-white"
                    (click)="open(getKeys(row[key][0]), row[key], key)"
                  >
                    {{ "Click " + key }}
                  </button>
                </ng-template>
              </td>
            </tr>
          </tbody>
        </nz-table>
        <div class="p-4">
          <nz-pagination
            [nzPageIndex]="currentPage"
            [nzTotal]="panel.totalCount"
            (nzPageIndexChange)="changePage($event)"
          ></nz-pagination>
        </div>
      </div>
    </nz-collapse-panel>
  </nz-collapse>
</div>
<nz-drawer
  [nzClosable]="true"
  [nzVisible]="visible"
  nzPlacement="bottom"
  [nzTitle]="title.toUpperCase()"
  (nzOnClose)="close()"
>
  <ng-container *nzDrawerContent>
    <nz-table>
      <thead>
        <tr>
          <th *ngFor="let key of selectedNestedKeys">
            {{ key }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let nestedRow of selectedRowKeys">
          <td *ngFor="let nestedKey of getKeys(nestedRow)">
            {{ nestedRow[nestedKey] }}
          </td>
        </tr>
      </tbody>
    </nz-table>
  </ng-container>
</nz-drawer>

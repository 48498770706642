import { Component } from '@angular/core';
import { AnyoComponent } from 'projects/app-core/src/app/models/AnyoComponent';
import { AuthService } from 'projects/app-core/src/auth/auth.service';
import { IAnyoUser } from 'projects/app-core/src/auth/models/IAnyoUser';
import {
  GetUserFormAttributesResponse,
  UpdateUserFormAttributes,
  UpdateUserFormAttributesRequest,
  UserFormAttributes,
} from '../../../models/UserFormAttributes';
import { InputType } from '../../../models/UserFormAttributes';
import { ToastService } from 'projects/app-core/src/app/services/toastr.service';
import { UserFormAttributesService } from '../../../services/user-form-attributes.service';
import { IAnyoError } from 'projects/app-core/src/models/errorModal';

@Component({
  selector: 'app-manage-user-attributes',

  templateUrl: './manage-user-attributes.component.html',
  styleUrl: './manage-user-attributes.component.scss',
})
export class ManageUserAttributesComponent extends AnyoComponent {
  constructor(
    auth: AuthService,
    private userAttributesService: UserFormAttributesService,
    private toastrService: ToastService,
  ) {
    super(auth);
  }

  async ready(): Promise<void> {
    if (this.user) {
      await this.getAttributes();
    }
  }

  test: IAnyoUser = this.user!;
  spinner: boolean = false;
  attributeId: string = '';
  manageUserAttributesSpinner: boolean = false;
  openEditDrawer: boolean = false;
  value: string = '';
  formFields: GetUserFormAttributesResponse[] = [];
  showButton: boolean = false;
  showDropdownFields: boolean = false;
  showAllowAndSaveFields: boolean = false;
  updateValue: boolean = false;
  updateIdx: number = 0;
  inputType = Object.keys(InputType).map((key) => ({
    id: InputType[key as keyof typeof InputType],
    value: InputType[key as keyof typeof InputType]
      .replace(/-/g, ' ')
      .replace(/\b\w/g, (char) => char.toUpperCase()),
  }));
  formField: UserFormAttributes = {
    visible: true,
    name: '',
    inputType: '',
    multiple: false,
    allowAdditionalChoices: false,
    saveAdditionalChoices: false,
    mandatory: false,
    values: [],
  };
  updateFormField: UpdateUserFormAttributes = {
    visible: true,
    name: '',
    inputType: '',
    multiple: false,
    allowAdditionalChoices: false,
    saveAdditionalChoices: false,
    mandatory: false,
    values: [],
  };
  handleOpenDrawer() {
    this.showButton = false;
    this.openEditDrawer = true;
    this.showDropdownFields = false;
  }

  handleOpenEditDrawer(idx: number) {
    this.showButton = true;
    this.openEditDrawer = true;
    this.attributeId = this.formFields[idx]._id;
    this.showDropdownFields = false;
    this.showAllowAndSaveFields = false;
    this.updateFormField = {
      visible: this.formFields[idx].visible,
      name: this.formFields[idx].name,
      inputType: this.formFields[idx].inputType,
      multiple: this.formFields[idx].multiple,
      allowAdditionalChoices: this.formFields[idx].allowAdditionalChoices,
      saveAdditionalChoices: this.formFields[idx].saveAdditionalChoices,
      mandatory: this.formFields[idx].mandatory,
      values: this.formFields[idx].values
        .filter((d) => d.visible)
        .map((d) => {
          return d;
        }),
    };
    this.formField = {
      visible: this.formFields[idx].visible,
      name: this.formFields[idx].name,
      inputType: this.formFields[idx].inputType,
      multiple: this.formFields[idx].multiple,
      allowAdditionalChoices: this.formFields[idx].allowAdditionalChoices,
      saveAdditionalChoices: this.formFields[idx].saveAdditionalChoices,
      mandatory: this.formFields[idx].mandatory,
      values: this.formFields[idx].values
        .filter((d) => d.visible)
        .map((d) => {
          return d.value;
        }),
    };
    if (
      this.formFields[idx].inputType === InputType.DROPDOWN ||
      this.formFields[idx].inputType === InputType.RADIO_BUTTON
    ) {
      this.showDropdownFields = true;
    }
    if (this.formFields[idx].inputType === InputType.DROPDOWN) {
      this.showAllowAndSaveFields = true;
    }
  }
  handleCloseDrawer() {
    this.openEditDrawer = false;
    this.formField = {
      visible: true,
      name: '',
      inputType: '',
      multiple: false,
      allowAdditionalChoices: false,
      saveAdditionalChoices: false,
      mandatory: false,
      values: [],
    };
  }

  onSelectInputType(value: string) {
    this.formField.inputType = value;
    this.showDropdownFields = false;
    this.formField.values = [];
    if (value === InputType.DROPDOWN || value === InputType.RADIO_BUTTON) {
      this.showDropdownFields = true;
    }
    if (value === InputType.DROPDOWN) {
      this.showAllowAndSaveFields = true;
    }
  }

  onAddValues() {
    this.formField.values = [...this.formField.values, this.value];
    this.updateFormField.values = [
      ...this.updateFormField.values,
      { id: '', value: this.value },
    ];
    this.value = '';
  }

  onRemoveValue(index: number) {
    this.formField.values.splice(index, 1);
    this.updateFormField.values.splice(index, 1);
  }

  updateValues(index: number) {
    this.updateValue = true;
    this.updateIdx = index;
    this.value = this.formField.values[index];
  }

  cancelUpdateValues() {
    this.updateValue = false;
    this.value = '';
  }

  onUpdateValues() {
    this.formField.values[this.updateIdx] = this.value;
    this.updateFormField.values[this.updateIdx] = {
      id: this.updateFormField.values[this.updateIdx].id,
      value: this.value,
    };
    this.updateValue = false;
    this.value = '';
  }

  async getAttributes() {
    this.spinner = true;
    this.userAttributesService.GetAttributes().subscribe({
      next: (response) => {
        this.formFields = response;
      },
      error: (error) => {
        const errorBody = error.error as IAnyoError;
        this.toastrService.showAnyoErrorToast(errorBody.description);
      },
      complete: () => {
        this.spinner = false;
      },
    });
  }

  updateAttribute() {
    if (this.formField.name === '') {
      this.toastrService.showAnyoErrorToast('Please enter a attribute name');
      return;
    }
    if (
      (this.formField.inputType === InputType.DROPDOWN ||
        this.formField.inputType === InputType.RADIO_BUTTON) &&
      !this.formField.values.length
    ) {
      this.toastrService.showAnyoErrorToast('Please add at least one value');
      return;
    }
    const req: UpdateUserFormAttributesRequest = {
      id: this.attributeId.toString(),
      formAttributes: {
        ...this.updateFormField,
        visible: this.formField.visible,
        name: this.formField.name,
        inputType: this.formField.inputType,
        multiple: this.formField.multiple,
        allowAdditionalChoices: this.formField.allowAdditionalChoices,
        saveAdditionalChoices: this.formField.saveAdditionalChoices,
        mandatory: this.formField.mandatory,
      },
    };
    console.log(req);
    this.userAttributesService.UpdateAttribute(req).subscribe({
      next: () => {
        this.toastrService.showSuccess('Attribute updated successfully');
        this.handleCloseDrawer();
      },
      error: (error) => {
        console.log(error);
        const errorBody = error.error as IAnyoError;
        this.toastrService.showAnyoErrorToast(errorBody.description);
      },
      complete: () => {
        this.getAttributes();
      },
    });
  }

  onSubmit() {
    if (this.formField.name === '') {
      this.toastrService.showAnyoErrorToast('Please enter a attribute name');
      return;
    }
    if (
      (this.formField.inputType === InputType.DROPDOWN ||
        this.formField.inputType === InputType.RADIO_BUTTON) &&
      !this.formField.values.length
    ) {
      this.toastrService.showAnyoErrorToast('Please add at least one value');
      return;
    }
    this.userAttributesService.AddAttribute(this.formField).subscribe({
      next: () => {
        this.toastrService.showSuccess('Attribute added successfully');
        this.handleCloseDrawer();
      },
      error: (error) => {
        const errorBody = error.error as IAnyoError;
        this.toastrService.showAnyoErrorToast(errorBody.description);
      },
      complete: () => {
        this.getAttributes();
      },
    });
  }
}

<nz-row [nzGutter]="nzGutter">
    <nz-col *ngFor="let card of cards" [nzSpan]="nzSpan">
        <nz-card 
        [nzLoading]="loading"
        >
          <nz-statistic
            [nzValue]="card.count"
            [nzTitle]="card.title"
            [nzPrefix]="card.userLogo ? prefixTplOne : card.upLogo ? prefixTplTwo : ''"
            [nzValueStyle]="{ color: '#3F8600' }"
          ></nz-statistic>
          <ng-template #prefixTplOne><span nz-icon nzType="user" nzTheme="outline"></span></ng-template>
          <ng-template #prefixTplTwo><span nz-icon nzType="arrow-up"></span></ng-template>
        </nz-card>
      </nz-col>
  </nz-row>
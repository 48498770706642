import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { DatepickerOptions } from 'ng2-datepicker';
import { PartnerService } from '../../../services/partner.service';
import { SubscriptionService } from '../../../services/subscription.service';
import { MasterdataService } from '../../../services/masterdata.service';
import { ToastService } from 'projects/app-core/src/app/services/toastr.service';
import { Status } from '../../../stores/types';
import {
  dateToddMMYYYY,
  isoToDDMMYYYY,
} from 'projects/app-core/src/app/utils/utils';
import { IAnyoPartner, ISpocDetails } from '../../../models/IPartner';
import { ISubscription } from '../../../models/ISubscription';
import { IAnyoError } from 'projects/app-core/src/models/errorModal';
import { AuthService } from 'projects/app-core/src/auth/auth.service';
import { lastValueFrom } from 'rxjs';
import {
  AnyoS3Buckets,
  FileUtilsService,
} from 'projects/app-core/src/service/fileService';
import { v4 as uuidv4 } from 'uuid';
@Component({
  selector: 'app-add',
  templateUrl: './add.component.html',
  styleUrls: ['./add.component.scss'],
})
export class AddComponent {
  companyLogo: File | undefined;
  uploadCompanyLogo($event: any) {
    const file = $event.target.files[0];
    if (file) {
      this.companyLogo = file;
    }
  }
  gotonext: boolean = false;
  addCompanyForm!: FormGroup;
  steppertitle1: string = 'Partner Information';
  steppertitle2: string = 'Subsription';
  partnerId: string = '';
  subscriptionId: string = '';
  spocId: string = '';
  endDate!: Date;
  viewForm: boolean = false;
  isDisabled: boolean = false;
  isSpocDelete: boolean = false;
  editMode: boolean = false;
  isSubscription: boolean = false;
  isAlert: boolean = false;
  errorMessage: string = '';
  alertHeaderDisable: string = 'Partner Disable';
  alertBodyDisable: string =
    'Please make sure that you want to disable the partner';
  alertHeaderDelete: string = 'SPOC Delete';
  alertBodyDelete: string =
    'Please make sure that you want to permanently delete the SPOC';
  alertHeaderEnable: string = 'Partner Enable';
  alertBodyEnable: string =
    'Please make sure that you want to enable the partner';
  partnerSector: any[] = [];
  partnerType: any[] = [];
  noOfSubscriptions: any[] = [];
  planDuration: any[] = [];
  showDisable: boolean = false;
  isSubmit: boolean = false;
  isUnique: boolean = true;
  subEndDate!: string;
  compulsoryFields = [
    'partnerType',
    'companyName',
    'companyBranch',
    'websiteLink',
    'address',
    'city',
    'state',
    'spocDetails',
  ];
  allowFamilyInvites = false;
  archived = false;
  showMaxFamilyInvitesAllowed = false;
  maxFamilyInvitesAllowed: number = 0;
  whitelistedDomains : { domain: string}[] = [];

  addDomain(){
    this.whitelistedDomains.push({ domain: "" });
  }

  removeDomain(i: number){
    this.whitelistedDomains.splice(i,1);
  }

  constructor(
    private auth: AuthService,
    private router: Router,
    private partnerService: PartnerService,
    private subscriptionService: SubscriptionService,
    private masterdataService: MasterdataService,
    private toastService: ToastService,
    private activatedRoute: ActivatedRoute,
    private formBuilder: FormBuilder,
    private fileService: FileUtilsService,
  ) {
    this.addCompanyForm = this.formBuilder.group({
      companyname: ['', Validators.required],
      companybranch: ['', Validators.required],
      partnertype: ['', Validators.required],
      weblink: ['', Validators.required],
      address: ['', Validators.required],
      city: ['', Validators.required],
      state: ['', Validators.required],
    });
  }

  spoc: any = [
    {
      name: '',
      email: '',
      mobile: '',
      isOperations: false,
    },
  ];

  partner: IAnyoPartner = {
    companyName: '',
    companyBranch: '',
    partnerType: '',
    companySector: '',
    websiteLink: '',
    address: '',
    city: '',
    state: '',
    year: '',
    totalStrength: 0,
    ownership: '',
    isListed: false,
    isProfitable: false,
    spocDetails: this.spoc,
    archived: false,
    companyLogoUrl: '',

    // status: "active",
  };

  subscription: ISubscription = {
    partnerId: '',
    noOfSubscriptions: 0,
    noOfTherapySessions: 0,
    planDuration: 0,
    subscriptionStart: '',
    subscriptionEnd: '',
    corporateSubscriptions: 0,
    familySubscriptions: 0,
  };

  options: DatepickerOptions = {
    inputClass: 'input small-text noborder',
    calendarClass: 'datepicker-default',
    format: 'dd/MM/yyyy',
    scrollBarColor: '#010001',
    placeholder: 'DD-MM-YYYY',
  };

  onInputChange(event: Event): void {
    const inputElement = event.target as HTMLInputElement;
    const inputValue = Number(inputElement.value);
    if (inputValue < 0) {
      inputElement.value = '';
    }
  }

  showMaxFamilyInvitesAllowedFn(value: boolean) {
    this.showMaxFamilyInvitesAllowed = value;
  }

  onShowMaxFamilyInvitesValidation(event: any) {
    const target = event.target as HTMLInputElement;
    let value: number | null = parseInt(target.value.trim());

    if (isNaN(value) || value < 0 || value > 5) {
      target.value = target.value.replace(/[^1-5]/g, '');
      value = parseInt(target.value);
    }

    target.value = target.value[0];

    this.maxFamilyInvitesAllowed = value as number;
  }

  onCorporateSubscription(event: any) {
    const corporateSubscriptions = parseInt(event.target.value);

    this.subscription.corporateSubscriptions = 0;
    this.subscription.familySubscriptions = 0;
    this.subscription.noOfSubscriptions = 0;

    if (!isNaN(corporateSubscriptions)) {
      this.subscription.corporateSubscriptions = corporateSubscriptions;

      if (this.partner.allowFamilyInvites) {
        const maxFamilyInvites = this.partner.maxFamilyInvitesAllowed || 0;
        this.subscription.familySubscriptions =
          maxFamilyInvites * corporateSubscriptions;
        this.subscription.noOfSubscriptions =
          corporateSubscriptions + this.subscription.familySubscriptions;
      } else {
        this.subscription.corporateSubscriptions = corporateSubscriptions;
        this.subscription.noOfSubscriptions = corporateSubscriptions;
      }
    }
  }

  async submit(form: any, moveNext?: boolean) {
    if(this.whitelistedDomains.length){
      this.partner.whitelistedDomains = this.whitelistedDomains.map((data) => data.domain );
    }else{
      this.partner.whitelistedDomains = [];
    }
    this.partner.allowFamilyInvites = this.allowFamilyInvites;
    this.partner.maxFamilyInvitesAllowed = this.maxFamilyInvitesAllowed! || 0;
    this.partner.archived = this.archived;
    this.partner.spocDetails.forEach((spoc: { email: string }) => {
      spoc.email = spoc.email.trim();
    });
    if (this.companyLogo) {
      const uuid = uuidv4();
      const uuidWithoutHyphens = uuid.replace(/-/g, '');
      const extension = this.companyLogo.name.split('.').pop();
      this.toastService.showSuccess('Uploading Company Logo');
      const filePath = `partners/company_logo_${uuidWithoutHyphens}.${extension}`;
      const uploadUrl = await lastValueFrom(
        this.fileService.generateUploadUrl(
          AnyoS3Buckets.CDN_BUCKET,
          filePath,
          this.companyLogo.type,
        ),
      );
      await lastValueFrom(
        this.fileService.uploadFile(this.companyLogo, uploadUrl.url),
      );
      this.toastService.showSuccess('Company Logo Uploaded');
      this.partner.companyLogoUrl = filePath;
    }
    const req = this.partner;
    const flag = true;
    if (flag && this.isUnique) {
      if (this.editMode) {
        const _id = String(this.activatedRoute.snapshot.params['id']);
        this.partnerService.updatePartner(_id, req).subscribe({
          next: () => {
            if (!this.gotonext) {
              this.router.navigate(['/partner']);
            }
          },
          error: (error) => {
            this.gotonext = false;
            const errorBody = error.error as IAnyoError;
            this.toastService.showAnyoErrorToast(errorBody.description);
          },
        });
      } else {
        this.partnerService.createPartner(req).subscribe({
          next: (value: any) => {
            this.partnerId = value.partnerId;
            if (moveNext) {
              this.gotonext = true;
            }
            if (!this.gotonext) {
              this.router.navigate(['/partner']);
            }
          },
          error: (error) => {
            this.gotonext = false;
            const errorBody = error.error as IAnyoError;
            this.toastService.showAnyoErrorToast(errorBody.description);
          },
        });
      }
    } else {
      this.isSubmit = true;
      this.gotonext = false;
      if (this.errorMessage && !this.isUnique) {
        this.toastService.showError(this.errorMessage);
        this.toastService.showError('Spoc details must be unique');
      } else if (this.errorMessage) {
        this.toastService.showError(this.errorMessage);
      } else if (!this.isUnique) {
        this.toastService.showError('Spoc details must be unique');
      } else {
        this.toastService.showError('Please fill all the required fields');
      }
    }
  }

  calculateSubscriptions() {
    const corporateSubscriptions = this.subscription.corporateSubscriptions;
    this.subscription.corporateSubscriptions = 0;
    this.subscription.familySubscriptions = 0;
    this.subscription.noOfSubscriptions = 0;

    if (this.partner.allowFamilyInvites) {
      const maxFamilyInvites = this.partner.maxFamilyInvitesAllowed!;
      this.subscription.familySubscriptions =
        maxFamilyInvites * corporateSubscriptions;
      this.subscription.noOfSubscriptions =
        corporateSubscriptions + this.subscription.familySubscriptions;
      this.subscription.corporateSubscriptions = corporateSubscriptions;
    } else {
      this.subscription.corporateSubscriptions = corporateSubscriptions;
      this.subscription.noOfSubscriptions = corporateSubscriptions;
    }
  }

  submitAndSubscribe(form: any) {
    this.subscription.partnerId = this.partnerId;
    if (this.endDate) {
      this.subscription.subscriptionEnd = this.endDate;
    } else {
      this.subscription.subscriptionEnd = this.subEndDate;
    }
    const req = this.subscription;
    let flag = true;
    const subscription: Array<any> = Object.values(this.subscription);
    subscription.forEach((field) => {
      if (!field && field != 0) {
        flag = false;
      }
    });
    if (flag) {
      if (this.editMode) {
        if (this.subscriptionId) {
          this.subscriptionService
            .updateSubscription(this.subscriptionId, req)
            .subscribe({
              next: (value) => {
                this.router.navigate(['/partner']);
                this.toastService.showSuccess('Subscription Updated');
              },
              error: (error) => {
                const errorBody = error.error as IAnyoError;
                this.toastService.showAnyoErrorToast(errorBody.description);
              },
            });
        } else {
          this.subscriptionService.createSubscription(req).subscribe({
            next: (value) => {
              this.router.navigate(['/partner']);
              this.toastService.showSuccess('Subscription Created');
            },
            error: (error) => {
              const errorBody = error.error as IAnyoError;
              this.toastService.showAnyoErrorToast(errorBody.description);
            },
          });
        }
      } else {
        this.subscriptionService.createSubscription(req).subscribe({
          next: (value) => {
            this.router.navigate(['/partner']);
            this.toastService.showSuccess('Subscription Created');
          },
          error: (error) => {
            const errorBody = error.error as IAnyoError;
            this.toastService.showAnyoErrorToast(errorBody.description);
          },
        });
      }
    } else {
      this.isSubmit = true;
      this.toastService.showError('Please fill all the required fields');
    }
  }

  redirect(link: string) {
    window.open('http://' + link, '_blank');
  }

  addSpoc() {
    const spocObject = {
      name: '',
      email: '',
      mobile: '',
      isOperations: false,
    };
    this.spoc.push(spocObject);
  }

  spocAuthDelete() {
    this.partnerService.deleteSpoc(this.spocId).subscribe({
      next: () => {
        this.isSpocDelete = false;
        this.isAlert = false;
        this.partner.spocDetails.forEach((spoc: ISpocDetails, index: any) => {
          if (spoc.u_id == this.spocId) {
            this.spoc.splice(index, 1);
          }
        });
      },
      error: (error) => {
        const errorBody = error.error as IAnyoError;
        this.toastService.showAnyoErrorToast(errorBody.description);
      },
    });
  }

  removeSpoc(index: number) {
    if (this.spoc[index].u_id) {
      this.isSpocDelete = true;
      this.isAlert = true;
      this.spocId = this.spoc[index].u_id;
    } else {
      this.spoc.splice(index, 1);
    }
  }

  next(form: any) {
    var flag = true;
    if (flag) {
      this.submit(form, true);
    } else {
      this.isSubmit = true;
      if (this.errorMessage && !this.isUnique) {
        this.toastService.showError(this.errorMessage);
        this.toastService.showError('Spoc details must be unique');
      } else if (this.errorMessage) {
        this.toastService.showError(this.errorMessage);
      } else if (!this.isUnique) {
        this.toastService.showError('Spoc details must be unique');
      } else {
        this.toastService.showError('Please fill all the required fields');
      }
    }
  }

  previous() {
    this.gotonext = false;
  }

  editForm() {
    this.viewForm = false;
    this.editMode = true;
  }

  dialogShow() {
    this.isAlert = !this.isAlert;
  }

  disablePartner() {
    const req = {
      status: Status.Inactive,
    };
    this.partner.status = Status.Inactive;
    const _id = String(this.activatedRoute.snapshot.params['id']);
    this.partnerService.enableAndDisablePartner(_id, req).subscribe({
      next: () => {
        this.router.navigate(['/partner']);
      },
      error: (error) => {
        const errorBody = error.error as IAnyoError;
        this.toastService.showAnyoErrorToast(errorBody.description);
      },
    });
    this.isAlert = false;
    this.isDisabled = true;
  }

  enablePartner() {
    const req = {
      status: Status.Active,
    };
    this.partner.status = Status.Active;
    const _id = String(this.activatedRoute.snapshot.params['id']);
    this.partnerService.enableAndDisablePartner(_id, req).subscribe({
      next: () => {
        this.router.navigate(['/partner']);
      },
      error: (error) => {
        const errorBody = error.error as IAnyoError;
        this.toastService.showAnyoErrorToast(errorBody.description);
      },
    });
    this.isAlert = false;
    this.isDisabled = false;
  }

  setEndDate() {
    if (this.subscription.subscriptionStart) {
      this.subscription.planDuration = Number(this.subscription.planDuration);
      const start = new Date(this.subscription.subscriptionStart);
      const end = new Date(start);
      end.setMonth(start.getMonth() + this.subscription.planDuration);
      this.endDate = end;
      this.subscription.subscriptionEnd = dateToddMMYYYY(end);
    }
  }

  fetchSubscription(form?: any) {
    this.gotonext = true;
    if (form) {
      this.submit(form);
    }
    this.subscriptionService.getSubscription(this.partnerId).subscribe({
      next: (value: any) => {
        this.subscription = value;
        this.subscriptionId = value.id;
        this.subEndDate = value.subscriptionEnd;
        this.subscription.subscriptionStart = new Date(value.subscriptionStart);
        this.subscription.subscriptionEnd = isoToDDMMYYYY(
          value.subscriptionEnd,
        );
        this.calculateSubscriptions();
      },
      error: (error) => {
        const errorBody = error.error as IAnyoError;
        this.toastService.showAnyoErrorToast(errorBody.description);
      },
    });
  }

  populate(_id: string) {
    this.partnerService.getPartner(_id).subscribe({
      next: (value: any) => {
        this.viewForm = true;
        this.showDisable = true;
        this.partner = value;
        this.spoc = value.spocDetails;
        this.partnerId = value.partnerId;
        if (value.status == Status.Inactive) {
          this.isDisabled = true;
        }
        this.allowFamilyInvites = value.allowFamilyInvites;
        this.maxFamilyInvitesAllowed = value.maxFamilyInvitesAllowed;
        this.archived = value.archived;
        if(value.whitelistedDomains){
          this.whitelistedDomains = value.whitelistedDomains?.map((data: string) => {
            return { domain: data }
          })
        }
       
      },
      error: (error) => {
        const errorBody = error.error as IAnyoError;
        this.toastService.showAnyoErrorToast(errorBody.description);
      },
    });
  }

  async loadMasterData() {
    this.masterdataService.masterDataList().subscribe({
      next: (masterData) => {
        if (masterData.partnerSectors) {
          masterData.partnerSectors.forEach((data) => {
            if (data.status == Status.Active) {
              this.partnerSector.push(data.data);
            }
          });
        }
        if (masterData.partnerType) {
          masterData.partnerType.forEach((data) => {
            if (data.status == Status.Active) {
              this.partnerType.push(data.data);
            }
          });
        }
        if (masterData.noOfSubscriptions) {
          masterData.noOfSubscriptions.forEach((data) => {
            if (data.status == Status.Active) {
              this.noOfSubscriptions.push(data.data);
            }
          });
        }
        if (masterData.planDuration) {
          masterData.planDuration.forEach((data) => {
            if (data.status == Status.Active) {
              this.planDuration.push(data.data);
            }
          });
        }
      },
      error: (error) => {
        const errorBody = error.error as IAnyoError;
        this.toastService.showAnyoErrorToast(errorBody.description);
      },
    });
  }

  checkUnique(arr: any[]): boolean {
    const set = new Set();
    for (let i = 0; i < arr.length; i++) {
      // var obj: Array<any> = Object.values(arr[i]);
      const obj = arr[i];
      if (set.has(obj.email) || set.has(obj.mobile)) {
        return false;
      } else {
        set.add(obj.email);
        set.add(obj.mobile);
      }
    }
    return true;
  }

  async ngOnInit(): Promise<void> {
    this.auth.currentAuthStatus.subscribe(async (user) => {
      if (user) {
        await this.loadMasterData();
        if (this.activatedRoute.snapshot.params) {
          const value = this.activatedRoute.snapshot.params['id'];
          if (value) {
            this.populate(value);
          }
        }
      }
    });
  }
}

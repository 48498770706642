import { Injectable } from '@angular/core';
import {
  NetworkUtilsService,
  URL_DICT,
} from 'projects/app-core/src/service/network-utils.service';
import { ExpertsGridRequest } from '../models/expertsGridRequest';
import { ExpertGridResponse } from '../models/expertGridResponse';
import { CreateExpertTherapistListenerRequest } from '../models/createExpertTherapistListener';
import { ExpertsOnboardingGridRequest } from '../models/expertOnboardingGridRequest';
import { ExpertOnboardingGridResponse } from '../models/expertOnboardingGridResponse';
import {
  ExpertOnboardingStatus,
  ExpertOnboardRequests,
} from '../models/ExpertOnboardingRequests';

@Injectable({
  providedIn: 'root',
})
export class ExpertsService {
  constructor(private api: NetworkUtilsService) {}

  listExperts(request: ExpertsGridRequest) {
    return this.api.post<ExpertGridResponse>(
      URL_DICT.adminExpertsGrid,
      request,
    );
  }

  createExpert(req: CreateExpertTherapistListenerRequest) {
    return this.api.post(URL_DICT.adminExpertsTherapistListenerCreate, req);
  }

  editExpert(userId: string, req: CreateExpertTherapistListenerRequest) {
    return this.api.post<CreateExpertTherapistListenerRequest>(
      URL_DICT.adminExpertsGet + userId,
      req,
    );
  }

  getExpertTherapistListener(userId: string) {
    return this.api.get<CreateExpertTherapistListenerRequest>(
      URL_DICT.adminExpertsGet + userId,
    );
  }

  getExpertOnboardingRequestGrid(gridRequest: ExpertsOnboardingGridRequest) {
    return this.api.post<ExpertOnboardingGridResponse>(
      URL_DICT.adminExpertOnboardRequestGridUrl,
      gridRequest,
    );
  }

  getExpertRequestById(id: string) {
    return this.api.get<ExpertOnboardRequests>(
      URL_DICT.adminExpertOnboardRequestGetUrl + id,
    );
  }

  addComments(id: string, comment: string) {
    return this.api.post(URL_DICT.adminExpertOnboardReqAddComments + id, {
      comment,
    });
  }

  updateOnboardStatus(id: string, status: ExpertOnboardingStatus) {
    return this.api.post(URL_DICT.adminExpertOnboardReqStatusUpdate + id, {
      status,
    });
  }

  getPdfDownLoadUrl(id: string) {
    return this.api.get<{ resumeDownloadUrl: string }>(
      URL_DICT.adminExpertOnboardPdfDownLoadUr + `?onboardingId=${id}`,
    );
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PasswordRequirementsComponent } from './password-requirements/password-requirements.component';
import { InputWithIconComponent } from './input-with-icon/input-with-icon.component';
import { PartnerInputWithIconComponent } from './partner-input-with-icon/partner-input-with-icon.component';
import { LoginComponent } from './login/login.component';
import { SetupComponent } from './setup/setup.component';
import { RouterModule } from '@angular/router';

import { AlertBoxComponent } from './alert-box/alert-box.component';
import { SearchComponent } from './search/search.component';
import { ProgressBarComponent } from './progress-bar/progress-bar.component';
import { InputWithIconAdminUiComponent } from './input-with-icon-adminui/input-with-icon-adminui.component';
import { ButtonWithIconTherapistComponent } from './button-with-icon-therapist/button-with-icon-therapist.component';
import { HeaderAdminuiComponent } from './header-adminui/header-adminui.component';
import { FooterAdminuiComponent } from './footer/footer.component';
import { PageNotFoundComponentComponent } from './page-not-found-component/page-not-found-component.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatChipsModule } from '@angular/material/chips';
import { MatIconModule } from '@angular/material/icon';
import { SecurePipe } from '../pipes/secure.pipe';
import { CommonHeaderComponent } from './common-header/common-header.component';
import { CardListenerComponent } from './card-listener/card-listener.component';
import { CircledInputListenerComponent } from './circled-input-listener/circled-input-listener.component';
import { ProgressbarWithTimerListenerComponent } from './progressbar-with-timer-listener/progressbar-with-timer-listener.component';
import { MenuComponent } from './menu/menu.component';
import { LoaderComponent } from './loader/loader.component';
import { SplashScreenComponent } from './splash-screen/splash-screen.component';
import { SpinnerComponent } from './spinner/spinner.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { NzAlertModule } from 'ng-zorro-antd/alert';
import { NzAvatarModule } from 'ng-zorro-antd/avatar';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzTabsModule } from 'ng-zorro-antd/tabs';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzSwitchModule } from 'ng-zorro-antd/switch';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzRadioModule } from 'ng-zorro-antd/radio';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzAutocompleteModule } from 'ng-zorro-antd/auto-complete';
import { NzTimePickerModule } from 'ng-zorro-antd/time-picker';
import { BytesTableComponent } from './bytes-table/bytes-table.component';
import { NzTagComponent } from 'ng-zorro-antd/tag';
import { MarkUnavailableSlotComponent } from './mark-unavailable-slot/mark-unavailable-slot.component';
import { NzDrawerModule } from 'ng-zorro-antd/drawer';
import { SetAvailableSlotsComponent } from './set-available-slots/set-available-slots.component';
import { StatsCardComponent } from './stats-card/stats-card.component';
import { SetAvailabilityV2Component } from './set-availability-v2/set-availability-v2.component';
import { NzBadgeModule } from 'ng-zorro-antd/badge';
import { NzCardModule } from 'ng-zorro-antd/card';
import { OnboardingTableComponent } from './onboarding-table/onboarding-table.component';

@NgModule({
  declarations: [
    PasswordRequirementsComponent,
    InputWithIconComponent,
    PartnerInputWithIconComponent,
    LoginComponent,
    SetupComponent,
    AlertBoxComponent,
    SearchComponent,
    ProgressBarComponent,
    InputWithIconAdminUiComponent,
    ButtonWithIconTherapistComponent,
    HeaderAdminuiComponent,
    FooterAdminuiComponent,
    PageNotFoundComponentComponent,
    SecurePipe,
    CommonHeaderComponent,
    CardListenerComponent,
    CircledInputListenerComponent,
    ProgressbarWithTimerListenerComponent,
    MenuComponent,
    LoaderComponent,
    SplashScreenComponent,
    SpinnerComponent,
    ForgotPasswordComponent,
    BytesTableComponent,
    MarkUnavailableSlotComponent,
    SetAvailableSlotsComponent,
    StatsCardComponent,
    SetAvailabilityV2Component,
    OnboardingTableComponent
  ],

  imports: [
    NzDrawerModule,
    NzTimePickerModule,
    NzAutocompleteModule,
    NzAlertModule,
    NzAvatarModule,
    NzTableModule,
    NzDropDownModule,
    NzButtonModule,
    NzIconModule,
    NzTabsModule,
    NzGridModule,
    NzDatePickerModule,
    NzInputModule,
    NzSpinModule,
    NzCheckboxModule,
    NzDividerModule,
    NzSwitchModule,
    NzModalModule,
    NzRadioModule,
    NzSelectModule,
    NzToolTipModule,
    MatProgressSpinnerModule,
    CommonModule,
    MatChipsModule,
    MatIconModule,
    FormsModule,
    MatAutocompleteModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    RouterModule,
    BrowserAnimationsModule,
    InfiniteScrollModule,
    RouterModule,
    CommonModule,
    BrowserModule,
    HttpClientModule,
    FormsModule,
    BrowserAnimationsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    NzTagComponent,
    NzBadgeModule,
    NzCardModule
  ],
  exports: [
    PasswordRequirementsComponent,
    PartnerInputWithIconComponent,
    InputWithIconComponent,
    LoginComponent,
    SetupComponent,
    PageNotFoundComponentComponent,
    AlertBoxComponent,
    SearchComponent,
    ProgressBarComponent,
    InputWithIconAdminUiComponent,
    ButtonWithIconTherapistComponent,
    HeaderAdminuiComponent,
    FooterAdminuiComponent,
    SecurePipe,
    CommonHeaderComponent,
    CardListenerComponent,
    CircledInputListenerComponent,
    ProgressbarWithTimerListenerComponent,
    MenuComponent,
    SplashScreenComponent,
    LoaderComponent,
    SpinnerComponent,
    ForgotPasswordComponent,
    BytesTableComponent,
    MarkUnavailableSlotComponent,
    SetAvailableSlotsComponent,
    StatsCardComponent,
    SetAvailabilityV2Component,
    OnboardingTableComponent
  ],
})
export class CommonComponentModule {}

export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http);
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PromoRoutingModule } from './promo-routing.module';
import { ManagePromoCodeComponent } from './manage-promo-code/manage-promo-code.component';
import { CreatePromoCodeComponent } from './create-promo-code/create-promo-code.component';
import { EditPromoCodeComponent } from './edit-promo-code/edit-promo-code.component';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzInputModule } from 'ng-zorro-antd/input';
import { FormsModule } from '@angular/forms';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { NzSwitchModule } from 'ng-zorro-antd/switch';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzCardModule } from 'ng-zorro-antd/card';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzAutocompleteModule } from 'ng-zorro-antd/auto-complete';


@NgModule({
  declarations: [
    ManagePromoCodeComponent,
    CreatePromoCodeComponent,
    EditPromoCodeComponent 
  ],
  imports: [
    CommonModule,
    PromoRoutingModule,
    NzTableModule,
    NzButtonModule,
    NzInputModule,
    FormsModule,
    NzSpinModule,
    NzSwitchModule,
    NzDatePickerModule,
    NzSelectModule,
    NzCardModule,
    NzDividerModule,
    NzGridModule,
    NzDropDownModule,
    NzIconModule,
    NzModalModule,
    NzAutocompleteModule
  ]
})
export class PromoModule { }

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ManagePromoCodeComponent } from './manage-promo-code/manage-promo-code.component';
import { CreatePromoCodeComponent } from './create-promo-code/create-promo-code.component';
import { EditPromoCodeComponent } from './edit-promo-code/edit-promo-code.component';

const routes: Routes = [
  {
    path: "manage",
    component: ManagePromoCodeComponent
  },
  {
    path:"create",
    component: CreatePromoCodeComponent
  },
  {
    path:"edit/:id",
    component: EditPromoCodeComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PromoRoutingModule { }

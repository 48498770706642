import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AdminAuthModule } from './admin-auth/admin-auth.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  HTTP_INTERCEPTORS,
  HttpClient,
  HttpClientModule,
} from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ComponentModule } from './components/component.module';
import { CommonComponentModule } from 'projects/app-core/src/app/components/commoncomponent.module';
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { environment } from '../environments/environment';
import { getMessaging, provideMessaging } from '@angular/fire/messaging';
import { getPerformance, providePerformance } from '@angular/fire/performance';
import {
  getRemoteConfig,
  provideRemoteConfig,
} from '@angular/fire/remote-config';
import { TherapistModule } from './pages/therapist/therapist.module';
import { PartnerModule } from './pages/partner/partner.module';
import { ContentModule } from './pages/content/content.module';
import { ListenerModule } from './pages/listener/listener.module';
import { RoutineModule } from './pages/routine/routine.module';
import { DpDatePickerModule } from 'ng2-date-picker';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { ToastrModule } from 'ngx-toastr';
import { AngularFireModule, FIREBASE_OPTIONS } from '@angular/fire/compat';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { en_US, provideNzI18n } from 'ng-zorro-antd/i18n';
import { registerLocaleData } from '@angular/common';
import en from '@angular/common/locales/en';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzAlertModule } from 'ng-zorro-antd/alert';
import { NzAvatarModule } from 'ng-zorro-antd/avatar';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { TokenV2Interceptor } from '../../../app-core/src/interceptors/tokenV2.interceptor';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzRadioModule } from 'ng-zorro-antd/radio';
import { NzTabsModule } from 'ng-zorro-antd/tabs';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzAutocompleteModule } from 'ng-zorro-antd/auto-complete';
import { NzTimePickerModule } from 'ng-zorro-antd/time-picker';
import { NzCardModule } from 'ng-zorro-antd/card';
import { BytesModule } from './pages/bytes/bytes.module';
import { CirclesModule } from './pages/circles/circles.module';
import { PostsModule } from 'projects/app-core/src/app/posts/posts.module';
import { SessionModule } from 'projects/app-core/src/app/session/session.module';
import { RolesPermissionModule } from './pages/roles-permission/roles-permission.module';
import { EventsModule } from './pages/events/events.module';
import { TalksModule } from 'projects/app-core/src/app/talks/talks.module';
import { TherapyPlansModule } from './pages/therapyPlans/therpay-plans/therapy-plans.module';
import { NzTagModule } from 'ng-zorro-antd/tag';
import { UserProfileModule } from './pages/user-profile/user-profile.module';
import { ExpertsModule } from './pages/experts/experts.module';
import { PromoModule } from './pages/promo/promo.module';
import { SurveyModule } from './pages/survey/survey.module';

registerLocaleData(en);

@NgModule({
  declarations: [AppComponent],
  imports: [
    NzTagModule,
    NzCardModule,
    NzTimePickerModule,
    NzAutocompleteModule,
    NzSelectModule,
    NzModalModule,
    NzDatePickerModule,
    NzTabsModule,
    NzRadioModule,
    NzToolTipModule,
    NzDividerModule,
    NzGridModule,
    NzCheckboxModule,
    NzSpinModule,
    NzDropDownModule,
    NzTableModule,
    NzAvatarModule,
    NzAlertModule,
    NzButtonModule,
    NzInputModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatDatepickerModule,
    DpDatePickerModule,
    TherapistModule,
    PartnerModule,
    ContentModule,
    ListenerModule,
    EventsModule,
    RoutineModule,
    BytesModule,
    CirclesModule,
    ExpertsModule,
    PromoModule,
    SurveyModule,
    RolesPermissionModule,
    CommonComponentModule,
    BrowserModule,
    AppRoutingModule,
    ComponentModule,
    HttpClientModule,
    FormsModule,
    AdminAuthModule,
    BrowserAnimationsModule,
    PostsModule,
    SessionModule,
    TalksModule,
    UserProfileModule,
    TherapyPlansModule,
    ToastrModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    provideFirebaseApp(() => initializeApp(environment.firebase)),
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAuthModule,
    provideMessaging(() => getMessaging()),
    providePerformance(() => getPerformance()),
    provideRemoteConfig(() => getRemoteConfig()),
  ],
  providers: [
    provideNzI18n(en_US),
    { provide: FIREBASE_OPTIONS, useValue: environment.firebase },
    { provide: HTTP_INTERCEPTORS, useClass: TokenV2Interceptor, multi: true },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}

export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http);
}

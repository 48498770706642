import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {PartnerRoutingModule} from './partner-routing.module';
import {AddComponent} from './add/add.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {ManageComponent} from './manage/manage.component';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {HttpClient} from '@angular/common/http';
import {AddMemberComponent} from './add-member/add-member.component';
import {ManageMemberComponent} from './manage-member/manage-member.component';

import {RouterModule} from '@angular/router';
import {CommonComponentModule} from 'projects/app-core/src/app/components/commoncomponent.module';
import {HttpLoaderFactory} from '../../app.module';
import {DpDatePickerModule} from 'ng2-date-picker';
import {MatButtonModule} from '@angular/material/button';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatInputModule} from '@angular/material/input';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatNativeDateModule} from '@angular/material/core';
import {NzTableModule} from 'ng-zorro-antd/table';
import {NzIconModule} from 'ng-zorro-antd/icon';
import {NzInputModule} from 'ng-zorro-antd/input';
import {NzButtonModule} from 'ng-zorro-antd/button';
import {NzSpinModule} from 'ng-zorro-antd/spin';
import {NzDropDownModule} from 'ng-zorro-antd/dropdown';
import {NzCheckboxModule} from 'ng-zorro-antd/checkbox';
import {NzGridModule} from 'ng-zorro-antd/grid';
import {NzDividerModule} from 'ng-zorro-antd/divider';
import {NzToolTipModule} from 'ng-zorro-antd/tooltip';
import {NzRadioModule} from 'ng-zorro-antd/radio';
import {NzDrawerComponent, NzDrawerContentDirective,} from 'ng-zorro-antd/drawer';
import {NzCardComponent} from 'ng-zorro-antd/card';
import {NzSwitchModule} from 'ng-zorro-antd/switch';
import {NzTabsModule} from 'ng-zorro-antd/tabs';
import {MemberTableComponent} from './member-table/member-table.component';
import {NzModalComponent, NzModalContentDirective} from "ng-zorro-antd/modal";
import {NzOptionComponent, NzSelectComponent} from "ng-zorro-antd/select";
import { TherapyPlansModule } from '../therapyPlans/therpay-plans/therapy-plans.module';
import { NzTagModule } from 'ng-zorro-antd/tag';
import { NzRibbonComponent } from 'ng-zorro-antd/badge';

@NgModule({
  declarations: [
    AddComponent,
    ManageComponent,
    AddMemberComponent,
    ManageMemberComponent,
    MemberTableComponent,
  ],
  imports: [
    NzRibbonComponent,
    NzTagModule,
    TherapyPlansModule,
    NzInputModule,
    NzIconModule,
    NzTableModule,
    MatInputModule,
    MatFormFieldModule,
    MatNativeDateModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatDatepickerModule,
    RouterModule,
    CommonModule,
    CommonComponentModule,
    PartnerRoutingModule,
    FormsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    ReactiveFormsModule,
    DpDatePickerModule,
    NzButtonModule,
    NzSpinModule,
    NzDropDownModule,
    NzCheckboxModule,
    NzGridModule,
    NzDividerModule,
    NzToolTipModule,
    NzRadioModule,
    NzDrawerComponent,
    NzDrawerContentDirective,
    NzCardComponent,
    NzSwitchModule,
    NzTabsModule,
    NzModalComponent,
    NzSelectComponent,
    NzOptionComponent,
    NzModalContentDirective,
  ],
})
export class PartnerModule {}

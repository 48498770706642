import { Component } from '@angular/core';
import { AnyoTableComponent } from 'projects/app-core/src/app/models/AnyoTableComponent';
import { AuthService } from 'projects/app-core/src/auth/auth.service';
import { ToastService } from 'projects/app-core/src/app/services/toastr.service';
import { ExtraSessionForPlanSubscription, TherapyPlansService } from '../../../services/therapy-plans.service';
import * as moment from 'moment';
import { UtilsService } from 'projects/app-core/src/service/utils.service';
import { TherapyPlanSubscriptionsFilter, TherapyPlanSubscriptionsGridRequest, TherapyPlanSubscriptionsSearch } from '../../../models/therapyPlanSubscribtionGridRequest';
import { TherapyPlansSubcriptionGridResponseData } from '../../../models/therapyPlansSubcriptionGridResponse';
import { ActivatedRoute } from '@angular/router';
import { IAnyoUser } from 'projects/app-core/src/auth/models/IAnyoUser';
import { IAnyoError } from 'projects/app-core/src/models/errorModal';
import { Permissions } from 'projects/app-core/src/auth/models/roles';

interface CheckboxOptions {
  label: string;
  value: string;
  checked: boolean;
}

@Component({
  selector: 'app-therapy-plans-subcription',
  templateUrl: './therapy-plans-subcription.component.html',
  styleUrl: './therapy-plans-subcription.component.scss'
})
export class TherapyPlansSubcriptionComponent extends AnyoTableComponent<TherapyPlansSubcriptionGridResponseData,TherapyPlanSubscriptionsGridRequest> {
  planId: string = ''
  userId: string = ''
  color = ''
  selectedSubscription: TherapyPlansSubcriptionGridResponseData | undefined
  showAddExtraSessionBtn = false
  showTestUsers = true

  scrollToTop() {
    const element = document.getElementById('top-container');
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  }

  override async getData(): Promise<void> {
    if(this.planId){
      this.gridRequest.filters = {
        [TherapyPlanSubscriptionsFilter.PAYMENT_TYPE]: [],
        [TherapyPlanSubscriptionsFilter.PARTNER_ID] : [],
        [TherapyPlanSubscriptionsFilter.PLAN_NAME] : [],
        [TherapyPlanSubscriptionsFilter.USER_ID] : [],
        [TherapyPlanSubscriptionsFilter.CONCERN] : [],
        [TherapyPlanSubscriptionsFilter.VERTICAL]:[],
        [TherapyPlanSubscriptionsFilter.TEST_USERS]: []
      }
      this.gridRequest.filters.PLAN_NAME = [this.planId]
    }
    if(this.userId){
        this.gridRequest.filters = {
          [TherapyPlanSubscriptionsFilter.PAYMENT_TYPE]: [],
          [TherapyPlanSubscriptionsFilter.PARTNER_ID] : [],
          [TherapyPlanSubscriptionsFilter.PLAN_NAME] : [],
          [TherapyPlanSubscriptionsFilter.USER_ID] : [],
          [TherapyPlanSubscriptionsFilter.CONCERN] : [],
          [TherapyPlanSubscriptionsFilter.VERTICAL]:[],
          [TherapyPlanSubscriptionsFilter.TEST_USERS]: []
  
        }
      this.gridRequest.filters.USER_ID = [this.userId]
    }
    this.color = this.utils.getRandomColor()
    this.fetchTherapyPlans()
  }

  showTestUserFn(){
    if(!this.gridRequest.filters){
      this.gridRequest.filters = {
        [TherapyPlanSubscriptionsFilter.PAYMENT_TYPE]: [],
        [TherapyPlanSubscriptionsFilter.PARTNER_ID] : [],
        [TherapyPlanSubscriptionsFilter.PLAN_NAME] : [],
        [TherapyPlanSubscriptionsFilter.USER_ID] : [],
        [TherapyPlanSubscriptionsFilter.CONCERN] : [],
        [TherapyPlanSubscriptionsFilter.VERTICAL]:[],
        [TherapyPlanSubscriptionsFilter.TEST_USERS]: []
      }
     }
      this.gridRequest.filters.TEST_USERS = [ this.showTestUsers ? 'true' : 'false']
    this.fetchTherapyPlans()
  }

  convertDataAndTime (date: Date){
    return moment(date).format(" DD/MM/YYYY hh:mm:A ")
  }

  override async ready(user?: IAnyoUser): Promise<void> {
    if (user) {
      if(user.customClaims?.additionalPermissions.includes(Permissions.AddExtraSessionToPlanSubscription)){
        this.showAddExtraSessionBtn = true
      }
      this.activatedRoute.queryParams.subscribe((value) => {
        if (value) {
          this.planId = value['planName'];
          this.userId = value['userId'];
        }
      });
      this.getData()
    } else {
      this.data = [];
    }
    return
  }

  constructor(
    protected override auth: AuthService,
    protected toast: ToastService,
    private therapyPlansService: TherapyPlansService,
    private activatedRoute: ActivatedRoute,
    public utils: UtilsService
  ) {
    super(auth, toast);
  }

  // grid
  therapyPlans: TherapyPlansSubcriptionGridResponseData[] = []
  totalRecords: number = 0
  paymentTypeCheckBoxOptions: CheckboxOptions[] = []
  page =  1
  validTillColumnFilterDate: Date[] = []
  purchasedAtColumnFilterDate: Date[] = []

  planNameColumnSearch = ''
  userNameColumnSearch = ''
  expandSet = new Set<string>();

  onExpandChange(id: string, checked: boolean,data: TherapyPlansSubcriptionGridResponseData) {
    if (checked) {
      this.expandSet.add(id);
      this.selectedSubscription = data
    } else {
      this.expandSet.delete(id);
      this.selectedSubscription = undefined
    }
  }


  validTillFilterDateChange($event: any[]){
    if ($event && $event.length == 2) {
      const startDate = moment($event[0]).startOf('day').toDate();
      const endDate = moment($event[1]).endOf('day').toDate();
      const isToday = moment(startDate).isSame(endDate, 'day');
      this.gridRequest = {
        dateFilters: {
          VALID_TILL: {
            from: isToday
              ? moment($event[0]).startOf('day').toDate()
              : startDate,
            to: isToday ? moment($event[1]).endOf('day').toDate() : endDate,
          },
        },
        export: false,
        filters: undefined,
        from: undefined,
        globalSearch: [],
        page: 1,
        pageSize: this.pageSize,
        searchQuery: undefined,
        to: undefined,
      };

    }
  }

  purchasedAtFilterDateChange($event: any[]){
    if ($event && $event.length == 2) {
      const startDate = moment($event[0]).startOf('day').toDate();
      const endDate = moment($event[1]).endOf('day').toDate();
      const isToday = moment(startDate).isSame(endDate, 'day');
      this.gridRequest = {
        dateFilters: {
          CREATED_AT: {
            from: isToday
              ? moment($event[0]).startOf('day').toDate()
              : startDate,
            to: isToday ? moment($event[1]).endOf('day').toDate() : endDate,
          },
        },
        export: false,
        filters: undefined,
        from: undefined,
        globalSearch: [],
        page: 1,
        pageSize: this.pageSize,
        searchQuery: undefined,
        to: undefined,
      };

    }
  }


  fetchTherapyPlans(){
    this.pageLoading = true
    this.therapyPlansService.fetchTherapyPlansSubscription(this.gridRequest).subscribe({
      next: (value) => {
        value.data.forEach((data) => {
          data.paymentTypeColor = this.utils.getRandomColor();
          const sessionList = Object.keys(data.sessionDetails).map(key => {
            const session = data.sessionDetails[key];
            return { id: key, ...session };
        });
         data.sessionDetailslist = sessionList
        });
        this.therapyPlans = value.data
        this.paymentTypeCheckBoxOptions = []
        this.paymentTypeCheckBoxOptions = value.filterOptions.paymentTypes.map((data) => {
          if(this.gridRequest?.filters?.PAYMENT_TYPE?.includes(data)){
            return{
              label: this.utils.convertToTitleCase(data),
              value: data,
              checked: true
            }
          }else{
            return{
              label: this.utils.convertToTitleCase(data),
              value: data,
              checked: false
            }
          }
        })
        this.totalRecords = value.totalRecords
        this.pageLoading = false
      }, error: (err) => {
        this.toastService.showError(err.message);
        this.pageLoading = false;
      }
    })

  }

  searchByPlanName(){
    if(this.planNameColumnSearch){
      this.gridRequest.searchQuery = {
        [TherapyPlanSubscriptionsSearch.USER_NAME] : '',
        [TherapyPlanSubscriptionsSearch.PLAN_NAME]: ''
      }
      this.gridRequest.searchQuery.PLAN_NAME = this.planNameColumnSearch.trim()
      this.fetchTherapyPlans()
    }
  }

  // searchByUserName(){
  //   if(this.userNameColumnSearch){
  //     this.gridRequest.searchQuery = {
  //       [TherapyPlanSubscriptionsSearch.USER_NAME] : '',
  //       [TherapyPlanSubscriptionsSearch.PLAN_NAME]: ''
  //     }
  //     this.gridRequest.searchQuery.USER_NAME = this.userNameColumnSearch.trim()
  //     this.fetchTherapyPlans()
  //   }
  // }

  paymentTypeFilterChangeFn($event: any[]){
    if(!this.gridRequest.filters){
    this.gridRequest.filters = {
      [TherapyPlanSubscriptionsFilter.PAYMENT_TYPE]: [],
      [TherapyPlanSubscriptionsFilter.PARTNER_ID] : [],
      [TherapyPlanSubscriptionsFilter.PLAN_NAME] : [],
      [TherapyPlanSubscriptionsFilter.USER_ID] : [],
      [TherapyPlanSubscriptionsFilter.CONCERN] : [],
      [TherapyPlanSubscriptionsFilter.VERTICAL]:[],
      [TherapyPlanSubscriptionsFilter.TEST_USERS]: []
    }
  }
    if($event && $event.length){
      this.gridRequest.filters.PAYMENT_TYPE = $event
    }
  } 

  override async resetFilter() {
    this.gridRequest = {
      export: false,
      globalSearch: [],
      page: 1,
      pageSize: this.pageSize,
    };
    this.gridRequest.filters = {
      [TherapyPlanSubscriptionsFilter.PAYMENT_TYPE]: [],
      [TherapyPlanSubscriptionsFilter.PARTNER_ID] : [],
      [TherapyPlanSubscriptionsFilter.PLAN_NAME] : [],
      [TherapyPlanSubscriptionsFilter.USER_ID] : [this.userId],
      [TherapyPlanSubscriptionsFilter.CONCERN] : [],
      [TherapyPlanSubscriptionsFilter.VERTICAL]:[],
      [TherapyPlanSubscriptionsFilter.TEST_USERS]:[],
    }
    this.paymentTypeCheckBoxOptions = []
    this.validTillColumnFilterDate = []
    this.purchasedAtColumnFilterDate = []
    this.planNameColumnSearch = ''
    this.userNameColumnSearch = ''
    this.fetchTherapyPlans();
  }

  isVisible = false;
  extraSessionName = ''
  extraSessionDescription = ''


  showModal(): void {
    this.extraSessionName = `Session ${this.selectedSubscription!.noOfSessions + 1}`
    this.extraSessionDescription = ''
    this.isVisible = true;
  }

  handleOk(): void {
    this.pageLoading = true;
    const sessionDetails = this.selectedSubscription?.sessionDetails!
    sessionDetails[this.selectedSubscription!.noOfSessions + 1] = {
      therapistId: this.selectedSubscription?.sessionDetailslist[0].therapistId!,
      therapistEmail: this.selectedSubscription?.sessionDetailslist[0].therapistEmail,
      therapistName: this.selectedSubscription?.sessionDetailslist[0].therapistName,
      sessionName: this.extraSessionName,
      description: this.extraSessionDescription,
      sessionBooked: false,
      sessionCompleted: false,
    }
    const req: ExtraSessionForPlanSubscription = {
      sessionDetails: sessionDetails
    }
    this.therapyPlansService.addExtraSessions(this.selectedSubscription!.id!,req).subscribe({
      next: (value) => {
        this.toastService.showSuccess("extra sessions added")
        this.pageLoading = false;
        this.isVisible = false;
        this.fetchTherapyPlans()
      }, error: (err) => {
        this.pageLoading = false;
        const errorBody = err.error as IAnyoError;
        this.toastService.showAnyoErrorToast(errorBody.description);
        this.isVisible = false;
      }
    })
  }

  handleCancel(): void {
    this.isVisible = false;
  }

}

export interface UserFormAttributes {
  visible: boolean;
  name: string;
  inputType: InputType | string;
  multiple: boolean;
  allowAdditionalChoices: boolean;
  saveAdditionalChoices: boolean;
  mandatory: boolean;
  values: string[];
}

export interface UpdateUserFormAttributes {
  visible: boolean;
  name: string;
  inputType: InputType | string;
  multiple: boolean;
  allowAdditionalChoices: boolean;
  saveAdditionalChoices: boolean;
  mandatory: boolean;
  values: { id: string; value: string; visible?: boolean }[];
}
export interface GetUserFormAttributesResponse
  extends UpdateUserFormAttributes {
  _id: string;
}

export interface UpdateUserFormAttributesRequest {
  id: string;
  formAttributes: UpdateUserFormAttributes;
}

export enum InputType {
  NUMBER = 'number',
  TEXT = 'text',
  TEXT_AREA = 'text-area',
  RADIO_BUTTON = 'radio-button',
  DROPDOWN = 'dropdown',
  SWITCH = 'switch',
  DATE = 'Date',
  FILE_PICKER = 'filePicker',
}

import { Component } from '@angular/core';
import { Currencies } from '../../../models/Currencies';
import { DiscountDetails, DiscountFor, DiscountType, IAnyoPromocode } from '../../../models/IAnyoPromoCode';
import { UtilsService } from 'projects/app-core/src/service/utils.service';
import { MasterdataService } from '../../../services/masterdata.service';
import { AuthService } from 'projects/app-core/src/auth/auth.service';
import { TherapyPlansService } from '../../../services/therapy-plans.service';
import { EventsService } from '../../../services/events.service';
import { PromoCodeRequestCreate, PromoCodeService } from '../../../services/promo-code.service';
import { ToastService } from 'projects/app-core/src/app/services/toastr.service';
import * as moment from 'moment';
import { IAnyoError } from 'projects/app-core/src/models/errorModal';
import { Router } from '@angular/router';
import { MasterDataResponse } from '../../../models/masterDataResponse';
import { TherapyPlansAdminResponse } from '../../../models/TherapyPlansAdminResponse';
import { AnyoClubPass } from '../../../models/events/AnyoClubPasses';

interface DropdownOptions {
  value: string;
  data: string;
}

interface DiscountDetailsArray {
  isActive: boolean,
  key: DiscountFor,
  masterData: DropdownOptions[],
  ids: string[]
  discountDetails: {
    type: DiscountType;
    discount: {
      minimumAmount?: number;
      currency: string;
      amount: number;
      maxAmount?: number;
    }[];
  };
}

@Component({
  selector: 'app-create-promo-code',
  templateUrl: './create-promo-code.component.html',
  styleUrl: './create-promo-code.component.scss'
})
export class CreatePromoCodeComponent {
  pageLoading = false;
  promoCode = '';
  description = '';
  internalNote = '';
  termsAndConditions = '';
  isActive = false;
  isVisible = false;
  validFromDate!: Date;
  validTillDate!: Date;
  isTesting = false;
  maxCount = 0;
  maxUsageCount = 0;

  onChangeValidFrom(result: Date) {
    if (moment(result).isBefore(moment())) {
      this.toast.showError("valid From Date should be in future !!")
    }
  }
  onChange(result: Date): void {
    if (moment(this.validFromDate).isAfter(moment(result))) {
      this.toast.showError("valid Till Date should be in future from valid From Date !!")
    }
  }

  masterData!: MasterDataResponse;
  activeTherapyPlans!: TherapyPlansAdminResponse[];
  anyoClubPasses!: AnyoClubPass[];
  partners: DropdownOptions[] = [];
  users: DropdownOptions[] = [];
  listOfSelectedValue = [];
  selectedTherapyPlans = [];
  currency: Currencies = Currencies.INR;
  discountType: DiscountType = DiscountType.PERCENTAGE;
  discountTypeOptions: { key: string, value: string }[] = [];
  promoCodeModel!: IAnyoPromocode;
  discountDetails: DiscountDetailsArray[] = [
    {
      masterData: [],
      key: DiscountFor.THERAPY_PLAN,
      isActive: false,
      ids: [],
      discountDetails: {
        type: DiscountType.PERCENTAGE,
        discount: [
          {
            minimumAmount: 0,
            currency: this.currency,
            amount: 0,
            maxAmount: 0,
          }
        ]
      }
    },
    {
      masterData: [],
      key: DiscountFor.EVENTS,
      isActive: false,
      ids: [],
      discountDetails: {
        type: DiscountType.PERCENTAGE,
        discount: [
          {
            minimumAmount: 0,
            currency: this.currency,
            amount: 0,
            maxAmount: 0,
          }
        ]
      }
    },
    {
      masterData: [],
      key: DiscountFor.CLUB_PASSES,
      isActive: false,
      ids: [],
      discountDetails: {
        type: DiscountType.PERCENTAGE,
        discount: [
          {
            minimumAmount: 0,
            currency: this.currency,
            amount: 0,
            maxAmount: 0,
          }
        ]
      }
    }
  ];

  constructor(
    public utilsService: UtilsService,
    private masterDataService: MasterdataService,
    private auth: AuthService,
    private therapyPlans: TherapyPlansService,
    private events: EventsService,
    private promo: PromoCodeService,
    private toast: ToastService,
    private router: Router
  ) {

  }

  savePromoCode() {
    this.pageLoading = true;
    const discountDetails: Record<DiscountFor, DiscountDetails> = {} as Record<DiscountFor, DiscountDetails>;
    for (const data of this.discountDetails) {
      if (data.isActive) {
        discountDetails[data.key] = {
          ids: data.ids.map((id) => {
            let value = '';
            switch (data.key) {
              case DiscountFor.THERAPY_PLAN:
                value = this.activeTherapyPlans.find((data) => data.id == id)?.internalPlanName || '';
                return {
                  key: id,
                  value: value
                }
              case DiscountFor.EVENTS:
                value = this.masterData.events.find((data) => data.id === id)?.name || '';
                return {
                  key: id,
                  value: value
                }
              case DiscountFor.CLUB_PASSES:
                value = this.anyoClubPasses.find((data) => data.passId === id)?.passName || '';
                return {
                  key: id,
                  value: value
                }
              default:
                return {
                  key: id,
                  value: value
                }
            }
          }),
          discountDetails: data.discountDetails
        }
      }

    }
    const req: PromoCodeRequestCreate = {
      partnerIds: this.listOfSelectedValue,
      isTest: this.isTesting,
      description: this.description,
      internalNote: this.internalNote,
      promocode: this.promoCode,
      active: this.isActive,
      visible: this.isVisible,
      termsAndConditions: this.termsAndConditions,
      discountDetails: discountDetails,
      maxCount: this.maxCount,
      maxUsageCount: this.maxUsageCount
    }
    if (this.validFromDate != null) {
      req.validFrom = moment(this.validFromDate).format('x');
    }
    if (this.validTillDate != null) {
      req.validTill = moment(this.validTillDate).format('x');
    }
    this.promo.createPromoCode(req).subscribe({
      next: (value) => {
        this.toast.showSuccess("promo code successfully created!!");
        this.router.navigate(['/promo-code/manage']);
        this.pageLoading = false;
      }, error: (err) => {
        this.pageLoading = false;
        const errorBody = err.error as IAnyoError;
        this.toast.showAnyoErrorToast(errorBody.description);
      }
    })
  }

  ngOnInit() {
    this.discountTypeOptions = Object.entries(DiscountType).map(([key, value]) => {
      return {
        key: this.utilsService.convertToTitleCase(key),
        value: value
      }
    });
    this.auth.currentAuthStatus.subscribe((user) => {
      if (user) {
        this.masterDataService.masterDataList().subscribe({
          next: (value) => {
            this.masterData = value;
            this.discountDetails[1].masterData = value.events.map((data) => {
              return {
                data: data.name!,
                value: data._id!
              }
            })
            this.partners = value.partners.map((data) => {
              return {
                data: data.companyName!,
                value: data._id!
              }
            })
          }, error: (err) => {
            const errorBody = err.error as IAnyoError;
            this.toast.showAnyoErrorToast(errorBody.description);
          }
        })
        this.therapyPlans.fetchAllPlans().subscribe({
          next: (value) => {
            this.activeTherapyPlans = value.activePlans;
            this.discountDetails[0].masterData = value.activePlans.map((data) => {
              return {
                data: data.internalPlanName,
                value: data.id
              }
            })
          }, error: (err) => {
            const errorBody = err.error as IAnyoError;
            this.toast.showAnyoErrorToast(errorBody.description);
          }
        })


        this.events.getAllClubPasses().subscribe({
          next: (value) => {
            this.anyoClubPasses = value;
            this.discountDetails[2].masterData = value.map((data) => {
              return {
                data: data.passName,
                value: data.passId
              }
            })
          }, error: (err) => {
            const errorBody = err.error as IAnyoError;
            this.toast.showAnyoErrorToast(errorBody.description);
          }
        })

      }
    })
  }
}

import { Component } from '@angular/core';
import { CirclesService } from '../../../services/circles.service';
import { MasterdataService } from '../../../services/masterdata.service';
import { ToastService } from 'projects/app-core/src/app/services/toastr.service';
import { AuthService } from 'projects/app-core/src/auth/auth.service';
import { ExpertsService } from '../../../services/experts.service';
import { FileUtilsService } from 'projects/app-core/src/service/fileService';
import { CrudExpertsComponent } from '../crud-experts/crud-experts.component';
import { ActivatedRoute, Router } from '@angular/router';
import { CRUD } from 'projects/app-core/src/app/models/AnyoCrudComponent';
import { UtilsService } from 'projects/app-core/src/service/utils.service';
import { DomSanitizer } from '@angular/platform-browser';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-add-experts',
  templateUrl: './add-experts.component.html',
  styleUrl: './add-experts.component.scss'
})
export class AddExpertsComponent extends CrudExpertsComponent {
  constructor(
    auth: AuthService,
    circleService: CirclesService,
    masterdataService: MasterdataService,
    toastrService: ToastService,
    experts: ExpertsService,
    fileService: FileUtilsService,
    activatedRoute: ActivatedRoute,
    router: Router,
    utils: UtilsService,
  ) {
    super(auth, circleService, masterdataService, toastrService, experts, fileService, activatedRoute, router,utils);
    this.crudType = CRUD.CREATE;
  }
 
}

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ManageSurveyComponent } from './manage-survey/manage-survey.component';
import { ManageSurveyResponsesComponent } from './manage-survey-responses/manage-survey-responses.component';


const routes: Routes = [
  {
    path: '',
    component: ManageSurveyComponent,
  },
  {
    path: 'response',
    component: ManageSurveyResponsesComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class SurveyRoutingModule {}

import { Component, Input } from '@angular/core';

export interface StatsCard {
  title: string;
  count: number | string;
  userLogo: boolean;
  upLogo: boolean;
}

@Component({
  selector: 'app-stats-cards',
  templateUrl: './stats-cards.component.html',
  styleUrl: './stats-cards.component.scss'
})
export class StatsCardsComponent {
  @Input() cards: StatsCard[] = []
  @Input() loading = false
  @Input() nzSpan = 4
  @Input() nzGutter = 16
}

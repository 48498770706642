import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzSwitchModule } from 'ng-zorro-antd/switch';
import { NzRateModule } from 'ng-zorro-antd/rate';
import { NzUploadModule } from 'ng-zorro-antd/upload';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzTagModule } from 'ng-zorro-antd/tag';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzStepsModule } from 'ng-zorro-antd/steps';
import { NzDescriptionsModule } from 'ng-zorro-antd/descriptions';
import { NzCardModule } from 'ng-zorro-antd/card';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { NzBadgeModule } from 'ng-zorro-antd/badge';

import { NzTabsModule } from 'ng-zorro-antd/tabs';
import { NzListModule } from 'ng-zorro-antd/list';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { CommonComponentModule } from 'projects/app-core/src/app/components/commoncomponent.module';
import { BrowserModule } from '@angular/platform-browser';
import { NzDrawerModule } from 'ng-zorro-antd/drawer';
import { NzRadioModule } from 'ng-zorro-antd/radio';

import { ManageSurveyComponent } from './manage-survey/manage-survey.component';
import { SurveyRoutingModule } from './survey-routing.module';
import { ManageSurveyResponsesComponent } from './manage-survey-responses/manage-survey-responses.component';

@NgModule({
  declarations: [ManageSurveyComponent,ManageSurveyResponsesComponent],
  imports: [
    BrowserModule,
    CommonComponentModule,
    SurveyRoutingModule,
    NzModalModule,
    NzListModule,
    NzTabsModule,
    CommonModule,
    NzDividerModule,
    NzTableModule,
    NzGridModule,
    NzSpinModule,
    NzButtonModule,
    NzToolTipModule,
    NzIconModule,
    NzSelectModule,
    FormsModule,
    NzInputModule,
    NzSwitchModule,
    NzRateModule,
    NzUploadModule,
    ReactiveFormsModule,
    NzDropDownModule,
    NzTagModule,
    NzCheckboxModule,
    NzStepsModule,
    NzDescriptionsModule,
    NzCardModule,
    NzDatePickerModule,
    NzBadgeModule,
    NzDrawerModule,
    NzRadioModule,
  ],
})
export class SurveyModule {}

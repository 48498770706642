import { Component } from '@angular/core';
import { AnyoComponent } from 'projects/app-core/src/app/models/AnyoComponent';
import { ToastService } from 'projects/app-core/src/app/services/toastr.service';
import { AuthService } from 'projects/app-core/src/auth/auth.service';
import {
  AdminMakePayoutToExpertsRequest,
  RewardService,
} from '../../../services/rewards.service';
import {
  AnyoRewardTypes,
  ExpertRewardsGridFilterOption,
  PaymentStatus,
  RewardsGridResponse,
  TransactionDirection,
} from '../../../models/expertRewardGridResponse';
import { ExpertRewardsGridRequest } from '../../../models/expertRewardGridRequest';
import { UtilsService } from 'projects/app-core/src/service/utils.service';
import { NzTabChangeEvent } from 'ng-zorro-antd/tabs';
import * as moment from 'moment';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { IAnyoError } from 'projects/app-core/src/models/errorModal';

@Component({
  selector: 'app-manage-experts-rewards',
  templateUrl: './manage-experts-rewards.component.html',
  styleUrl: './manage-experts-rewards.component.scss',
})
export class ManageExpertsRewardsComponent extends AnyoComponent {
  constructor(
    auth: AuthService,
    private toastService: ToastService,
    private rewardService: RewardService,
    public utils: UtilsService,
    private fb: FormBuilder,
  ) {
    super(auth);
    this.paymentForm = this.fb.group({
      amount: [
        { value: null, disabled: true },
        [Validators.required, Validators.min(1)],
      ],
      transactionId: [null, Validators.required],
      modeOfPayment: [null, Validators.required],
    });
  }

  readonly pageSizeOptions = [10, 20];
  readonly transactionFilterOption = [
    TransactionDirection.CREDIT,
    TransactionDirection.DEBIT,
  ];
  readonly rewardTypeFilter = [
    AnyoRewardTypes.NEW_IMAGE_POST,
    AnyoRewardTypes.NEW_VIDEO_POST,
    AnyoRewardTypes.NEW_TEXT_POST,
  ];
  pageSize = 10;
  currentPage = 1;
  totalRecords = 0;
  spinner = false;
  editingRow: number | null = null;
  activeTab = 'Complete';
  gridData: RewardsGridResponse[] = [];
  viewLedgerGridData: RewardsGridResponse[] = [];
  isDrawerVisible = false;
  isPaymentModalVisible = false;
  paymentForm: FormGroup;
  userId: string = '';
  amount: number = 0;
  dateRange: Date[] = [];
  dateFormat = 'dd/MM/yyyy';
  selectedTransactionType: TransactionDirection | null = null;
  selectedUserId: { userName: string; userId: string } | null = null;
  selectedRewardType: string | null = null;
  nameFilterOption: ExpertRewardsGridFilterOption =
    {} as ExpertRewardsGridFilterOption;

  async ready(): Promise<void> {
    if (this.user) {
      await this.getAllOrPendingGridData();
    }
  }

  async getAllOrPendingGridData(pending: boolean = false) {
    this.spinner = true;
    const reqBody: ExpertRewardsGridRequest = this.buildGridRequest(pending);
    this.rewardService.rewardGrid(reqBody).subscribe({
      next: (res) => {
        this.gridData = pending
          ? res.data.filter((data) => data.creditValue > 0)
          : res.data;
        this.totalRecords = res.totalRecords;
        this.nameFilterOption = res.filterOptions;
      },
      error: (e) => {
        const error = e.error as IAnyoError;
        this.toastService.showError(error.description);
      },
      complete: () => {
        this.spinner = false;
      },
    });
  }

  buildGridRequest(pending: boolean): ExpertRewardsGridRequest {
    const reqBody: ExpertRewardsGridRequest = {
      page: this.currentPage,
      pageSize: this.pageSize,
      filters: {
        TRANSACTION_TYPE: [],
        DESCRIPTION: [],
        PAYMENT_SORT: [],
        CREDIT: [],
        DEBIT: [],
        USER_ID: [],
      },
      export: false,
    };
    if (pending) reqBody.filters!.PAYMENT_SORT = [PaymentStatus.Pending];
    if (this.dateRange.length > 0) {
      reqBody.dateFilters = {
        TRANSFER_DATE: {
          from: moment(this.dateRange[0], 'x').startOf('day').toDate(),
          to: moment(this.dateRange[1], 'x').endOf('day').toDate(),
        },
      };
    }
    if (this.activeTab === 'Complete')
      reqBody.filters!.PAYMENT_SORT = [PaymentStatus.Success];
    if (this.selectedTransactionType)
      reqBody.filters![
        this.selectedTransactionType === TransactionDirection.CREDIT
          ? 'CREDIT'
          : 'DEBIT'
      ] = ['true'];
    if (this.selectedUserId)
      reqBody.filters!.USER_ID = [this.selectedUserId.userId];
    if (this.selectedRewardType)
      reqBody.filters!.DESCRIPTION = [this.selectedRewardType];
    return reqBody;
  }

  async openDrawer(id: RewardsGridResponse): Promise<void> {
    this.isDrawerVisible = true;
    await this.getLedgerGridData(id);
  }

  closeDrawer(): void {
    this.isDrawerVisible = false;
  }

  async getLedgerGridData(data: RewardsGridResponse): Promise<void> {
    this.spinner = true;
    const reqBody: ExpertRewardsGridRequest = {
      page: 1,
      pageSize: 100,
      filters: {
        TRANSACTION_TYPE: [],
        DESCRIPTION: [],
        PAYMENT_SORT: [],
        CREDIT: [],
        DEBIT: [],
        USER_ID: [data.userId],
      },
      dateFilters: {
        TRANSFER_DATE: {
          from: moment(data.duration?.start, 'x').startOf('day').toDate(),
          to: moment(data.duration?.end, 'x').endOf('day').toDate(),
        },
      },
      export: false,
    };

    return new Promise<void>((resolve, reject) => {
      this.rewardService.rewardGrid(reqBody).subscribe({
        next: (res) => {
          this.viewLedgerGridData = res.data.filter((d) => d.creditValue >= 0);
          resolve();
        },
        error: (e) => {
          const error = e.error as IAnyoError;
          this.toastService.showError(error.description);
          reject(e);
        },
        complete: () => {
          this.spinner = false;
        },
      });
    });
  }

  onTabChange(event: NzTabChangeEvent): void {
    this.activeTab = event.tab.nzTitle;
    this.resetFilters();
    this.getAllOrPendingGridData(this.activeTab === 'Pending');
  }

  resetFilters(): void {
    this.dateRange = [];
    this.selectedTransactionType = null;
    this.selectedUserId = null;
    this.selectedRewardType = null;
    this.currentPage = 1;
  }

  async resetAllFilter() {
    this.resetFilters();
    await this.getAllOrPendingGridData(this.activeTab === 'Pending');
  }

  async resetFilterAndFetchData(
    filterName: 'TRANSACTION_TYPE' | 'USER_ID' | 'REWARD_TYPE',
  ) {
    switch (filterName) {
      case 'TRANSACTION_TYPE':
        this.selectedTransactionType = null;
        break;
      case 'USER_ID':
        this.selectedUserId = null;
        break;
      case 'REWARD_TYPE':
        this.selectedRewardType = null;
        break;
    }
    await this.getAllOrPendingGridData(this.activeTab === 'Pending');
  }

  async onSubmitFilterAndFetchData() {
    await this.getAllOrPendingGridData(this.activeTab === 'Pending');
  }

  openPaymentModal(userId: string, amount: number): void {
    this.userId = userId;
    this.isPaymentModalVisible = true;
    this.amount = amount;
    this.paymentForm.patchValue({
      amount: amount,
    });
  }

  closePaymentModal(): void {
    this.isPaymentModalVisible = false;
    this.paymentForm.reset();
  }

  submitPaymentForm(): void {
    if (this.paymentForm.valid) {
      const { transactionId, modeOfPayment } = this.paymentForm.value;

      const reqBody: AdminMakePayoutToExpertsRequest = {
        userId: this.userId,
        amount: this.amount,
        transactionId,
        modeOfPayment,
      };

      this.rewardService.makePayout(reqBody).subscribe({
        next: () => {
          this.toastService.showSuccess('Payment Successful');
          this.closePaymentModal();
          this.getAllOrPendingGridData(true);
        },
        error: (e) => {
          const error = e.error as IAnyoError;
          this.toastService.showError(error.description);
        },
      });
    }
  }

  async nzPageIndexChange($event: number) {
    this.currentPage = $event;
    await this.getAllOrPendingGridData(this.activeTab === 'Pending');
  }

  async nzPageSizeChange($event: number) {
    this.pageSize = $event;
    await this.getAllOrPendingGridData();
  }

  async dateChange($event: Date[]) {
    this.dateRange = $event;
    await this.getAllOrPendingGridData(this.activeTab === 'Pending');
  }
}

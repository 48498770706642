import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ContentRoutingModule } from './content-routing.module';
import { AddComponent } from './add/add.component';
import { FormsModule } from '@angular/forms';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { ManageComponent } from './manage/manage.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { Select2Module } from 'ng-select2-component';
import { RouterModule } from '@angular/router';
import { CommonComponentModule } from 'projects/app-core/src/app/components/commoncomponent.module';
import { HttpLoaderFactory } from '../../app.module';
import { ComponentModule } from '../../components/component.module';
import { NzUploadModule } from 'ng-zorro-antd/upload';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzCardModule } from 'ng-zorro-antd/card';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { ViewComponent } from './view/view.component';
import { NzPaginationModule } from 'ng-zorro-antd/pagination';
import { NzRateModule } from 'ng-zorro-antd/rate';
import { AddRecipeComponent } from './add-recipe/add-recipe.component';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzBadgeModule } from 'ng-zorro-antd/badge';
import { NzSwitchModule } from 'ng-zorro-antd/switch';

@NgModule({
  declarations: [AddComponent, ManageComponent, ViewComponent,AddRecipeComponent],
  imports: [
    ComponentModule,
    CommonComponentModule,
    RouterModule,
    CommonModule,
    ContentRoutingModule,
    FormsModule,
    Select2Module,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    InfiniteScrollModule,
    NzUploadModule,
    NzIconModule,
    NzSelectModule,
    NzGridModule,
    NzInputModule,
    NzDividerModule,
    NzButtonModule,
    NzCardModule,
    NzCheckboxModule,
    NzSpinModule,
    NzPaginationModule,
    NzRateModule,
    NzFormModule,
    NzBadgeModule,
    NzSwitchModule
  ],
})
export class ContentModule {}

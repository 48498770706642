import { lastValueFrom } from 'rxjs';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import * as moment from 'moment';
import { Permissions } from 'projects/app-core/src/auth/models/roles';
import { IAnyoError } from '../../../models/error/errorResponse';
import { IAnyoTherapist } from 'projects/admin/src/app/models/IAnyoTherapist';
import { AuthService } from 'projects/app-core/src/auth/auth.service';
import {
  SessionAddRemarkRequest,
  SessionsService,
} from 'projects/app-core/src/app/session/service/sessions.service';
import { ToastService } from '../../../services/toastr.service';
import { AutocompleteDataSourceItem } from 'ng-zorro-antd/auto-complete';
import {
  SessionGridFilterData,
  SessionGridResponse,
  SessionGridResponseData,
} from '../../models/sessionGridResponse';
import { UpdateSessionRequest } from '../../models/SessionRequest';
import { SessionFeedbackFormSubmitRequest } from '../../models/sessionFeedbackFormSubmitRequest';
import {
  AppointmentStatus,
  SessionTags,
} from '../../models/TherapistAppointment';
import { Router } from '@angular/router';
import { SessionGridRequest } from '../../models/sessionGridRequest';
import { UtilsService } from 'projects/app-core/src/service/utils.service';
import { MasterdataService } from 'projects/admin/src/app/services/masterdata.service';
import { TherapyVerticals } from 'projects/app-core/src/models/TherapyVerticals';
import { TherapyConcerns } from 'projects/app-core/src/models/TherapyConcerns';
import { MasterData } from 'projects/admin/src/app/models/IAnyoListener';
import { MasterDataResponse } from 'projects/admin/src/app/models/masterDataResponse';
import { TherapyPlans } from 'projects/admin/src/app/models/TherapyPlans';
import { TherapyPlansService } from 'projects/admin/src/app/services/therapy-plans.service';
import { TherapyPlansAppResponse } from '../../../models/TherapyPlansAppResponse';
import { ActiveTherapyPlansResponse } from '../../../models/ActiveTherapyPlansResponse';

interface CheckBoxOptions {
  label: string;
  value: string;
  checked: boolean;
}

export interface SearchUserResponse {
  email: string;
  id: string;
  name: string;
}

export interface SlotAvailability {
  date: string;
  available: boolean;
  start: string;
  end: string;
}

export interface AdminTherapistBookingRequest {
  userEmail: string;
  therapistId: string;
  appointmentDateTime: number;
  appointmentServiceType: AppointmentServiceType;
  emergencyAppointment: boolean;
  planSubscriptionId: string;
}

enum AppointmentServiceType {
  Therapist = 'Therapist',
}

interface therapistSelectOptions {
  label: string;
  value: string;
}

@Component({
  selector: 'app-session-table',
  templateUrl: './session-table.component.html',
  styleUrls: ['./session-table.component.scss'],
})
export class SessionTableComponent {
  @Output() reloadTableEvent = new EventEmitter<void>();
  @Input() inputTableData: SessionGridResponse | undefined;
  @Output() resetFilterEvent = new EventEmitter<void>();
  @Output() pageSizeChangeEvent = new EventEmitter<number>();
  @Output() clientSearchEvent = new EventEmitter<string>();
  @Output() sessionDateFilterEvent = new EventEmitter<Date[]>();
  @Output() sessionDateSortEvent = new EventEmitter<string | null>();
  @Output() sessionStatusFilterEvent = new EventEmitter<string[]>();
  @Output() partnerIdFilterEvent = new EventEmitter<string[]>();
  @Output() partnerNameFilterEvent = new EventEmitter<string[]>();
  @Output() therapistFilterEvent = new EventEmitter<string[]>();
  @Output() pageChangeEvent = new EventEmitter<number>();
  @Output() hideTestBookingEvent = new EventEmitter<boolean>();
  @Output() viewPatientDetailsEvent = new EventEmitter<string>();
  @Output() confirmAppointmentEvent = new EventEmitter<string>();
  @Output() cancelAppointmentEvent = new EventEmitter<{
    userName: string;
    userEmail: string;
    id: string;
  }>();
  @Output() updateAppointmentEvent = new EventEmitter<UpdateSessionRequest>();
  @Output() exportDataEvent = new EventEmitter<void>();
  @Output() feedbackSubmitEvent =
    new EventEmitter<SessionFeedbackFormSubmitRequest>();
  showFeedBackForm: boolean = false;
  feedbackFormSubmitLoading: boolean = false;
  @Input() showBarIcon!: boolean;
  updateAppointmentSubmitLoading: boolean = false;
  @Input() therapistWithId!: Array<therapistSelectOptions> | undefined;
  @Input() showUpdateButtonPortal!: boolean;
  @Input() tableQuery: SessionGridRequest | undefined;
  @Input() masterData: MasterDataResponse | undefined;

  protected readonly SessionStatus = AppointmentStatus;

  selectedSession: SessionGridResponseData | undefined;
  selectedStatus!: string;
  sessionCompleted = 'Yes';
  feedbackRemarks: string | undefined;
  showRemarksModal = false;
  showNewSessionModal = false;
  newSessionSubmitProgress = false;
  activeTherapists: IAnyoTherapist[] = [];
  selectedUser: string | undefined;
  selectedUserId: string | undefined;
  bookSessionDateTimeSpinner: boolean = false;

  pageSize: number = 10;
  pageSizeOptions = [10, 20, 50, 100];
  clientPhoneEmailSearch: string = '';
  sessionStatusCheckBoxOptions: CheckBoxOptions[] = [];
  partnerIdCheckBoxOptions: CheckBoxOptions[] = [];
  therapistCheckBoxOptions: CheckBoxOptions[] = [];
  partnerCompanyNameCheckBoxOptions: CheckBoxOptions[] = [];
  selectedSessionFilterStatus: string[] = [];
  selectedPartId: string[] = [];
  selectedTherapists: string[] = [];
  sessionColumnFilterDate: Date[] | undefined;
  expandSet = new Set<string>();
  @Input() showNewBookingBtn: boolean = false;
  visibleUpdateAppointment: boolean = false;
  selectedTherapistString!: string;
  startTime!: Date;
  updateDate!: Date;

  closeNewSessionModal() {
    this.showNewSessionModal = false;
  }

  closeUpdateModal() {
    this.visibleUpdateAppointment = false;
  }

  resetValues() {
    this.clientPhoneEmailSearch = '';
    this.sessionColumnFilterDate = undefined;
    this.pageSize = 10;
    this.selectedSessionFilterStatus = [];
  }

  submitFeedBack() {
    if (!this.selectedSession) {
      return;
    }
    this.feedbackRemarks = this.feedbackRemarks?.trim();
    if (!this.feedbackRemarks) {
      this.toastService.showAnyoErrorToast('Please enter remarks');
      return;
    }

    const request: SessionFeedbackFormSubmitRequest = {
      sessionCompleted: this.sessionCompleted === 'Yes',
      sessionId: this.selectedSession!.id,
      remarks: this.feedbackRemarks!,
      sessionStatus: this.selectedStatus,
    };
    this.feedbackSubmitEvent.emit(request);
  }

  submitUpdateAppointment() {
    if (!this.selectedSession) {
      return;
    }
    this.selectedTherapistString = this.selectedTherapistString?.trim()!;
    if (!this.selectedTherapistString) {
      this.toastService.showAnyoErrorToast('Please Select Therapist');
      return;
    }
    const selectedDateTime = moment(this.updateDate).set({
      hours: parseInt(moment(this.startTime).format('HH')),
      minutes: 0,
      second: 0,
      millisecond: 0,
    });
    const combinedEpoch = selectedDateTime.format('x');
    const request = {
      startTime: combinedEpoch,
      therapistId: this.selectedTherapistString,
      sessionId: this.selectedSession!.id,
    };
    this.updateAppointmentEvent.emit(request);
  }

  resetFilter() {
    this.resetFilterEvent.emit();
  }

  nzPageIndexChange(page: number) {
    this.pageChangeEvent.emit(page);
  }

  clientSearchFn() {
    this.clientSearchEvent.emit(this.clientPhoneEmailSearch);
  }

  setTherapistFilterData(filterOptions?: SessionGridFilterData) {
    this.therapistCheckBoxOptions = [];
    filterOptions?.therapists.forEach((th) => {
      if (this.tableQuery!.filters?.THERAPIST_EMAIL?.includes(th.id)) {
        this.therapistCheckBoxOptions.push({
          checked: true,
          label: th.name,
          value: th.id,
        });
      } else {
        this.therapistCheckBoxOptions.push({
          checked: false,
          label: th.name,
          value: th.id,
        });
      }
    });
  }

  setPartnerNameFilterData(filterOptions?: SessionGridFilterData) {
    this.partnerCompanyNameCheckBoxOptions = [];
    filterOptions?.partners.forEach((th) => {
      if (this.tableQuery!.filters?.PARTNER_ID?.includes(th.partnerId)) {
        this.partnerCompanyNameCheckBoxOptions.push({
          checked: true,
          label: th.partnerId + '-' + th.companyName,
          value: th.partnerId,
        });
      } else {
        this.partnerCompanyNameCheckBoxOptions.push({
          checked: false,
          label: th.partnerId + '-' + th.companyName,
          value: th.partnerId,
        });
      }
    });
  }

  setSessionStatusFilterData(filterOptions?: SessionGridFilterData) {
    this.sessionStatusCheckBoxOptions = [];
    filterOptions?.sessionStatus.forEach((status) => {
      if (this.tableQuery!.filters?.SESSION_STATUS?.includes(status)) {
        this.sessionStatusCheckBoxOptions.push({
          checked: true,
          label: status,
          value: status,
        });
      } else {
        this.sessionStatusCheckBoxOptions.push({
          checked: false,
          label: status,
          value: status,
        });
      }
    });
  }

  constructor(
    private router: Router,
    private auth: AuthService,
    private sessionService: SessionsService,
    private toastService: ToastService,
    public utils: UtilsService,
    public therapyPlanService: TherapyPlansService,
  ) {}

  remarkForPastSession: string = '';
  showRemarkForm = false;
  addRemarkForPastUserLoading = false;

  closeRemarkForm() {
    this.showRemarkForm = false;
  }

  openRemarkForm(data: SessionGridResponseData) {
    this.showRemarkForm = true;
    this.selectedSession = data;
  }

  addRemarkForPastUser() {
    this.addRemarkForPastUserLoading = true;
    const request: SessionAddRemarkRequest = {
      sessionId: this.selectedSession?.id!,
      remarks: this.remarkForPastSession,
    };
    this.sessionService.addRemarkForPastSession(request).subscribe({
      next: () => {
        this.addRemarkForPastUserLoading = false;
        this.showRemarkForm = false;
        this.remarkForPastSession = '';
        this.reloadTableEvent.emit();
        this.toastService.showSuccess('Successfully Remarks Added');
      },
      error: (error) => {
        this.addRemarkForPastUserLoading = false;
        const errorBody = error.error as IAnyoError;
        this.toastService.showAnyoErrorToast(
          'Error while adding therapists remarks' + errorBody.description,
        );
      },
    });
  }

  dateFilterFn() {
    this.sessionDateFilterEvent.emit(this.sessionColumnFilterDate);
  }

  sortSessionDates(sort: string | null) {
    this.sessionDateSortEvent.emit(sort);
  }

  sessionStatusFilterChangeFn($event: string[]) {
    this.selectedSessionFilterStatus = $event;
  }

  formatSessionDate(date?: string | undefined) {
    if (!date) {
      return 'N/A';
    }
    return moment(date, 'x').format('DD/MM/yyyy');
  }
  formatSessionTime(date: string) {
    return moment(date, 'x').format('hh:mm A');
  }

  sessionStatusFilterFn() {
    this.sessionStatusFilterEvent.emit(this.selectedSessionFilterStatus);
  }

  onSessionTimeChange($event: Date[]) {
    this.sessionColumnFilterDate = $event;
  }

  therapistFilterChangeFn($event: string[]) {
    this.selectedTherapists = $event;
  }

  nzPageSizeChange(pageSize: number) {
    this.pageSizeChangeEvent.emit(pageSize);
  }

  therapistFilterFn() {
    this.therapistFilterEvent.emit(this.selectedTherapists);
  }

  confirmAppointmentFn(id: string) {
    this.confirmAppointmentEvent.emit(id);
  }

  cancelAppointmentFn(id: string, userName: string, userEmail: string) {
    const data = {
      userName: userName,
      userEmail: userEmail,
      id: id,
    };
    this.cancelAppointmentEvent.emit(data);
  }
  UpdateAppointmentFn(data: SessionGridResponseData) {
    this.selectedSession = data;
    this.selectedTherapistString = data.therapistDetails.id;
    this.startTime = moment(data.sessionStart, 'x').toDate();
    const dateMoment = moment(data.sessionDate, 'x');
    const dateObject = dateMoment.toDate();
    this.updateDate = dateObject;
    this.visibleUpdateAppointment = true;
  }

  viewDetailsFn(id: string) {
    this.viewPatientDetailsEvent.emit(id);
  }

  onExpandChange(id: string, checked: boolean) {
    if (checked) {
      this.expandSet.add(id);
    } else {
      this.expandSet.delete(id);
    }
  }

  showConfirmButton(status: AppointmentStatus) {
    return [
      AppointmentStatus.NEW_REQUEST,
      AppointmentStatus.THERAPIST_RESCHEDULE_REQUESTED,
      AppointmentStatus.CANCELLATION_REQUESTED,
    ].includes(status);
  }

  showCancelButton(status: AppointmentStatus) {
    return [
      AppointmentStatus.CONFIRMED,
      AppointmentStatus.NEW_REQUEST,
      AppointmentStatus.THERAPIST_RESCHEDULE_REQUESTED,
      AppointmentStatus.CANCELLATION_REQUESTED,
    ].includes(status);
  }

  showUpdateButton(status: AppointmentStatus) {
    return [AppointmentStatus.CONFIRMED].includes(status);
  }

  showRemarkButton(status: AppointmentStatus, endTime: string) {
    // return (
    //   moment().isAfter(moment(endTime, 'x')) &&
    //   ![
    //     AppointmentStatus.CONFIRMED,
    //     AppointmentStatus.FEEDBACK_PENDING,
    //     AppointmentStatus.MISSED_APPOINTMENT,
    //   ].includes(status)
    // );
    return true;
  }

  showFeedbackPendingButton(status: AppointmentStatus, endTime: string) {
    return (
      moment().isAfter(moment(endTime, 'x')) &&
      [
        AppointmentStatus.CONFIRMED,
        AppointmentStatus.FEEDBACK_PENDING,
        AppointmentStatus.MISSED_APPOINTMENT,
      ].includes(status)
    );
  }

  showFeedBackFormFn(data: SessionGridResponseData) {
    this.selectedSession = data;
    this.showFeedBackForm = true;
    this.feedbackRemarks = '';
    if (data.sessionStatus === AppointmentStatus.MISSED_APPOINTMENT) {
      this.selectedStatus = AppointmentStatus.MISSED_APPOINTMENT;
      this.sessionCompleted = 'No';
    }
  }

  closeFeedbackForm() {
    this.showFeedBackForm = false;
  }

  availableSlots: Record<string, SlotAvailability> | undefined;
  sessionOkDisabled: boolean = true;
  selectedSessionDate: Date | undefined;
  userSearchResults: AutocompleteDataSourceItem[] = [];
  userSearchResultsResponse: SearchUserResponse[] = [];
  selectedTherapist: string | undefined;
  emergencyBooking: boolean = false;
  searchSpinner: boolean = false;
  selectedSessionTime: Date | undefined;
  date: any;
  showTestBookings: boolean = false;
  fetchPlansLoading: boolean = false;

  setPartnerIdFilterData(filterOptions?: SessionGridFilterData) {
    this.partnerIdCheckBoxOptions = [];
    filterOptions?.partnerId.forEach((status) => {
      if (this.tableQuery!.filters?.PARTNER_ID?.includes(status)) {
        this.partnerIdCheckBoxOptions.push({
          checked: true,
          label: status,
          value: status,
        });
      } else {
        this.partnerIdCheckBoxOptions.push({
          checked: false,
          label: status,
          value: status,
        });
      }
    });
  }

  getColumnName() {
    return this.selectedSession?.sessionStatus ===
      AppointmentStatus.THERAPIST_RESCHEDULE_REQUESTED ||
      this.selectedSession?.sessionStatus.includes(
        SessionTags.THERAPIST_CANCELATION_REQUESTED,
      )
      ? 'Therapist'
      : 'User';
  }

  exportData() {
    this.exportDataEvent.emit();
  }
  sessionCompletedChangeFn($event: string) {}

  isSessionCompleted() {
    return this.sessionCompleted === 'Yes';
  }

  closeRewardsModal() {
    this.selectedSession = undefined;
    this.showRemarksModal = false;
  }

  onSessionFilterDateChange($event: Date[]) {
    this.sessionColumnFilterDate = $event;
  }

  showRemarksModalFn(data: SessionGridResponseData) {
    this.selectedSession = data;
    this.showRemarksModal = true;
  }

  showNewSessionFn() {
    this.showNewSessionModal = true;
    this.sessionService.getActiveTherapists().subscribe({
      next: (response) => {
        this.activeTherapists = response;
      },
      error: (error) => {
        const errorBody = error.error as IAnyoError;
        this.toastService.showAnyoErrorToast(
          'Error while fetching active therapists' + errorBody.description,
        );
      },
    });
  }

  submitNewSessionFn() {
    this.newSessionSubmitProgress = true;
    if (!this.availableSlots) {
      this.toastService.showError(
        'There is no available slots for the Therapist',
      );
      this.newSessionSubmitProgress = false;
      return;
    }
    if (!this.selectedActivePlan) {
      this.toastService.showAnyoErrorToast('Please select a plan');
      this.newSessionSubmitProgress = false;
      return;
    }
    const selectedDateTime = moment(this.selectedSessionDate).set({
      hours: parseInt(moment(this.selectedSessionTime).format('HH')),
      minutes: 0,
      second: 0,
      millisecond: 0,
    });
    if (selectedDateTime.isBefore(selectedDateTime)) {
      this.toastService.showAnyoErrorToast('Date should be in future');
      this.newSessionSubmitProgress = false;
      return;
    }
    const selectedDate = moment(this.selectedSessionDate).set({
      hours: 0,
      minutes: 0,
      second: 0,
      millisecond: 0,
    });

    const slot = this.availableSlots![selectedDate.format('x')];
    if (!slot) {
      this.newSessionSubmitProgress = false;
      this.toastService.showAnyoErrorToast('Therapist is not available');
      return;
    }
    if (!this.selectedUser) {
      this.newSessionSubmitProgress = false;
      this.toastService.showError('Please select a user');
      return;
    }
    if (!this.selectedTherapist) {
      this.newSessionSubmitProgress = false;
      this.toastService.showError('Please select a therapist');
      return;
    }
    const request: AdminTherapistBookingRequest = {
      appointmentDateTime: Number(selectedDateTime.format('x')),
      appointmentServiceType: AppointmentServiceType.Therapist,
      emergencyAppointment: false,
      therapistId: this.selectedTherapist!,
      userEmail: this.selectedUser,
      planSubscriptionId: this.selectedActivePlan.id,
    };
    this.sessionService.adminSessionBooking(request).subscribe({
      next: (response) => {
        this.toastService.showSuccess('Session booked successfully');
        this.newSessionSubmitProgress = false;
        this.showNewSessionModal = false;
      },
      error: (error) => {
        this.newSessionSubmitProgress = false;
        const errorBody = error.error as IAnyoError;
        this.toastService.showAnyoErrorToast(errorBody.description);
      },
    });
  }

  // showNewBookingButton$ = this.auth.currentAuthStatus.pipe(
  //   map((user) => {
  //     if (
  //       user &&
  //       user.customClaims &&
  //       user.customClaims.additionalPermissions
  //     ) {
  //       return user.customClaims.additionalPermissions.includes(
  //         Permissions.AdminBookNewSession,
  //       );
  //     } else {
  //       return false;
  //     }
  //   }),
  // );

  async showNewBookingButton(): Promise<boolean> {
    try {
      const user = await this.auth.currentAuthStatus.toPromise();
      if (
        user &&
        user.customClaims &&
        user.customClaims.additionalPermissions
      ) {
        return user.customClaims.additionalPermissions.includes(
          Permissions.AdminBookNewSession,
        );
      } else {
        return false;
      }
    } catch (error) {
      console.error('Error fetching user data:', error);
      return false;
    }
  }

  onSearchUser($event: Event) {
    const searchText = ($event.target as HTMLInputElement).value;
    if (searchText.length > 3) {
      this.searchSpinner = true;
      this.sessionService.adminSearchUsers(searchText).subscribe({
        next: (response) => {
          this.searchSpinner = false;
          this.userSearchResults = [];
          this.userSearchResultsResponse.push(...response);
          response.forEach((value) => {
            this.userSearchResults.push({
              value: value.email,
              label: value.email,
            });
          });
        },
        error: (error) => {
          this.searchSpinner = false;
          const errorBody = error.error as IAnyoError;
          this.toastService.showAnyoErrorToast(errorBody.description);
        },
      });
    }
  }

  therapistSelectedFn($event: any) {
    if (this.selectedUser && this.selectedTherapist) {
      this.bookSessionDateTimeSpinner = true;
      this.sessionService
        .getAvailableSlotsTherapist(this.selectedTherapist, this.selectedUser)
        .subscribe({
          next: (response) => {
            this.bookSessionDateTimeSpinner = false;
            this.availableSlots = response;
          },
          error: (error) => {
            this.searchSpinner = false;
            const errorBody = error.error as IAnyoError;
            this.toastService.showAnyoErrorToast(errorBody.description);
          },
        });
    }
  }

  onNewSessionDateChange($event: Date) {
    if (!moment($event).startOf('day').isSameOrAfter(moment().startOf('day'))) {
      this.toastService.showError('Date should be in future');
      return;
    }
    this.selectedSessionDate = $event;
  }

  onNewSessionTimeChange($event: Date) {
    this.selectedSessionTime = $event;
    if (this.selectedSessionDate && this.selectedSessionTime) {
      this.sessionOkDisabled = false;
    }
  }

  onUpdateSessionTimeChange($event: Date) {
    this.startTime = $event;
    if (this.startTime && this.updateDate) {
      this.sessionOkDisabled = false;
    }
  }

  onUpdateSessionDateChange($event: Date) {
    if (moment($event).isBefore(moment())) {
      this.toastService.showError('Date should be in future');
      return;
    }
    this.updateDate = $event;
  }

  testBookingChangeFn() {
    this.hideTestBookingEvent.emit(this.showTestBookings);
    console.log('this.showTestBookings', this.showTestBookings);
  }

  openUserSessionPage(email: string) {
    const isAdminPortal = this.router.routerState.snapshot.url.includes(
      'therapist/session/admin',
    );
    window.open(
      `${
        isAdminPortal ? 'therapist/session/admin' : '/sessions/therapist'
      }?userId=${email}`,
    );
  }

  openTherapyPlansSubscriptionPageByPlanName(planName: string) {
    const isAdminPortal = this.router.routerState.snapshot.url.includes(
      'therapist/session/admin',
    );
    window.open(
      `${
        isAdminPortal ? 'therapy-plans/subscriptions' : '/sessions/therapist'
      }?planName=${planName}`,
    );
  }

  openTherapyPlansSubscriptionPageByUser(userId: string) {
    const isAdminPortal = this.router.routerState.snapshot.url.includes(
      'therapist/session/admin',
    );
    window.open(
      `${
        isAdminPortal ? 'therapy-plans/subscriptions' : '/sessions/therapist'
      }?userId=${userId}`,
    );
  }

  openTherapistSessionsPage(email: string) {
    const isAdminPortal = this.router.routerState.snapshot.url.includes(
      'therapist/session/admin',
    );
    window.open(
      `${
        isAdminPortal ? 'therapist/session/admin' : '/sessions/therapist'
      }?therapistId=${email}`,
    );
  }

  partnerIdFilterChangeFn($event: string[]) {
    this.selectedPartId = $event;
    this.partnerIdFilterEvent.emit(this.selectedPartId);
  }

  partnerNameFilterChangeFn($event: string[]) {
    this.partnerNameFilterEvent.emit($event);
  }
  activateNewPlanLoading = false;
  activateNewPlanModal = false;
  verticals: TherapyVerticals[] = [];
  concerns: TherapyConcerns[] = [];
  filteredConcerns: TherapyConcerns[] = [];
  selectedVerticalId: string | undefined;
  selectedConcernId: string | undefined;
  selectedVertical: TherapyVerticals | undefined;
  selectedConcern: TherapyConcerns | undefined;
  selectedPlanId: string | undefined;
  selectedPlan: TherapyPlansAppResponse | undefined;
  plansForSelectedConcern: TherapyPlansAppResponse[] = [];
  paymentOrderId: string | undefined;
  onVerticalChange($event: any) {
    console.log('event', $event);
    this.selectedVertical = this.verticals.find(
      (vertical) => vertical.m_id === $event,
    );

    this.filteredConcerns = this.concerns.filter((concern) =>
      concern.verticalId.includes(this.selectedVertical!.m_id),
    );
  }
  onPlanChange($event: any) {
    this.selectedPlan = this.plansForSelectedConcern.find(
      (plan) => plan.planId === $event,
    );
  }
  onConcernChange($event: any) {
    this.selectedConcern = this.filteredConcerns.find(
      (concern) => concern.m_id === $event,
    );
    if (!this.selectedConcern) {
      this.toastService.showError('Please select a valid concern');
      return;
    }
    if (!this.selectedVertical) {
      this.toastService.showError('Please select a valid vertical');
      return;
    }
    if (!this.selectedTherapist) {
      this.toastService.showError('Please select a valid therapist');
      return;
    }
    if (!this.selectedUserId) {
      this.toastService.showError('Please select a valid user');
      return;
    }
    this.fetchPlansLoading = true;
    this.therapyPlanService
      .fetchPlansForConcernVertical(
        this.selectedVertical.m_id,
        this.selectedConcern.m_id,
        this.selectedTherapist,
        this.selectedUserId,
      )
      .subscribe({
        next: (response) => {
          this.plansForSelectedConcern = response;
          this.fetchPlansLoading = false;
        },
        error: (error) => {
          this.fetchPlansLoading = false;
          const errorBody = error.error as IAnyoError;
          this.toastService.showAnyoErrorToast(
            'Error while fetching plans for concern' + errorBody.description,
          );
        },
      });
  }

  showActivateNewPlaModal() {
    this.verticals = this.masterData!.therapyVerticals
      ? this.masterData!.therapyVerticals
      : [];
    this.concerns = this.masterData!.therapyConcerns
      ? this.masterData!.therapyConcerns
      : [];
    this.sessionService.getActiveTherapists().subscribe({
      next: (response) => {
        this.activeTherapists = response;
      },
      error: (error) => {
        const errorBody = error.error as IAnyoError;
        this.toastService.showAnyoErrorToast(
          'Error while fetching active therapists' + errorBody.description,
        );
      },
    });
    this.activateNewPlanModal = true;
  }
  hideActivateNewPlanModal() {
    this.activateNewPlanModal = false;
  }
  activateNewPlan() {
    if (!this.selectedConcern) {
      this.toastService.showError('Please select a valid concern');
      return;
    }
    if (!this.selectedVertical) {
      this.toastService.showError('Please select a valid vertical');
      return;
    }
    if (!this.selectedTherapist) {
      this.toastService.showError('Please select a valid therapist');
      return;
    }
    if (!this.selectedUserId) {
      this.toastService.showError('Please select a valid user');
      return;
    }
    if (!this.selectedPlan) {
      this.toastService.showError('Please select a valid plan');
      return;
    }
    this.activateNewPlanLoading = true;

    const activatePlanRequest = {
      planId: this.selectedPlan.planId,
      therapistId: this.selectedTherapist,
      userId: this.selectedUserId,
      concernId: this.selectedConcern.m_id,
      verticalId: this.selectedVertical.m_id,
      paymentOrderId: this.paymentOrderId,
    };
    this.therapyPlanService
      .activateAdminTherapySession(activatePlanRequest)
      .subscribe({
        next: () => {
          this.activateNewPlanLoading = false;
          this.toastService.showInfo('Plan activated successfully');
          this.activateNewPlanModal = false;
        },
        error: (error) => {
          this.activateNewPlanLoading = false;
          const errorBody = error.error as IAnyoError;
          this.toastService.showAnyoErrorToast(
            'Error while activating plan' + errorBody.description,
          );
        },
      });
  }
  onUserSelectFn($event: any) {
    this.selectedUserId = this.userSearchResultsResponse.find(
      (value) => value.email === $event,
    )?.id;
  }
  selectedActivePlanId: string | undefined;
  selectedActivePlan: ActiveTherapyPlansResponse | undefined;
  userActivePlans: ActiveTherapyPlansResponse[] = [];
  onActivePlanSelected($event: any) {
    if (!$event) {
      return;
    }

    this.selectedActivePlan = this.userActivePlans.find(
      (plan) => plan.id === $event,
    );
    this.selectedTherapist =
      this.selectedActivePlan?.therapistDetails.therapistId;
    this.therapistSelectedFn(this.selectedTherapist);
    console.log('selectedTherapist', this.selectedTherapist);
  }
  onUserSelectedBookNewSession($event: any) {
    if (this.selectedUser) {
      this.selectedUserId = this.userSearchResultsResponse.find(
        (value) => value.email === $event,
      )?.id;
    }

    if (this.selectedUserId) {
      this.therapyPlanService
        .fetchActiveTherapyPlansForUser(this.selectedUserId!)
        .subscribe({
          next: (response) => {
            this.userActivePlans = response;
          },
          error: (error) => {
            const errorBody = error.error as IAnyoError;
            this.toastService.showAnyoErrorToast(
              'Error while fetching active plans for user' + errorBody,
            );
          },
        });
    }
  }
}

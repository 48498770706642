import { GridResponse } from 'projects/app-core/src/models/gridResponse';

export interface RewardsGridResponse {
  userName: string;
  userId: string;
  date: string;
  transactionType: RewardsTransactionType | string;
  transactionId: string;
  description: AnyoRewardTypes | string;
  creditValue: number;
  debitValue: number;
  paymentStatus: PaymentStatus | string;
  modeOfPayment: string;
  walletBalance: number;
  postId?: string;
  duration?: {
    start: string;
    end: string;
  };
}

export interface ExpertRewardsGridFilterOption {
  userInfo: {
    userName: string;
    userId: string;
  }[];
}

export interface ExpertRewardsGridResponse
  extends GridResponse<ExpertRewardsGridFilterOption, RewardsGridResponse> {}

export enum AnyoRewardTypes {
  NEW_TEXT_POST = 'New Text Post',
  NEW_VIDEO_POST = 'New Video Post',
  NEW_IMAGE_POST = 'New Image Post',
}

export enum RewardsTransactionType {
  POST = 'Post',
  PAYOUT = 'Payout',
}

export enum TransactionDirection {
  CREDIT = 'Credit',
  DEBIT = 'Debit',
}

export enum PaymentStatus {
  Pending = 'Pending',
  Success = 'Success',
  Failed = 'Failed',
}

import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom, Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { CustomHttpUrlEncodingCodec } from 'projects/app-core/src/app/utils/encoder';

import {
  NetworkUtilsService,
  URL_DICT,
} from '../../../../app-core/src/service/network-utils.service';
import { PartnerGridRequest } from '../models/partnerGridRequest';
import { PartnerGridResponse } from '../models/partnerGridResponse';
import { ICountResponse } from '../models/IcountResponse';
import { IAnyoPartner } from '../models/IPartner';
import { EnableDisableRequest } from '../models/enableDisableRequest';

@Injectable({
  providedIn: 'root',
})
export class PartnerService {
  protected basePath = environment.serviceUrl;
  protected authPath = environment.serviceUrl2;
  constructor(
    protected httpClient: HttpClient,
    public api: NetworkUtilsService,
  ) {}

  public partnerCount() {
    return this.api.get<ICountResponse[]>(URL_DICT.partner + 'count');
  }

  public partnerList(gridRequest: PartnerGridRequest) {
    return this.api.post<PartnerGridResponse>(
      URL_DICT.partnerGrid,
      gridRequest,
    );
  }

  public async getAllActivePartners() {
    const girdRequest: PartnerGridRequest = {
      dateFilters: undefined,
      export: true,
      filters: undefined,
      page: 1,
      pageSize: 1000,
      searchQuery: undefined,
      sort: undefined,
    };
    try {
      const response = await lastValueFrom(
        this.api.post<PartnerGridResponse>(URL_DICT.partnerGrid, girdRequest),
      );
      const partners = response.data.filter(
        (value) => !value.planStatus.expired,
      );
      return partners;
    } catch (e) {
      console.log(e);
      return [];
    }
  }

  public createPartner(createPartnerRequest: IAnyoPartner) {
    return this.api.post(URL_DICT.partner + 'create', createPartnerRequest);
  }

  public getPartner(partnerId: string) {
    return this.api.get<IAnyoPartner>(
      URL_DICT.partner + `${String(partnerId)}`,
    );
  }

  public updatePartner(partnerId: string, updatePartnerRequest: IAnyoPartner) {
    return this.api.post(
      URL_DICT.partner + `${partnerId}`,
      updatePartnerRequest,
    );
  }

  public enableAndDisablePartner(
    partnerId: string,
    enableAndDisableRequest: EnableDisableRequest,
  ) {
    return this.api.post(
      URL_DICT.partner + `availability/${partnerId}`,
      enableAndDisableRequest,
    );
  }

  public transferMember(newPartnerId: string, memberId: string) {
    return this.api.post(URL_DICT.transferMember, {
      memberId: memberId,
      partnerId: newPartnerId,
    });
  }
  public deleteSpoc(
    id?: Object,
    observe: any = 'body',
    reportProgress: boolean = false,
  ): Observable<any> {
    let queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec(),
    });

    queryParameters = queryParameters.set('id', String(id));

    return this.httpClient.request<Object>(
      'delete',
      `${this.authPath}/auth/delete/${String(id)}`,
      {
        headers: {
          Authorization: 'Bearer 64e5d35828a4251948fbe3e1',
        },
        params: queryParameters,
        observe: observe,
        reportProgress: reportProgress,
      },
    );
  }
}

import { Component } from '@angular/core';
import { AnyoTableComponent } from 'projects/app-core/src/app/models/AnyoTableComponent';
import { SurveyRespFilter, SurveyResponsesGridRequest } from '../../../models/survey/SurveyResponsesGridRequest';
import { SurveyResponsesGridResponseData } from '../../../models/survey/surveyResponsesGridRespone';
import { UtilsService } from 'projects/app-core/src/service/utils.service';
import { AuthService } from 'projects/app-core/src/auth/auth.service';
import { SurveyService } from '../../../services/survey.service';
import { ToastService } from 'projects/app-core/src/app/services/toastr.service';
import { IAnyoUser } from 'projects/app-core/src/auth/models/IAnyoUser';
import * as moment from 'moment';
import { IAnyoError } from 'projects/app-core/src/models/errorModal';

interface CheckboxOptions {
  label: string;
  value: string;
  checked: boolean;
}

@Component({
  selector: 'app-manage-survey-responses',
  templateUrl: './manage-survey-responses.component.html',
  styleUrl: './manage-survey-responses.component.scss'
})
export class ManageSurveyResponsesComponent extends AnyoTableComponent<SurveyResponsesGridResponseData,SurveyResponsesGridRequest> {
  constructor(
      protected override auth: AuthService,
      protected toast: ToastService,
      private survey: SurveyService,
      public utils: UtilsService
    ) {
      super(auth, toast);
    }

    objectEntries = Object.entries;

    async getData(): Promise<void> {
      this.fetchSurveyResponses()
      return
    }

    surveyResponsesData: SurveyResponsesGridResponseData[] = []
    totalRecords: number = 0
    SurveyFilterCheckBoxOptions: CheckboxOptions[] = []
    page =  1
    createdAtColumnFilterDate: Date[] = []

    createdAtFilterDateChange($event: any[]){
        console.log($event)
        if ($event && $event.length == 2) {
          const startDate = moment($event[0]).startOf('day').toDate();
          const endDate = moment($event[1]).endOf('day').toDate();
          const isToday = moment(startDate).isSame(endDate, 'day');
          this.gridRequest = {
            dateFilters: {
              CREATED_AT: {
                from: isToday
                  ? moment($event[0]).startOf('day').toDate()
                  : startDate,
                to: isToday ? moment($event[1]).endOf('day').toDate() : endDate,
              },
            },
            export: false,
            filters: undefined,
            from: undefined,
            globalSearch: [],
            page: 1,
            pageSize: this.pageSize,
            searchQuery: undefined,
            to: undefined,
          };
    
        }
      }

    userDetails(data:SurveyResponsesGridResponseData){
      let text = "";
      Object.values(data.surveyEndDetails!).forEach((data) => text = text + "," + data )
      return text;
    }

    fetchSurveyResponses(){
      this.pageLoading = true
      this.survey.fetchSurveyResponsesGrid(this.gridRequest).subscribe({
        next: (value) => {
          this.surveyResponsesData = value.data
          this.SurveyFilterCheckBoxOptions = []
          this.SurveyFilterCheckBoxOptions = value.filterOptions?.surveys.map((data) => {
            if(this.gridRequest?.filters?.SURVEY?.includes(data.id)){
              return{
                label: data.name,
                value: data.id,
                checked: true
              }
            }else{
              return{
                label: data.name,
                value: data.id,
                checked: false
              }
            }
          })
          this.totalRecords = value.totalRecords
          this.pageLoading = false
        }, error: (err) => {
            this.pageLoading = false;
            const errorBody = err.error as IAnyoError;
            this.toast.showAnyoErrorToast(errorBody.description);
        }
      })
    }

    surveyFilterChangeFn($event: any[]){
        this.gridRequest.filters = {
          [SurveyRespFilter.SURVEY]: [],
        }
        if($event && $event.length){
          this.gridRequest.filters.SURVEY = $event
        }
      } 

    override async resetFilter() {
    this.gridRequest = {
      export: false,
      globalSearch: [],
      page: 1,
      pageSize: this.pageSize,
    };
    this.SurveyFilterCheckBoxOptions = [];
    this.createdAtColumnFilterDate = [];
    this.fetchSurveyResponses();
  }
}

import { Component, Input } from '@angular/core';
import { TherapistAvailabilityV2 } from 'projects/admin/src/app/models/IAnyoTherapist';
import { TherapistService, UpdateAvailabilityV2ForTherapistRequest } from 'projects/admin/src/app/services/therapist.service';
import { UtilsService } from 'projects/app-core/src/service/utils.service';
import { ToastService } from '../../services/toastr.service';
import { IAnyoError } from '../../models/error/errorResponse';
import { AuthService } from 'projects/app-core/src/auth/auth.service';
import * as moment from 'moment';

interface DisplayAvailabilityV2 extends TherapistAvailabilityV2 {
  color: string;
}

@Component({
  selector: 'app-set-availability-v2',
  templateUrl: './set-availability-v2.component.html',
  styleUrl: './set-availability-v2.component.scss'
})
export class SetAvailabilityV2Component {
  constructor(
    private utils: UtilsService,
    private therapistService: TherapistService,
    private toastrService: ToastService,
    private auth: AuthService,
  ){}
  availabilityV2: DisplayAvailabilityV2[] = [
    {
      day: 'monday',
      color: "",
      slots: [
        {
          slotEnd: '',
          slotStart: '',
          offline: true,
          online: true,
        }
      ]
    },
    {
      day: 'tuesday',
      color: "",
      slots: [
        {
          slotEnd: '',
          slotStart: '',
          offline: true,
          online: true,
        }
      ]
    },
    {
      day: 'wednesday',
      color: "",
      slots: [
        {
          slotEnd: '',
          slotStart: '',
          offline: true,
          online: true,
        }
      ]
    },
    {
      day: 'thursday',
      color: "",
      slots: [
        {
          slotEnd: '',
          slotStart: '',
          offline: true,
          online: true,
        }
      ]
    },
    {
      day: 'friday',
      color: "",
      slots: [
        {
          slotEnd: '',
          slotStart: '',
          offline: true,
          online: true,
        }
      ]
    },
    {
      day: 'saturday',
      color: "",
      slots: [
        {
          slotEnd: '',
          slotStart: '',
          offline: true,
          online: true,
        }
      ]
    },
    {
      day: 'sunday',
      color: "",
      slots: [
        {
          slotEnd: '',
          slotStart: '',
          offline: true,
          online: true,
        }
      ]
    },
  ];
  visible = false;
  @Input() therapistName!: string;
  @Input() therapistId!: string;
  setAvailableSpinner = false;

  toUpperCaseFirstLetter(letter: string){
    return letter.charAt(0).toLocaleUpperCase()+ letter.substring(1)
  }

  close(){
    this.visible = false;
  }

  open(){
    this.visible = true;
  }

  removeSlot(i: number,indexSlots: number){
    this.availabilityV2[i].slots.splice(indexSlots,1);
    this.checkOverlappingSlotsWhenDelete(i);
  }
  addSlots(i: number){
    this.availabilityV2[i].slots.push({
      slotEnd: '',
      slotStart: '',
      offline: true,
      online: true,
    })
  }

  ngOnInit(){
    this.auth.currentAuthStatus.subscribe((user) => {
      if(user){
        this.getTherapist()
      }
    })
  }

  convertToEpochs(timeString: string) {
    if (!timeString) return '';
    const parsedTime = moment(timeString, 'HH:mm', true);
    if (!parsedTime.isValid()) return '';
    return parsedTime.format('x');
  }

  isSave = false;

  checkOverlappingSlots($event: any,index: number){
    // this.isSave = false;
    // this.availabilityV2[index].slots.forEach((slot) => {
    //  const existingStart =  moment(slot.slotStart,'HH:mm');
    //  const existingEnd = moment(slot.slotEnd,'HH:mm');
    //  const newTime = moment($event,'HH:mm');
    //  console.log(slot.slotStart,slot.slotEnd,newTime);
    //  const overLapping = newTime.isBetween(existingStart, existingEnd, "minute", "[)");
    //  console.log(overLapping);
    //  if (overLapping) {
    //    this.isSave = true;
    //    this.toastrService.showError(`Entered slot time ${$event} overlaps with an existing slot from ${slot.slotStart} to ${slot.slotEnd}.`);
    //    return;
    //   }
    // })
    this.checkOverlappingSlotsWhenDelete(index)
    
  }

  checkOverlappingSlotsWhenDelete(index: number){
    this.isSave = false;
    let comparingIndex = 1; 
    this.availabilityV2[index].slots.forEach((slot) => {
    const compareSlot = this.availabilityV2[index].slots[comparingIndex];
      // Parse the slots into moment objects
      const existingStart = moment(slot.slotStart, "HH:mm");
      const existingEnd = moment(slot.slotEnd, "HH:mm");
      const compareStart = moment(compareSlot.slotStart, "HH:mm");
      const compareEnd = moment(compareSlot.slotEnd, "HH:mm");

      // Check for any overlap , current start and end with existing, exsting start with current.
      const overLappingStart = compareStart.isBetween(existingStart, existingEnd, "minute", "[)");
      const overLappingEnd = compareEnd.isBetween(existingStart, existingEnd, "minute", "[)");
      const spansExistingSlot = existingStart.isBetween(compareStart, compareEnd, "minute", "[)");

     if (overLappingStart) {
       this.isSave = true;
       this.toastrService.showError(`Entered slot start time ${compareStart.format("hh:mm:A")} overlaps with an existing slot from ${existingStart.format("hh:mm:A")} to ${existingEnd.format("hh:mm:A")}.`);
      }
      if (overLappingEnd) {
        this.isSave = true;
        this.toastrService.showError(`Entered slot end time ${compareEnd.format("hh:mm:A")} overlaps with an existing slot from ${existingStart.format("hh:mm:A")} to ${existingEnd.format("hh:mm:A")}.`);
       }
       if (spansExistingSlot) {
        this.isSave = true;
        this.toastrService.showError(
          `Entered slot (${existingStart.format("hh:mm:A")} - ${existingEnd.format("hh:mm:A")}) overlaps with an existing slot (${compareStart.format("hh:mm:A")} - ${compareEnd.format("hh:mm:A")}).`
        );
      }
       comparingIndex += 1;
    })
    
  }

  saveAvailabilityV2(){
    this.setAvailableSpinner = true;
    const req: UpdateAvailabilityV2ForTherapistRequest = {
      therapistId: this.therapistId,
      availabilityV2: []
    }
    for (const day of this.availabilityV2) {
      for(const slot of day.slots){
        const startEpoch = this.convertToEpochs(slot.slotStart);
        const endEpoch = this.convertToEpochs(slot.slotEnd);
        if (startEpoch && endEpoch && startEpoch > endEpoch) {
          this.toastrService.showError(
            'Slots End time must be greater than Slots Start time for ' + day.day,
          );
          this.setAvailableSpinner = false;
        return;
      }
    
      }
    }
    this.availabilityV2.forEach((data) => {
      data.slots = data.slots.map((data) => {
        return {
          slotStart: this.convertToEpochs(data.slotStart),
          slotEnd: this.convertToEpochs(data.slotEnd),
          online: data.online,
          offline: data.offline
        }
      })
    });
    const therapistV2Availability: TherapistAvailabilityV2[] = this.availabilityV2.map((data) => {
      return {
        day: data.day,
        slots: data.slots
      }
    })
    req.availabilityV2 = therapistV2Availability
    this.therapistService.saveTherapistAvailabilityV2(req).subscribe({
      next: (value) => {
        this.setAvailableSpinner = false;
        this.toastrService.showSuccess("Successfully availability saved")
        this.getTherapist();
      }, error: (error) => {
        this.setAvailableSpinner = false;
        const errorBody = error.error as IAnyoError;
        this.toastrService.showAnyoErrorToast(errorBody.description);
      }
    })
  }

  getTherapist() {
    this.setAvailableSpinner = true;
    this.therapistService.getTherapist(this.therapistId).subscribe({
      next: (value) => {
        if (value.data.availabilityV2 && value.data.availabilityV2.length) {
          this.availabilityV2 = value.data.availabilityV2.map((data) => {
            return {
              day: data.day,
              color: this.utils.getRandomColor(),
              slots: data.slots
            }
          })
        }else{
         this.availabilityV2.forEach((data) => data.color = this.utils.getRandomColor())
        }
        this.setAvailableSpinner = false;
      },
      error: (error) => {
        this.setAvailableSpinner = false;
        const errorBody = error.error as IAnyoError;
        this.toastrService.showAnyoErrorToast(errorBody.description);
      },
    });
  }

}

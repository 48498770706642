import { GridRequest } from "projects/app-core/src/models/gridRequest";

export enum UniqueTherapyPlanSubscriptionsFilter {
    PARTNER_ID = "PARTNER_ID",
    TEST_USER = "TEST_USER"
  }

  export enum UniqueTherapyPlanSubscriptionsSort {
    THERAPY_CREATED_AT = "CREATED_AT",
    THERAPY_STATUS = "STATUS",
  }
  export enum UniqueTherapyPlanSubscriptionsSearch {
    PLAN_NAME = "PLAN_NAME",
    USER_NAME = "USER_NAME",
  }
  
  export enum UniqueTherapyPlanSubscriptionsDateFilter {
    CREATED_AT = "CREATED_AT",
    VALID_TILL = "VALID_TILL",
  }

  
export interface UniqueTherapyPlanSubscriptionForUsersReq extends 
GridRequest<
    UniqueTherapyPlanSubscriptionsSort,
    UniqueTherapyPlanSubscriptionsFilter,
    UniqueTherapyPlanSubscriptionsSearch,
    UniqueTherapyPlanSubscriptionsDateFilter
>{}
<div class="flex flex-row flex-nowrap md:gap-3 items-center justify-between">
  <div class="flex flex-wrap w-1/4 justify-start">
    <img
      [routerLink]="['/content']"
      class="left-arrow clickable"
      src="../../../../assets/icons/left-arrow.svg"
    />
    <h1 class="headeradminui-text">Upload New Content</h1>
  </div>
</div>
<nz-spin [nzSpinning]="contentSpinning" nzTip="Loading...">
  <div class="custom-card mt-2">
    <div class="card-body">
      <div [nzGutter]="16" nz-row nzAlign="middle" nzJustify="space-evenly">
        <div nz-col nzSpan="6">
          <nz-select
            (ngModelChange)="categoryChangeFn($event)"
            [(ngModel)]="selectedCategoryString"
            class="w-[100%] mr-1"
            nzAllowClear
            nzPlaceHolder="Select a Category"
            nzShowSearch
          >
            <nz-option
              *ngFor="let category of categories"
              [nzLabel]="category.label"
              [nzValue]="category.value"
            ></nz-option>
          </nz-select>
        </div>
        <div nz-col nzSpan="6">
          <nz-select
            [(ngModel)]="selectedGenreString"
            class="w-[100%] mr-1"
            nzAllowClear
            nzPlaceHolder="Select a Genre"
            nzShowSearch
          >
            <nz-option
              *ngFor="let genre of genres"
              [nzLabel]="genre.label"
              [nzValue]="genre.value"
            ></nz-option>
          </nz-select>
        </div>
        <div nz-col nzSpan="6">
          <input
            [disabled]="true"
            [ngModel]="emotionPurpose"
            class="w-[100%] mr-1"
            nz-input
            nzSize="large"
            placeholder="Emotion Purpose"
          />
        </div>
        <div nz-col nzSpan="6">
          <input
            [disabled]="true"
            [ngModel]="energyPurpose"
            class="w-[100%] mr-1"
            nz-input
            nzSize="large"
            placeholder="Energy Purpose"
          />
        </div>
      </div>
      <div [nzGutter]="16" class="mt-5" nz-row nzJustify="space-evenly">
        <div nz-col nzSpan="6">
          <input
            [disabled]="true"
            [ngModel]="userProblems"
            class="w-[100%] mr-1"
            nz-input
            nzSize="large"
            placeholder="User Problems"
          />
        </div>
        <div nz-col nzSpan="6">
          <input
            [disabled]="true"
            [ngModel]="feedback"
            class="w-[100%] mr-1"
            nz-input
            nzSize="large"
            placeholder="Feedback"
          />
        </div>
        <div nz-col nzSpan="6">
          <input
            [(ngModel)]="practiceName"
            class="w-[100%] mr-1"
            nz-input
            nzSize="large"
            placeholder="Practice Name"
          />
        </div>
        <div nz-col nzSpan="6" class="flex flex-col gap-2">
          <nz-select
          [(ngModel)]="selectedUserInterests"
          nzPlaceHolder="Select User Interests"
          nzMode="multiple"
          nzSize="large"
        >
          <nz-option
            *ngFor="let item of userInterestTopics"
            [nzLabel]="item.value"
            [nzValue]="item.key"
          ></nz-option>
        </nz-select>
        </div>
      </div>
      <div [nzGutter]="16" class="mt-5" nz-row nzJustify="space-evenly">
        <div nz-col nzSpan="12">
          <textarea [(ngModel)]="contentDescription" nz-input rows="4">
Description</textarea
          >
        </div>
        <div nz-col nzSpan="12">
          <textarea [(ngModel)]="templates" [disabled]="true" nz-input rows="4">
Templates</textarea
          >
        </div>
      </div>
      <div [nzGutter]="16" class="mt-5" nz-row nzJustify="space-evenly">
        <div class="mt-5" nz-col nzSpan="6">
          <span><b>Upload Content Thumbnail</b></span>
          <br />
          <input
            (change)="uploadContentThumbNail($event)"
            accept=".jpg,.jpeg,.png"
            type="file"
          />
        </div>
        <div class="mt-5" nz-col nzSpan="6">
          <span><b>Upload Content Banner</b></span>
          <br />
          <input
            (change)="uploadContentBanner($event)"
            accept=".jpg,.jpeg,.png"
            type="file"
          />
        </div>
        <div class="mt-5" nz-col nzSpan="6">
          <nz-select
            class="w-[100%] mr-1"
            [nzMaxTagCount]="4"
            [nzMaxTagPlaceholder]="tagPlaceHolder"
            nzMode="multiple"
            nzPlaceHolder="Please select instructors"
            [(ngModel)]="selectedInstructors"
          >
            <nz-option
              *ngFor="let item of instructors"
              [nzLabel]="item.label"
              [nzValue]="item.value"
            ></nz-option>
          </nz-select>
          <ng-template #tagPlaceHolder let-selectedList
            >and {{ selectedList.length }} more selected</ng-template
          >
        </div>
        <div nz-col nzSpan="6" class="flex flex-col gap-2">
          <label>Visible :</label>
          <nz-switch [(ngModel)]="isVisible"></nz-switch
          >
        </div>
      </div>

      <nz-divider nzText="Modules"></nz-divider>
      <div nz-row nzJustify="end">
        <div nz-col>
          <button (click)="addModule()" nz-button nzType="primary">
            Add Module
          </button>
        </div>
      </div>
      <div class="module-container">
        <div *ngFor="let module of modules; index as i" class="mt-3" nz-row>
          <nz-card
            [nzExtra]="deleteModuleTemplate"
            [nzTitle]="'Module ' + (i + 1)"
            style="width: 100%"
          >
            <div nz-row nzGutter="18">
              <div nz-col nzSpan="12">
                <div nz-row nzGutter="18">
                  <div nz-col nzSpan="12">
                    <span><b>Upload Module Thumbnail</b></span>
                    <br />
                    <input
                      (change)="uploadModuleThumbnail($event, i)"
                      accept=".jpg,.jpeg,.png"
                      type="file"
                    />
                  </div>
                  <div nz-col nzSpan="12">
                    <span><b>Upload Module Banner</b></span>
                    <br />
                    <input
                      (change)="uploadModuleBanner($event, i)"
                      accept=".jpg,.jpeg,.png"
                      type="file"
                    />
                  </div>
                  <div nz-col nzSpan="12" class="pt-3">
                    <span><b>Upload Module Media</b></span>
                    <br />
                    <input
                      (change)="uploadModuleMedia($event, i)"
                      accept=".mp3,.mp4"
                      type="file"
                    />
                  </div>
                </div>
                <div class="mt-5" nz-row nzGutter="18">
                  <div nz-col nzSpan="24">
                    <textarea
                      [(ngModel)]="module.moduleDescription"
                      class="w-[100%]"
                      nz-input
                      placeholder="Module Description"
                      rows="4"
                    >
Module Description</textarea
                    >
                  </div>
                </div>
              </div>

              <div nz-col nzSpan="12">
                <div nz-row nzGutter="18">
                  <div nz-col nzSpan="12">
                    <input
                      [(ngModel)]="module.moduleId"
                      nz-input
                      nzSize="large"
                      placeholder="Module Id"
                    />
                  </div>
                  <div nz-col nzSpan="12">
                    <input
                      [(ngModel)]="module.moduleName"
                      nz-input
                      nzSize="large"
                      placeholder="Module Name"
                    />
                  </div>
                </div>
                <div class="mt-10" nz-row nzGutter="18">
                  <div nz-col nzSpan="12">
                    <input
                      [(ngModel)]="module.estimatedTime"
                      nz-input
                      nzSize="large"
                      (input)="validateNumber($event)"
                      placeholder="Estimated Time (in seconds)"
                    />
                  </div>
                  <div nz-col nzSpan="12">
                    <label [(ngModel)]="module.isMandatory" nz-checkbox
                      >Make as mandatory
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </nz-card>
          <ng-template #deleteModuleTemplate>
            <span
              (click)="deleteModule(i)"
              nz-icon
              nzTheme="outline"
              nzType="delete"
            ></span>
          </ng-template>
        </div>
      </div>
      <div class="mt-5" nz-row nzJustify="end">
        <div nz-col>
          <button (click)="saveContent()" nz-button nzType="primary">
            Save Content
          </button>
        </div>
      </div>
    </div>
  </div>
</nz-spin>
<!--<nz-upload-->
<!--  (nzChange)="fileSelected($event)"-->
<!--  [nzMultiple]="false"-->
<!--  nzAction=""-->
<!--  nzType="drag"-->
<!--&gt;-->
<!--  <p class="ant-upload-drag-icon">-->
<!--    <span nz-icon nzType="inbox"></span>-->
<!--  </p>-->
<!--  <p class="ant-upload-text">Click or drag file to this area to upload</p>-->
<!--</nz-upload>-->

import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TherapistAvailability } from 'projects/admin/src/app/models/IAnyoTherapist';
import { TherapistService } from 'projects/admin/src/app/services/therapist.service';
import * as moment from 'moment';
import { IAnyoError } from '../../models/error/errorResponse';
import { ToastService } from '../../services/toastr.service';
import { UpdateTherapistAvailabilityRequest } from 'projects/admin/src/app/models/updateTherapistAvailabilityRequest';
import { AuthService } from 'projects/app-core/src/auth/auth.service';

export interface TherapistAvailabilityToDisplay {
  day?: string;
  slot1Start: Date;
  slot1End: Date;
  ifSlot2: boolean;
  slot2Start: string;
  slot2End: string;
}

@Component({
  selector: 'app-set-available-slots',
  templateUrl: './set-available-slots.component.html',
  styleUrl: './set-available-slots.component.scss',
})
export class SetAvailableSlotsComponent {
  time: Date | null = null;
  @Input() therapistId!: string;
  @Output() therapistAvailabilityEvent = new EventEmitter<
    TherapistAvailability[]
  >();
  availabilitySlots: TherapistAvailabilityToDisplay[] = [];
  setAvailableSpinner = false;
  availability: TherapistAvailability[] = [
    {
      day: 'monday',
      slot1Start: '',
      slot1End: '',
      ifSlot2: false,
      slot2Start: '',
      slot2End: '',
    },
    {
      day: 'tuesday',
      slot1Start: '',
      slot1End: '',
      ifSlot2: false,
      slot2Start: '',
      slot2End: '',
    },
    {
      day: 'wednesday',
      slot1Start: '',
      slot1End: '',
      ifSlot2: false,
      slot2Start: '',
      slot2End: '',
    },
    {
      day: 'thursday',
      slot1Start: '',
      slot1End: '',
      ifSlot2: false,
      slot2Start: '',
      slot2End: '',
    },
    {
      day: 'friday',
      slot1Start: '',
      slot1End: '',
      ifSlot2: false,
      slot2Start: '',
      slot2End: '',
    },
    {
      day: 'saturday',
      slot1Start: '',
      slot1End: '',
      ifSlot2: false,
      slot2Start: '',
      slot2End: '',
    },
    {
      day: 'sunday',
      slot1Start: '',
      slot1End: '',
      ifSlot2: false,
      slot2Start: '',
      slot2End: '',
    },
  ];

  constructor(
    private therapistService: TherapistService,
    private toastrService: ToastService,
    private auth: AuthService,
  ) {}

  isVisible = false;

  add(day: string) {
    const dayIndex = this.availability.findIndex((item) => item.day === day);
    if (dayIndex !== -1) {
      this.availability[dayIndex].ifSlot2 = true;
    }
  }

  remove(day: string) {
    const dayIndex = this.availability.findIndex((item) => item.day === day);
    if (dayIndex !== -1) {
      this.availability[dayIndex].ifSlot2 = false;
    }
  }

  openSetAvailableSlots() {
    this.isVisible = !this.isVisible;
  }

  handleOk() {
    this.isVisible = false;
  }

  handleCancel() {
    this.isVisible = false;
  }

  ngOnInit() {
    this.auth.currentAuthStatus.subscribe((user) => {
      if (user) {
        this.getTherapist();
      }
    });
  }

  getTherapist() {
    this.setAvailableSpinner = true;
    this.therapistService.getTherapist(this.therapistId).subscribe({
      next: (value) => {
        if (value.data.availability && value.data.availability.length) {
          this.availability = value.data.availability;
          this.therapistAvailabilityEvent.emit(this.availability);
        }
        this.setAvailableSpinner = false;
      },
      error: (error) => {
        this.setAvailableSpinner = false;
        const errorBody = error.error as IAnyoError;
        this.toastrService.showAnyoErrorToast(errorBody.description);
      },
    });
  }

  convertToEpochs(timeString: string) {
    if (!timeString) return '';
    const parsedTime = moment(timeString, 'HH:mm', true);
    if (!parsedTime.isValid()) return '';
    return parsedTime.format('x');
  }

  saveAvailability() {
    this.setAvailableSpinner = true;
    for (const day of this.availability) {
      const startEpoch = this.convertToEpochs(day.slot1Start);
      const endEpoch = this.convertToEpochs(day.slot1End);
      if (startEpoch && endEpoch && startEpoch > endEpoch) {
        this.toastrService.showError(
          'Slots End time must be greater than Slots Start time for ' + day.day,
        );
        this.setAvailableSpinner = false;
        return;
      }
    }
    this.availability.forEach((data) => {
      data.slot1Start = this.convertToEpochs(data.slot1Start);
      data.slot2Start = this.convertToEpochs(data.slot2Start);
      data.slot1End = this.convertToEpochs(data.slot1End);
      data.slot2End = this.convertToEpochs(data.slot2End);
    });
    const req: UpdateTherapistAvailabilityRequest = {
      therapistId: this.therapistId,
      therapistAvailability: this.availability,
    };
    this.therapistService.therapistAvailabilityUpdate(req).subscribe({
      next: (value) => {
        this.setAvailableSpinner = false;
        this.toastrService.showSuccess('Availability Successfully Saved');
        this.getTherapist();
      },
      error: (error) => {
        this.setAvailableSpinner = false;
        const errorBody = error.error as IAnyoError;
        this.toastrService.showAnyoErrorToast(errorBody.description);
      },
    });
  }
}

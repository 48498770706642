<div class="flex flex-row justify-between items-center mb-3">
  <div class="flex flex-row md:gap-3 items-center">
    <div class="flex flex-row flex-nowrap md:gap-3 items-center">
      <h1 class="headeradminui-text md:break-keep">Manage Events</h1>
    </div>
    <div>
      <img class="separator" src="../../../../assets/icons/separator.svg" alt="img" />
    </div>
    <div>
      <h1 class="sub-headeradminui-text">{{ totalCount }} Events</h1>
    </div>
  </div>
  <div
    class="flex flex-row justify-between btn custom-btn-primary-adminui gap-1"
    [routerLink]="['/events/add']"
  >
    <div>
      <img class="add-icon" src="../../../../assets/icons/add-icon.svg" alt="img" />
    </div>
    <div>
      <span class="">Add Events</span>
    </div>
  </div>
</div>

<nz-spin [nzSpinning]="eventsSpinner" nzTip="Fetching Events....">
  <div nz-row nzJustify="end" nzAlign="middle">
    <div nz-col class="p-1">
      <nz-input-group nzSearch nzSize="large" [nzAddOnAfter]="suffixButton">
        <input
          type="text"
          nz-input
          [(ngModel)]="globalSearchInput"
          placeholder="Search for name"
        />
      </nz-input-group>
    </div>
  </div>
  <div nz-row>
    <nz-tabset (nzSelectChange)="tabChange($event)">
      <nz-tab nzTitle="Upcoming Events">
        <app-events-table
          #UpcomingTableComponent
          [eventsTableData]="tableData"
          (resetFilterEvent)="resetFilter()"
          (pageChangeEvent)="nzPageIndexChange($event)"
          (pageSizeChangeEvent)="nzPageSizeChange($event)"
          (categoryFilterEvent)="categoryFilter($event)"
          (eventsDateFilterEvent)="dateFilter($event)"
          (categoryFilterEvent)="categoryFilter($event)"
          (paymentTypeFilterEvent)="paymentTypeFilter($event)"
          (hideTestFilterEvent)="testFilter($event)"
        ></app-events-table>
      </nz-tab>
      <nz-tab nzTitle="Ongoing Classes">
        <app-events-table
          #OngoingClassesComponent
          [eventsTableData]="tableData"
          (resetFilterEvent)="resetFilter()"
          (pageChangeEvent)="nzPageIndexChange($event)"
          (pageSizeChangeEvent)="nzPageSizeChange($event)"
          (categoryFilterEvent)="categoryFilter($event)"
          (eventsDateFilterEvent)="dateFilter($event)"
          (categoryFilterEvent)="categoryFilter($event)"
          (paymentTypeFilterEvent)="paymentTypeFilter($event)"
          (hideTestFilterEvent)="testFilter($event)"
        ></app-events-table>
      </nz-tab>
      <nz-tab nzTitle="All Events">
        <app-events-table
          #AllTableComponent
          [eventsTableData]="tableData"
          (resetFilterEvent)="resetFilter()"
          (pageChangeEvent)="nzPageIndexChange($event)"
          (pageSizeChangeEvent)="nzPageSizeChange($event)"
          (categoryFilterEvent)="categoryFilter($event)"
          (eventsDateFilterEvent)="dateFilter($event)"
          (categoryFilterEvent)="categoryFilter($event)"
          (paymentTypeFilterEvent)="paymentTypeFilter($event)"
          (hideTestFilterEvent)="testFilter($event)"
        ></app-events-table>
      </nz-tab>
    </nz-tabset>
  </div>
  <ng-template #suffixButton>
    <button
      nz-button
      nzType="primary"
      nzSize="small"
      nzSearch
      (click)="searchGlobal()"
    >
      Search
    </button>
  </ng-template>
</nz-spin>

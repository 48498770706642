<ng-template #tableHead>
    <div nz-row nzJustify="space-between">
         <div nz-col>
            <span class="font-bold">Anyo Survey Responses</span>
          </div>
          <div class="flex gap-2">
            <div nz-col>
              <button
                class="btn-color"
                nz-button
                nzSize="small"
                nzType="primary"
                (click)="resetFilter()"
              >
                Clear filter
              </button>
            </div>
        </div>
    </div>
</ng-template>
<nz-spin [nzSpinning]="pageLoading" nzTip="Fetching Survey Responses......">
	<nz-table
	  #therapistTable
	  [nzTitle]="tableHead"
	  [nzScroll]="{ y: '500px' }"
	  class="mt-5"
	  [nzData]="surveyResponsesData"
	  [nzShowPagination]="true"
	  nzFrontPagination="false"
	  [nzTotal]="totalRecords"
	  [nzPageSize]="pageSize"
	  [nzBordered]="true"
	  [nzShowSizeChanger]="true"
	  [nzPageSizeOptions]="pageSizeOptions"
	  [nzPageIndex]="page"
	  nzTableLayout="fixed"
	  (nzPageIndexChange)="nzPageIndexChange($event)"
	  (nzPageSizeChange)="nzPageSizeChange($event)"
	>
	  <thead>
		<tr>
		  <th>
			User Details
		  </th>
		  <th>
			Survey Name
            <nz-filter-trigger
            [nzActive]="true"
            [nzDropdownMenu]="surveys"
          >
            <span nz-icon nzTheme="outline" nzType="filter-o"></span>
          </nz-filter-trigger>
		  </th>
		  <th>Created At
            <nz-filter-trigger
            [nzActive]="true"
            [nzDropdownMenu]="createdAtDateFilterMenu"
          >
            <span nz-icon nzTheme="outline" nzType="schedule"></span>
          </nz-filter-trigger>
          </th>
		  <th>
			Total Points
		  </th>
		</tr>
	  </thead>
	  <tbody>
		<tr *ngFor="let data of therapistTable.data">
		  <td>
			  <div *ngFor="let detail of objectEntries(data?.surveyEndDetails!)">
              <p>{{ detail[0] +" : "+ detail[1] }}</p>
        </div>
		  </td>
		  <td>
			{{ data.surveyName }}
		  </td>
		  <td>
			{{ utils.convertEpochToFormattedDate(data.createdAt) }}
		  </td>
		  <td>
			{{ data.totalPoints }}
		  </td>
		  <td>
			<div class="flex items-center justify-evenly">
			  <!-- <span nz-tooltip nzTooltipTitle="View Survey">
				<button
				  nz-button
				  nzType="primary"
				  nzShape="circle"
				  (click)="editDrawer(data.id!)"
				>
				  <span nz-icon nzType="eye" nzTheme="outline"></span>
				</button>
			  </span> -->
			  </div>
		  </td>
		</tr>
	  </tbody>
	</nz-table>
</nz-spin>

<nz-dropdown-menu #surveys="nzDropdownMenu">
    <div class="bg-white drop-shadow-md m-1 p-2 rounded-md">
      <div nz-row>
        <div nz-col>
          <span class="font-bold">Surveys</span>
          <nz-divider></nz-divider>
          <nz-checkbox-wrapper (nzOnChange)="surveyFilterChangeFn($event)">
            <div *ngFor="let topic of SurveyFilterCheckBoxOptions" nz-row>
              <div nz-col>
                <label
                  nz-checkbox
                  [nzValue]="topic.value"
                  [ngModel]="topic.checked"
                  >{{ topic.label }}</label
                >
              </div>
            </div>
          </nz-checkbox-wrapper>
        </div>
      </div>
      <div nz-row nzJustify="end">
        <div nz-col>
          <button
            class="m-1 btn-color"
            nz-button
            nzType="primary"
            (click)="fetchSurveyResponses()"
          >
            Ok
          </button>
        </div>
  
        <div nz-col>
          <button
            class="m-1 btn-color"
            nz-button
            nzType="primary"
            (click)="resetFilter()"
          >
            Reset
          </button>
        </div>
      </div>
    </div>
  </nz-dropdown-menu>


  <nz-dropdown-menu #createdAtDateFilterMenu="nzDropdownMenu">
    <div class="bg-white drop-shadow-md m-1 p-2 rounded-md">
      <div class="ng-row">
        <nz-range-picker
          [(ngModel)]="createdAtColumnFilterDate"
          (ngModelChange)="createdAtFilterDateChange($event)"
        ></nz-range-picker>
      </div>
      <div nz-row class="mt-5" nzJustify="end">
        <div nz-col class="mr-1">
          <button
            class="m-1"
            nz-button
            nzType="primary"
            class="btn-color"
            (click)="fetchSurveyResponses()"
          >
            Ok
          </button>
        </div>
        <div nz-col>
          <button
            class="m-1"
            nz-button
            nzType="primary"
            class="btn-color"
            (click)="resetFilter()"
          >
            Reset
          </button>
        </div>
      </div>
    </div>
  </nz-dropdown-menu>
<div class="flex justify-end mt-3">
    <div class="w-[40%]">
      <nz-input-group nzSearch nzSize="large" [nzAddOnAfter]="suffixButton">
        <input
          type="text"
          nz-input
          [(ngModel)]="globalSearchInput"
          placeholder="Search for expert name,email"
        />
      </nz-input-group>
    </div>
  </div>
  <ng-template #suffixButton>
    <button
      nz-button
      nzType="primary"
      nzSize="small"
      nzSearch
      (click)="searchGlobal()"
    >
      Search
    </button>
  </ng-template>
<nz-spin [nzSpinning]="expertsOnboardingSpinner" nzTip="Fetching Experts....">
  <div nz-row>
    <nz-tabset (nzSelectChange)="tabChange($event)">
      <nz-tab nzTitle="Under Review">
        <app-onboarding-table
          #UnderReviewTableComponent
          [gridResp]="tableData"
          (resetFilterEvent)="resetFilter()"
          (pageChangeEvent)="nzPageIndexChange($event)"
          (pageSizeChangeEvent)="nzPageSizeChange($event)"
          (lastUpdateSortChangeEvent)="lastUpdateSortFn($event)"
        ></app-onboarding-table>
      </nz-tab>
      <nz-tab nzTitle="Required Changes">
        <app-onboarding-table
          #RequiredChangesTableComponent
          [gridResp]="tableData"
          (resetFilterEvent)="resetFilter()"
          (pageChangeEvent)="nzPageIndexChange($event)"
          (pageSizeChangeEvent)="nzPageSizeChange($event)"
          (lastUpdateSortChangeEvent)="lastUpdateSortFn($event)"
        ></app-onboarding-table>
      </nz-tab>
      <nz-tab nzTitle="Approved">
        <app-onboarding-table
          #RequiredChangesTableComponent
          [gridResp]="tableData"
          (resetFilterEvent)="resetFilter()"
          (pageChangeEvent)="nzPageIndexChange($event)"
          (pageSizeChangeEvent)="nzPageSizeChange($event)"
          (lastUpdateSortChangeEvent)="lastUpdateSortFn($event)"
        ></app-onboarding-table>
      </nz-tab>
      <nz-tab nzTitle="Rejected">
        <app-onboarding-table
          #RequiredChangesTableComponent
          [gridResp]="tableData"
          (resetFilterEvent)="resetFilter()"
          (pageChangeEvent)="nzPageIndexChange($event)"
          (pageSizeChangeEvent)="nzPageSizeChange($event)"
          (lastUpdateSortChangeEvent)="lastUpdateSortFn($event)"
        ></app-onboarding-table>
      </nz-tab>
    </nz-tabset>
  </div>
</nz-spin>
export enum Roles {
  Admin = 'Admin',
  SuperAdmin = 'SuperAdmin',
  Therapist = 'Therapist',
  Listener = 'Listener',
  EnterpriseUser = 'EnterpriseUser',
  InviteUsers = 'InviteUsers',
  InterChatTester = 'InterChatTester',
  CirclesModerator = 'CirclesModerator',
  CircleManager = 'CircleManager',
  EventsManager = 'EventsManager',
  Partner = 'Partner',
  TalksManager = 'TalksManager',
  ViewUserProfile = 'ViewUserProfile',
  ManageUserAttributes = 'ManageUserAttributes',
  ManagePromoCodes = 'ManagePromoCodes',
  ManageSurveys = 'ManageSurveys',
}
export enum Permissions {
  AddTherapist = 'AddTherapist',
  AdminBookNewSession = 'AdminBookNewSession',
  BytesManager = 'BytesManager',
  ContentManager = 'ContentManager',
  RoutineManager = 'RoutineManager',
  AdminAddPost = 'AdminAddPost',
  AdminManageTherapist = 'AdminManageTherapist',
  AdminManageAppointment = 'AdminManageAppointment',
  AdminManageListener = 'AdminManageListener',
  AdminManagePartner = 'AdminManagePartner',
  AdminManageTherapyPlans = 'AdminManageTherapyPlans',
  ActivateTherapyPlan = 'ActivateTherapyPlan',
  AddExtraSessionToPlanSubscription = 'AddExtraSessionToPlanSubscription',
  AddRecipeInContent = 'AddRecipeInContent',
  AdminManageExpertsRewards = 'AdminManageExpertsRewards',
  AdminShowSurveyResult = 'AdminShowSurveyResult',
}

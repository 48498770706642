<ng-template #tableHead>
  <div nz-row nzJustify="space-between">
    <div nz-col>
      <span class="font-bold">Events</span>
    </div>
    <div nz-col>
      <nz-switch
      (ngModelChange)="testEventChangeFn($event)"
            [(ngModel)]="hideTestEvent"
            nzCheckedChildren="Hide Test Events"
            nzUnCheckedChildren="Show Test Events"
            class="mr-3"
          ></nz-switch>
      <button
        class="btn-color"
        nz-button
        nzSize="small"
        nzType="primary"
        (click)="resetFilter()"
      >
        Clear filter
      </button>
    </div>
  </div>
</ng-template>
<nz-table
  #basicTable
  [nzData]="eventsTableData?.data!"
  [nzTitle]="tableHead"
  [nzScroll]="{ y: '500px' }"
  class="mt-5"
  [nzShowPagination]="true"
  nzFrontPagination="false"
  [nzTotal]="eventsTableData?.totalRecords!"
  [nzPageSize]="eventsTableData?.pageSize!"
  [nzBordered]="true"
  [nzShowSizeChanger]="true"
  [nzPageSizeOptions]="pageSizeOptions"
  [nzPageIndex]="eventsTableData?.pageNum!"
  nzTableLayout="fixed"
  (nzPageIndexChange)="nzPageIndexChange($event)"
  (nzPageSizeChange)="nzPageSizeChange($event)"
>
  <thead>
    <tr>
      <th>Name</th>
      <th>
        Category
        <nz-filter-trigger [nzActive]="true" [nzDropdownMenu]="CategoryMenu">
          <span nz-icon nzTheme="outline" nzType="filter-o"></span>
        </nz-filter-trigger>
      </th>
      <th>Duration</th>
      <th>Max Registration</th>
      <th>Total Registration</th>
      <th nzWidth="20%">
        Dates
        <nz-filter-trigger
          [nzActive]="true"
          [nzDropdownMenu]="eventsDateFilterMenu"
        >
          <span nz-icon nzTheme="outline" nzType="schedule"></span>
        </nz-filter-trigger>
      </th>
      <th>
        Payment Type
        <nz-filter-trigger [nzActive]="true" [nzDropdownMenu]="paymentTypeMenu">
          <span nz-icon nzTheme="outline" nzType="filter-o"></span>
        </nz-filter-trigger>
      </th>
      <th>Action</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let data of basicTable.data">
      <td>
        <div>
          <div>{{ data.name }}</div>
          <nz-tag *ngIf="data.tags && data.tags.length"
            [nzColor]="data.categoryNameColor" class="text-xs">
            {{  data.tags.includes(eventTags.RECURRING_EVENT) ? 'Class' : 'Event'  }}
          </nz-tag>
        </div>
      </td>
      
      <td [align]="'left'">
        <nz-tag [nzColor]="data.categoryNameColor">{{
          data.categoryName
        }}</nz-tag>
      </td>

      <td>{{ data.durationMinutes }}</td>
      <td>{{ data.maxRegistrations ? data.maxRegistrations : "N/A" }}</td>
      <td>{{ data.totalRegistrations ? data.totalRegistrations : "N/A" }}</td>
      <td>
        <div class="ml-1 mr-1" colspan="2" nz-col>
          <div nz-col nzJustify="start">
            <div>Event Date :</div>
            <div>
              {{
                data.eventDate != undefined
                  ? utils.convertEpochToFormattedDate(data.eventDate)
                  : "N/A"
              }}
            </div>
          </div>
          <div
            *ngIf="data.registrationCutOffDate"
            class="mt-2"
            nz-col
            nzJustify="start"
          >
            <div>Registration CutOff Date :</div>
            <div>
              {{
               data.tags && data.tags.length && data.tags.includes(eventTags.RECURRING_EVENT) ? "N/A" : utils.convertEpochToFormattedDate(data.registrationCutOffDate)
              }}
            </div>
          </div>
          <div *ngIf="data.visibleFrom" nz-col class="mt-2" nzJustify="start">
            <div>Visible From :</div>
            <div>{{ utils.convertEpochToFormattedDate(data.visibleFrom) }}</div>
          </div>
        </div>
      </td>
      <td [align]="'left'">
        <nz-tag [nzColor]="data.paymentTypeColor">{{
          utils.convertToTitleCase(data.paymentType)
        }}</nz-tag>
      </td>

      <td nzRight>
        <span nz-tooltip nzTooltipTitle="View Events">
          <button
            nz-button
            class="save"
            nzShape="circle"
            [routerLink]="['/events/edit/' + data.id]"
          >
            <span nz-icon nzType="eye" nzTheme="outline"></span>
          </button>
        </span>
      </td>
    </tr>
  </tbody>
</nz-table>
<nz-dropdown-menu #CategoryMenu="nzDropdownMenu">
  <div class="bg-white drop-shadow-md m-1 p-2 rounded-md">
    <div nz-row>
      <div nz-col>
        <span class="font-bold">Category</span>
        <nz-divider></nz-divider>
        <nz-checkbox-wrapper (nzOnChange)="categoryFilterChangeFn($event)">
          <div *ngFor="let topic of categoryCheckBoxOptions" nz-row>
            <div nz-col>
              <label
                nz-checkbox
                [nzValue]="topic.value"
                [ngModel]="topic.checked"
                >{{ topic.label }}</label
              >
            </div>
          </div>
        </nz-checkbox-wrapper>
      </div>
    </div>
    <div nz-row nzJustify="end">
      <div nz-col>
        <button
          class="m-1 btn-color"
          nz-button
          nzType="primary"
          (click)="categoryFilterFn()"
        >
          Ok
        </button>
      </div>

      <div nz-col>
        <button
          class="m-1 btn-color"
          nz-button
          nzType="primary"
          (click)="resetFilter()"
        >
          Reset
        </button>
      </div>
    </div>
  </div>
</nz-dropdown-menu>
<nz-dropdown-menu #eventsDateFilterMenu="nzDropdownMenu">
  <div class="bg-white drop-shadow-md m-1 p-2 rounded-md">
    <div class="ng-row">
      <nz-range-picker
        [nzFormat]="dateFormat"
        [(ngModel)]="eventsColumnFilterDate"
        (ngModelChange)="onEventsFilterDateChange($event)"
      ></nz-range-picker>
    </div>
    <div class="ng-row">
      <nz-checkbox-wrapper (nzOnChange)="dateFilterChangeFn($event)">
        <div *ngFor="let topic of dateCheckBoxOptions" nz-row>
          <div nz-col>
            <label
              nz-checkbox
              [nzValue]="topic.value"
              [ngModel]="topic.checked"
              >{{ topic.label }}</label
            >
          </div>
        </div>
      </nz-checkbox-wrapper>
    </div>
    <div nz-row class="mt-5 gap-4" nzJustify="end">
      <div nz-col>
        <button
          class="m-1"
          nz-button
          nzType="primary"
          class="btn-color"
          (click)="dateFilterFn()"
        >
          Ok
        </button>
      </div>
      <div nz-col>
        <button
          class="m-1"
          nz-button
          nzType="primary"
          class="btn-color"
          (click)="resetFilter()"
        >
          Reset
        </button>
      </div>
    </div>
  </div>
</nz-dropdown-menu>
<nz-dropdown-menu #paymentTypeMenu="nzDropdownMenu">
  <div class="bg-white drop-shadow-md m-1 p-2 rounded-md">
    <div nz-row>
      <div nz-col>
        <span class="font-bold">Payment Type</span>
        <nz-divider></nz-divider>
        <nz-checkbox-wrapper (nzOnChange)="paymentTypeFilterChangeFn($event)">
          <div *ngFor="let topic of paymentTypeCheckBoxOptions" nz-row>
            <div nz-col>
              <label
                nz-checkbox
                [nzValue]="topic.value"
                [ngModel]="topic.checked"
                >{{ topic.label }}</label
              >
            </div>
          </div>
        </nz-checkbox-wrapper>
      </div>
    </div>
    <div nz-row nzJustify="end">
      <div nz-col>
        <button
          class="m-1 btn-color"
          nz-button
          nzType="primary"
          (click)="paymentTypeFilterFn()"
        >
          Ok
        </button>
      </div>

      <div nz-col>
        <button
          class="m-1 btn-color"
          nz-button
          nzType="primary"
          (click)="resetFilter()"
        >
          Reset
        </button>
      </div>
    </div>
  </div>
</nz-dropdown-menu>
